import React, { FC } from 'react';
import { Select, SelectOption, SelectProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './TaskCategorySelect.module.scss';

import { TaskCategory } from '../../../../domain/Task/Task';
import {
  getEmptyValidationMessage,
  TaskFormFields
} from '../../CreateTask/CreateTask';
import { buildOptions } from '../../../../utils/buildOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const renderOption = (option: SelectOption) => {
  const color = option.label === TaskCategory.Field ? '#772B89' : '#1C66A7';

  return (
    <div className={styles.option}>
      <FontAwesomeIcon icon={faCircle} style={{ color: color }} />
      <span className={styles.label}>{option.label}</span>
    </div>
  );
};

export const TaskCategorySelect: FC<Partial<SelectProps>> = props => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={TaskFormFields.category}
      rules={{ required: true }}
      render={({ ref, onChange, ...rest }, { invalid }) => (
        <Select
          options={buildOptions(TaskCategory)}
          onChange={onChange}
          required={true}
          errorMessage={getEmptyValidationMessage(invalid)}
          renderValue={renderOption}
          renderOption={renderOption}
          label="Category"
          placeholder="Select"
          size="large"
          {...rest}
          {...props}
        />
      )}
    />
  );
};
