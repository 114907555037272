import React from 'react';
import {
  Button,
  ButtonProps,
  Modal,
  ModalButtons,
  ModalContent
} from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ModalConfirmationProps {
  title: string;
  description: React.ReactNode;
  open: boolean;
  loading?: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmButtonProps?: Partial<ButtonProps>;
  onCancel: () => void;
  onConfirm: () => void;
}

export const modalConfirmationTestId = 'modalConfirmationTestId';
export const cancelButtonTestId = 'cancel';
export const confirmButtonTestId = 'confirm';

export const ModalConfirmation = (props: ModalConfirmationProps) => {
  const {
    title,
    description,
    open,
    loading,
    cancelButtonText,
    confirmButtonText,
    confirmButtonProps,
    onCancel,
    onConfirm
  } = props;

  return (
    <Modal
      isOpen={open}
      onClose={onCancel}
      title={title}
      data-testid={modalConfirmationTestId}
    >
      <ModalContent>{description}</ModalContent>
      <ModalButtons>
        <Button
          data-testid={cancelButtonTestId}
          variant="outlined"
          onClick={onCancel}
          disabled={loading}
        >
          {cancelButtonText}
        </Button>
        <Button
          color="danger"
          data-testid={confirmButtonTestId}
          onClick={() => {
            onConfirm();
          }}
          {...(loading && {
            disabled: true,
            icon: <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          })}
          {...confirmButtonProps}
        >
          {confirmButtonText}
        </Button>
      </ModalButtons>
    </Modal>
  );
};
