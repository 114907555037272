import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './Status.module.scss';
import { cancelled, completed, TaskStatus } from '../../../domain/Task/Task';

interface StatusProps {
  status: TaskStatus;
  className?: string;
}

export const Status = (props: StatusProps) => {
  const { status, className } = props;

  const getStatusStyle = {
    [TaskStatus.ToDo]: styles.toDo,
    [TaskStatus.Cancelled]: styles.cancelled,
    [TaskStatus.Completed]: styles.completed
  }[status];

  return (
    <div className={clsx(getStatusStyle, className)}>
      {completed(status) && (
        <FontAwesomeIcon className={styles.iconStyle} icon={faCheck} />
      )}
      {cancelled(status) && (
        <FontAwesomeIcon
          className={clsx(styles.iconStyle, styles.red)}
          icon={faTimes}
        />
      )}
      {status}
    </div>
  );
};
