import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { format, isAfter, isBefore } from 'date-fns';
import styles from './UpcomingEvents.module.scss';
import { ReactComponent as EmptyEventSVG } from './empty_state.svg';
import { ActiveTimeSlot } from '../Calendar';
import { TimeSlot } from '../../../domain/Calendar/Timeslot';
import {
  CalendarEvent,
  EventStatus
} from '../../../domain/Calendar/CalendarEvent';
import { EventCard } from '../EventCard/EventCard';
import { EventsSkeleton } from '../EventsSkeleton/EventsSkeleton';
import {
  addLunchSlot,
  EventsDispatchContext,
  MappedEvents
} from '../../../context/events/EventsProvider';
import { StoreContext } from '../../../context/store/StoreProvider';
import { ToastType, useToastActionsContext } from '@rentacenter/racstrap';
import { getEvents, getTimeSlots } from '../../../api/calendar';
import { ApiError } from '../../common/ApiError/ApiError';
import { TIME } from '../../../utils/time';
import { getCancelTokenSource } from '../../../api/client';
import { CancelToken, CancelTokenSource } from 'axios';
import { formatEvents } from '../../../context/events/eventsActions';

export interface UpcomingEventsProps {
  activeTimeSlot?: ActiveTimeSlot; // TODO: this might be useless
  onEventClick: (event: CalendarEvent) => void;
  pauseAutoRefresh?: boolean;
}

export const upcomingEventsTitleTestId = 'upcomingEventsTitleTestId';
export const upcomingEventsListTestId = 'upcomingEventsListTestId';
export const emptyUpcomingEventTestId = 'emptyUpcomingEventTestId';

export const EmptyEvent = () => (
  <div className={styles.emptyEvent} data-testid={emptyUpcomingEventTestId}>
    <EmptyEventSVG />
    You have no Upcoming Events
  </div>
);

type ListStatus = 'loading' | 'apiError' | 'empty' | 'success';

// eslint-disable-next-line
export const UpcomingEvents = (props: UpcomingEventsProps) => {
  const { activeTimeSlot, onEventClick, pauseAutoRefresh } = props;
  const { showToast } = useToastActionsContext();
  const { selectedStore } = useContext(StoreContext);
  const { reloadUpcomingEvents, setReloadUpcomingEvents } = useContext(
    EventsDispatchContext
  );

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [timeSlot, setTimeSlot] = useState<TimeSlot>();
  const [eventsLoading, setEventsLoading] = useState(true);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>();
  const [events, setEvents] = useState<MappedEvents>({});
  const [hasApiError, setHasApiError] = useState<boolean>(false);
  const [autoRefreshIntervalId, setAutoRefreshIntervalId] = useState<
    number | undefined
  >();
  const initialCallToken = useRef<CancelTokenSource>();

  const fetchTimeSlots = async (
    selectedStore: string,
    cancelToken: CancelToken,
    addLunch = true
  ) => {
    const today = new Date();
    return getTimeSlots(selectedStore, today, cancelToken)
      .then(timeslots => {
        addLunchSlot(timeslots, addLunch, today);
        setTimeSlots(timeslots);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          showToast(
            ToastType.Error,
            'Something went wrong while fetching time slots, please try again!'
          );
        }
      });
  };

  const fetchEvents = async (selectedStore: string, cancelToken: CancelToken) =>
    getEvents(selectedStore, format(new Date(), 'MM/dd/yyyy'), cancelToken)
      .then(eventsResponse => {
        const todoEvents = eventsResponse?.filter((event: any) => {
          return event.status === EventStatus.ToDo;
        });
        setEvents(formatEvents(todoEvents));
        setHasApiError(false);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          setHasApiError(true);
          showToast(
            ToastType.Error,
            'Something went wrong while fetching events, please try again!'
          );
        }
      });

  const fetchUpcomingEvents = (showLoader = true) => {
    if (!selectedStore) return;

    showLoader && setEventsLoading(true);

    if (initialCallToken.current) {
      initialCallToken.current.cancel();
    }

    initialCallToken.current = getCancelTokenSource();

    const promises = [
      fetchTimeSlots(selectedStore, initialCallToken.current.token),
      fetchEvents(selectedStore, initialCallToken.current.token)
    ];

    Promise.all(promises).finally(() => showLoader && setEventsLoading(false));
  };

  useEffect(() => {
    fetchUpcomingEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  useEffect(() => {
    if (reloadUpcomingEvents) {
      fetchUpcomingEvents();
      setReloadUpcomingEvents(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadUpcomingEvents]);

  useEffect(() => {
    const now = new Date();
    const upcomingTimeslot = timeSlots?.find(
      ({ startTime, endTime, lunchBreak }) => {
        if (lunchBreak) return false;

        return (
          (isAfter(now, startTime) && isBefore(now, endTime)) ||
          (isBefore(now, startTime) && isBefore(now, endTime))
        );
      }
    );

    if (upcomingTimeslot) {
      setTimeSlot(upcomingTimeslot);
      setStartTime(format(upcomingTimeslot.startTime, 'h a'));
      setEndTime(format(upcomingTimeslot.endTime, 'h a'));
    }
  }, [activeTimeSlot, timeSlots]);

  useEffect(() => {
    if (!pauseAutoRefresh) {
      const intervalId = setInterval(
        fetchUpcomingEvents,
        3 * TIME.MINUTES,
        false
      );

      setAutoRefreshIntervalId(intervalId);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      autoRefreshIntervalId && clearInterval(autoRefreshIntervalId);
      setAutoRefreshIntervalId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseAutoRefresh]);

  const getStatus = (): ListStatus => {
    if (eventsLoading) return 'loading';
    if (hasApiError) return 'apiError';

    return timeSlot && events[timeSlot.id]?.length ? 'success' : 'empty';
  };

  return (
    <>
      <div
        data-testid={upcomingEventsTitleTestId}
        className={styles.selectionTitle}
      >
        Upcoming Events:{' '}
        {startTime && <span>{`${startTime} To ${endTime}`}</span>}
      </div>
      <div
        className={clsx(
          styles.upcomingEventsContent,
          !(timeSlot && events[timeSlot.id]?.length > 0) && styles.emptyEvents
        )}
        data-testid={upcomingEventsListTestId}
      >
        {
          {
            loading: <EventsSkeleton className={styles.loading} />,
            apiError: (
              <ApiError text="The Upcoming Events are not available at this time." />
            ),
            empty: <EmptyEvent />,
            success:
              timeSlot &&
              !!events[timeSlot.id]?.length &&
              events[timeSlot.id]?.map(event => (
                <EventCard
                  key={event.eventId}
                  event={event}
                  className={styles.upcomingEventsCard}
                  onClick={() => onEventClick(event)}
                />
              ))
          }[getStatus()]
        }
      </div>
    </>
  );
};
