import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { SearchFormData } from '../../components/Calendar/NewEvent/SelectCustomer/SelectCustomer';
import { Customer } from '../../domain/Customer/Customer';
import { Agreement } from '../../domain/Customer/Agreement';
import { EventDetailsFormData } from '../../components/Calendar/NewEvent/EventDetails/EventDetailsForm/EventDetailsForm';
import { isEqual } from 'lodash';
import { Item } from '../../domain/Customer/Item';

interface SelectCustomerState {
  readonly searchFormData?: SearchFormData;
  readonly customers?: Customer[];
  readonly selectedCustomer?: Customer;
}

interface NewEventState {
  readonly selectCustomer: SelectCustomerState;
  readonly selectedAgreement?: Agreement;
  readonly selectedItems?: any;
  readonly eventDetails: EventDetailsFormData;
  readonly dirty: boolean;
}

interface NewEventDispatch {
  setSearchFormData: (data: SearchFormData) => void;
  setCustomers: (data?: Customer[]) => void;
  setSelectedCustomer: (customer?: Customer) => void;
  setSelectedAgreement: (agreement?: Agreement) => void;
  setSelectedItems: (items?: Item[]) => void;
  setEventDetails: (eventDetails: EventDetailsFormData) => void;
  clearState: () => void;
}

export const NewEventStateContext = createContext<NewEventState | undefined>(
  undefined
);
export const NewEventDispatchContext = createContext<
  NewEventDispatch | undefined
>(undefined);

export const useNewEventState = () => {
  const context = useContext(NewEventStateContext);
  if (context === undefined) {
    throw new Error('useNewEventState must be used within a NewEventProvider');
  }
  return context;
};

export const useNewEventDispatch = () => {
  const context = useContext(NewEventDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNewEventDispatch must be used within a NewEventProvider'
    );
  }
  return context;
};

export const defaultSearchFormData = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  activeAgreements: false
};

export const defaultEventDetails = {
  address: '',
  eventType: '',
  timeSlot: {
    date: new Date(),
    timeSlotId: ''
  },
  coworkersRequired: '',
  description: '',
  note: ''
};

export const NewEventProvider = (props: { children: ReactNode }) => {
  const [searchFormData, setSearchFormData] = useState<SearchFormData>(
    defaultSearchFormData
  );
  const [customers, setCustomers] = useState<Customer[] | undefined>();
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >();
  const [selectedAgreement, setSelectedAgreement] = useState<
    Agreement | undefined
  >();
  const [selectedItems, setSelectedItems] = useState<Item[] | undefined>();

  const [eventDetails, setEventDetails] = useState<EventDetailsFormData>(
    defaultEventDetails
  );

  useEffect(
    function resetSelectedCustomer() {
      if (!selectedCustomer || !customers) return;

      if (!customers.find(c => c.partyId === selectedCustomer?.partyId)) {
        setSelectedCustomer(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]
  );

  const clearState = useCallback(() => {
    setSelectedCustomer(undefined);
    setCustomers(undefined);
    setSelectedAgreement(undefined);
    setSelectedItems(undefined);
    setEventDetails(defaultEventDetails);
    setSearchFormData(defaultSearchFormData);
  }, []);

  const dirty =
    !!selectedCustomer ||
    !!customers?.length ||
    !!selectedAgreement ||
    !!selectedItems?.length ||
    !isEqual(searchFormData, defaultSearchFormData) ||
    !isEqual(eventDetails, defaultEventDetails);

  const dispatch = useMemo(
    () => ({
      setSearchFormData,
      setCustomers,
      setSelectedCustomer,
      setSelectedAgreement,
      setSelectedItems,
      setEventDetails,
      clearState
    }),
    [
      setSearchFormData,
      setCustomers,
      setSelectedCustomer,
      setEventDetails,
      clearState
    ]
  );

  return (
    <NewEventStateContext.Provider
      value={{
        selectCustomer: {
          searchFormData,
          customers,
          selectedCustomer
        },
        selectedAgreement,
        selectedItems,
        eventDetails,
        dirty
      }}
    >
      <NewEventDispatchContext.Provider value={dispatch}>
        {props.children}
      </NewEventDispatchContext.Provider>
    </NewEventStateContext.Provider>
  );
};
