import React, { useState } from 'react';
import { Card } from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faCheck,
  faCommentDots,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { format, isSameDay } from 'date-fns';
import clsx from 'clsx';
import { generatePath } from 'react-router-dom';

import styles from './TaskCard.module.scss';

import { completed, cancelled, Task } from '../../../../../domain/Task/Task';
import { Priority } from '../../../../common/Priority/Priority';
import { Category } from '../../../../common/Category/Category';
import { TaskSideModal } from '../../../TaskSideModal/TaskSideModal';
import { PrintPreviewModal } from '../../../../common/PrintPreviewModal/PrintPreviewModal';
import { DapRoute } from '../../../../../config/route-config';

export const taskCardTestId = 'taskCardTestId';

export interface TaskCardProps {
  task: Task;
}

export const TaskCard = ({ task }: TaskCardProps) => {
  const [viewSideModal, setViewSideModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [printMode, setPrintMode] = useState(false);

  const date = new Date(task.dueDate);

  const getFormattedDate = () => {
    if (isSameDay(date, new Date())) {
      return 'Today';
    } else {
      return format(date, 'MM/dd/yy');
    }
  };

  const handleCardClick = () => {
    setEditMode(false);
    setViewSideModal(true);
  };

  const handleDelete = () => {
    setViewSideModal(false);
  };

  const handleClose = () => {
    setViewSideModal(false);
  };

  const getDetailsURL = () => {
    const { isRenderedByContainer } = window;
    const path = generatePath(DapRoute.TaskDetails, {
      taskId: task.id
    });

    const suffix = isRenderedByContainer ? '' : '/dap';
    return `${suffix}${path}#fullscreen`;
  };

  return (
    <>
      {viewSideModal && (
        <TaskSideModal
          editMode={editMode}
          onClose={handleClose}
          handleDelete={handleDelete}
          handlePrint={() => setPrintMode(true)}
          task={task}
        />
      )}

      {printMode && (
        <PrintPreviewModal
          url={getDetailsURL()}
          onClose={() => setPrintMode(false)}
        />
      )}

      <div className={styles.cardWrap}>
        <Card
          className={clsx(
            styles.task,
            completed(task.taskStatus) && styles.completed,
            cancelled(task.taskStatus) && styles.cancelled
          )}
          data-testid={taskCardTestId}
          onClick={handleCardClick}
        >
          <div className={styles.title}>
            <div className={styles.name}>{task.title}</div>
            {completed(task.taskStatus) && (
              <FontAwesomeIcon className={styles.iconStyle} icon={faCheck} />
            )}
            {cancelled(task.taskStatus) && (
              <FontAwesomeIcon
                className={clsx(styles.iconStyle, styles.cancelColor)}
                icon={faTimes}
              />
            )}
          </div>
          <div className={styles.description}>{task.description}</div>
          <div className={styles.footer}>
            <div className={styles.assignee}>
              <span>{task.assignee.name}</span>
            </div>
            <Category className={styles.category} category={task.category} />
            <Priority className={styles.priority} priority={task.priority} />
            <div className={styles.dueDate}>
              <FontAwesomeIcon icon={faCalendarDay} />
              {getFormattedDate()}
              {', '}
              {format(date, 'p')}
            </div>
            <div className={styles.notes}>
              <FontAwesomeIcon icon={faCommentDots} />{' '}
              {task.noteCount < 99 ? task.noteCount : '99+'}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
