import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import { Table } from '@rentacenter/racstrap';

import * as logsApi from '../../../api/historyLog';

import styles from './HistoryLog.module.scss';

import { TaskLog, ParentType } from '../../../domain/HistoryLog/HistoryLog';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventHistoryLog } from './EventHistoryLog';
import { TaskHistoryLog } from './TaskHistoryLog';
import { StoreContext } from '../../../context/store/StoreProvider';

export const historyLogsTestId = 'historyLogsTestId';

export interface HistoryLogProps extends HTMLProps<HTMLDivElement> {
  parentId: string;
  parentType: ParentType;
}

type ListStatus = 'initial' | 'apiError' | 'success' | 'empty';

export const HistoryLog = (props: HistoryLogProps) => {
  const { parentId, parentType, ...rest } = props;

  const { selectedStore } = useContext(StoreContext);

  const [historyLogs, setHistoryLogs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const isTask = parentType === ParentType.Task;

  useEffect(() => {
    setLoading(true);
    setApiError(false);
    if (!selectedStore) return;
    if (isTask) {
      logsApi
        .getTaskHistoryLog(parentId)
        .then((response: TaskLog[]) => {
          setHistoryLogs(
            response.sort(
              (a, b) =>
                new Date(b.actionDate).getTime() -
                new Date(a.actionDate).getTime()
            )
          );
        })
        .catch(() => setApiError(true))
        .finally(() => setLoading(false));
    } else {
      logsApi
        .getEventHistoryLog(selectedStore, parentId)
        .then((response: any) => {
          setHistoryLogs(response.eventHistory);
        })
        .catch(() => setApiError(true))
        .finally(() => setLoading(false));
    }
  }, [isTask, parentId, parentType, selectedStore]);

  const getStatus = (): ListStatus => {
    if (apiError) return 'apiError';

    if (historyLogs) {
      return !loading && historyLogs.length > 0 ? 'success' : 'empty';
    }
    return 'initial';
  };

  return (
    <Table
      classes={{ table: styles.logsTable }}
      data-testid={historyLogsTestId}
      {...rest}
    >
      <thead>
        <tr>
          <th scope="col">{isTask ? 'User' : 'User & Source'}</th>
          <th scope="col">Action & Note</th>
          <th className={styles.dateTimeHeader} scope="col">
            Date & Time
          </th>
        </tr>
      </thead>
      <tbody>
        {
          {
            initial: null,
            empty: (
              <div className={styles.emptyList}>There are no logs yet</div>
            ),
            apiError: (
              <div className={styles.apiError}>
                <span>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  We cannot retrieve the history logs
                </span>
              </div>
            ),
            success: isTask ? (
              <TaskHistoryLog taskLogs={historyLogs} />
            ) : (
              <EventHistoryLog eventLogs={historyLogs} />
            )
          }[getStatus()]
        }
      </tbody>
    </Table>
  );
};
