import React, { HTMLProps } from 'react';

import styles from './EmptyColumn.module.scss';

import emptyIcon from './empty-icon.png';

export const EmptyColumn = ({
  children,
  ...rest
}: HTMLProps<HTMLDivElement>) => {
  return (
    <div {...rest} className={styles.emptyMessage}>
      <img className={styles.emptyIcon} src={emptyIcon} alt="No tasks icon" />
      <div>{children}</div>
    </div>
  );
};
