import { EventType } from '../../../../domain/Calendar/CalendarEvent';
import { withEventSubmit } from '../../../common/EventForm/withEventSubmit';
import { EditBlockTimeEventForm } from './EditBlockTimeEventForm';
import { updateEvent } from '../../../../api/calendar';

export default withEventSubmit(
  EditBlockTimeEventForm,
  EventType.BlockTime,
  updateEvent
);
