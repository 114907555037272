import React from 'react';

import { SetStepProp } from '../NewEvent';
import { SelectedCustomer } from '../SelectedCustomer/SelectedCustomer';
import { SelectedAgreement } from '../SelectedAgreement/SelectedAgreement';
import { SelectedItems } from '../SelectedItems/SelectedItems';
import { EventDetailsForm } from './EventDetailsForm/EventDetailsForm';

export function EventDetails({ setStep }: SetStepProp) {
  return (
    <>
      <SelectedCustomer />
      <SelectedAgreement />
      <SelectedItems />
      <EventDetailsForm setStep={setStep} />
    </>
  );
}
