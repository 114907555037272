import React from 'react';
import { Card } from '@rentacenter/racstrap';
import { Skeleton } from '@material-ui/lab';

import styles from './TasksColumn.module.scss';
import cardStyle from './TaskCard/TaskCard.module.scss';

export const numberOfCards = 3;
export const columnSkeletonTestId = 'columnSkeletonTestId';

export const TasksColumnSkeleton = () => {
  return (
    <div className={styles.listContainer}>
      {new Array(numberOfCards).fill(null).map((item, index) => (
        <Card
          key={index}
          className={cardStyle.task}
          data-testid={columnSkeletonTestId}
        >
          <Skeleton variant="text" height={30} animation="wave" />
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
        </Card>
      ))}
    </div>
  );
};
