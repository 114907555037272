import React, { HTMLProps } from 'react';
import { format, parseISO } from 'date-fns';

import { TaskLog } from '../../../domain/HistoryLog/HistoryLog';

import styles from './HistoryLog.module.scss';

interface TaskHistoryLogProps extends HTMLProps<HTMLDivElement> {
  taskLogs: TaskLog[];
}

export const taskHistoryLogsTestId = 'taskHistoryLogsTestId';

export const TaskHistoryLog = (props: TaskHistoryLogProps) => {
  const { taskLogs } = props;

  return (
    <>
      {taskLogs?.map((historyLog: TaskLog) => (
        <tr key={historyLog.id} data-testid={taskHistoryLogsTestId}>
          <td className={styles.userName}>{historyLog.userName}</td>
          <td className={styles.action}>{historyLog.actionName}</td>
          <td className={styles.dateColumn}>
            <>{format(parseISO(historyLog.actionDate), 'MMM dd yyyy')}</>
            <div className={styles.time}>
              {format(parseISO(historyLog.actionDate), 'h:mm a')}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
