import { AxiosResponse } from 'axios';

export interface Error {
  code: string;
  message: string;
}

export interface APIError {
  traceId: string;
  requestId: string;
  errors: Error[];
}

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  const isClientError = response?.status === 400 || response?.status === 403;

  if (isClientError && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};
