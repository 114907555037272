import React, { useState } from 'react';
import { SideDrawerModal, SideDrawerModalContent } from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPrint } from '@fortawesome/free-solid-svg-icons';

import styles from './TaskSideModal.module.scss';

import { Task, completed, cancelled } from '../../../domain/Task/Task';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';
import { TaskDetails } from '../TaskDetails/TaskDetails';
import { EditTask } from '../EditTask/EditTask';

export type TaskSideModalProps = {
  editMode?: boolean;
  onClose: () => void;
  handleDelete?: () => void;
  handlePrint: () => void;
  task: Task;
};

export const taskSideModalEditBtnTestId = 'taskSideModalEditBtnTestId';
export const taskSideModalDeleteBtnTestId = 'taskSideModalDeleteBtnTestId';
export const taskSideModalPrintBtnTestId = 'taskSideModalPrintBtnTestId';

export const TaskSideModal = (props: TaskSideModalProps) => {
  const { onClose, task, editMode, handlePrint } = props;
  const [isEditMode, setEditMode] = useState<boolean>(editMode || false);
  const [disableModalClose, setDisableModalClose] = useState<boolean>(false);

  const { canSubmit, isUpdateTaskForbidden } = useUserPermissions();

  const cancelledOrCompleted = cancelled(task) || completed(task);

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSave = () => {
    toggleEditMode();
    onClose();
  };

  const print = () => {
    onClose();
    handlePrint();
  };

  const renderActionBar = () => {
    const canUpdateTask = canSubmit && !isUpdateTaskForbidden;
    return (
      <>
        <div className={styles.actionBar}>
          {canUpdateTask && (
            <>
              {!cancelledOrCompleted && (
                <>
                  <FontAwesomeIcon
                    data-testid={taskSideModalEditBtnTestId}
                    cursor="pointer"
                    icon={faPen}
                    onClick={toggleEditMode}
                  />
                  <span className={styles.divider}></span>
                </>
              )}
              <FontAwesomeIcon
                data-testid={taskSideModalPrintBtnTestId}
                cursor="pointer"
                icon={faPrint}
                onClick={print}
              />
            </>
          )}
        </div>
      </>
    );
  };
  const title = isEditMode ? 'Edit Task Details' : 'Task Details';

  return (
    <SideDrawerModal
      isOpen
      title={title}
      onClose={onClose}
      renderActionBar={isEditMode ? undefined : renderActionBar}
      disableClose={disableModalClose}
    >
      <SideDrawerModalContent>
        {isEditMode ? (
          <EditTask
            task={task}
            onSave={handleSave}
            onCancel={toggleEditMode}
            onPending={setDisableModalClose}
          />
        ) : (
          <TaskDetails task={task} />
        )}
      </SideDrawerModalContent>
    </SideDrawerModal>
  );
};
