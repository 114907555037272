import React, { FC, useContext, useEffect, useState } from 'react';
import { Select, SelectOption, SelectProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';

import {
  getEmptyValidationMessage,
  TaskFormFields
} from '../../CreateTask/CreateTask';
import { getEmployeeList } from '../../../../api/tasks';
import { StoreContext } from '../../../../context/store/StoreProvider';
import { noOp } from '../../../../utils/helper-functions';
import { Employee } from '../../../../domain/User/Employee';

export const makeEmployeesOptions = (employeesList: Employee[]) => {
  return employeesList?.map((employee: Employee) => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.userId
  }));
};

type TaskAssigneesSelectProps = Partial<SelectProps> & {
  onChange: (option: SelectOption) => void;
};

export const TaskAssigneesSelect: FC<TaskAssigneesSelectProps> = ({
  onChange: handleChange = noOp
}) => {
  const { control, errors } = useFormContext();
  const { selectedStore } = useContext(StoreContext);
  const [pending, setPending] = useState<boolean>(false);
  const [employeesApiError, setEmployeesApiError] = useState<string>();
  const [employeesOptions, setEmployeesOptions] = useState<SelectOption[]>();

  useEffect(() => {
    if (!selectedStore) return;
    setPending(true);

    getEmployeeList(selectedStore)
      .then(response => {
        const employeeList = response?.employeeInfoList;
        const options = makeEmployeesOptions(employeeList);
        setEmployeesOptions(options);
      })
      .catch(() => {
        setEmployeesApiError('The values cannot be retrieved at the moment');
      })
      .finally(() => {
        setPending(false);
      });
  }, [selectedStore]);

  return (
    <Controller
      control={control}
      name={TaskFormFields.assignee}
      rules={{ required: true }}
      render={({ ref, onChange, ...rest }, { invalid }) => (
        <Select
          options={employeesOptions}
          required={true}
          onChange={value => {
            const option =
              employeesOptions &&
              employeesOptions.find(option => option.value === value);
            option && handleChange(option);
            onChange(value);
          }}
          errorMessage={
            errors?.assignee?.message ||
            employeesApiError ||
            getEmptyValidationMessage(invalid)
          }
          label="Assignee"
          placeholder="Select"
          size="large"
          isLoading={pending}
          {...rest}
        />
      )}
    />
  );
};
