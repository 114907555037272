import { SelectOption } from '@rentacenter/racstrap';
import { TimeSlot } from '../domain/Calendar/Timeslot';
import { format } from 'date-fns';

export const buildOptions = (
  options: Record<string, string>
): SelectOption[] => {
  return Object.keys(options).map(key => ({
    label: options[key],
    value: key
  }));
};

export const buildTimeSlotOptions = (timeSlots: TimeSlot[]) => {
  return timeSlots.map(({ id, startTime, endTime }) => {
    return {
      value: id,
      label: `${format(startTime, 'h a')} to ${format(endTime, 'h a')}`
    };
  });
};
