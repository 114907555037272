import { CalendarEvent, StoreEvent } from '../domain/Calendar/CalendarEvent';
import { cancelled, Task } from '../domain/Task/Task';

/**
 * Used as default value for a prop that is optional, instead of
 * checking everytime if it is defined before calling it
 */
export const noOp = () => {};

export const handleSorting = (a: Task, b: Task, result: number) => {
  if (cancelled(a.taskStatus) && cancelled(b.taskStatus)) {
    return result;
  } else if (cancelled(a.taskStatus)) {
    return 1;
  } else if (cancelled(b.taskStatus)) {
    return -1;
  }
  return result;
};

export function isCalendarEvent(
  event: CalendarEvent | StoreEvent
): event is CalendarEvent {
  return !!(event as CalendarEvent)?.customerInformation;
}
