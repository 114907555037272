import { ToastType, useToastActionsContext } from '@rentacenter/racstrap';
import { AxiosError, CancelToken } from 'axios';
import { format, isSameDay } from 'date-fns';
import React, { ReactNode, useState, createContext } from 'react';
import * as api from '../../api/calendar';
import { dateFormat, getMockTimeSlots } from '../../api/calendar';
import { TimeSlotValue } from '../../components/Calendar/TimeSlotSelector/TimeSlotSelector';
import {
  CalendarEvent,
  EventPriority,
  EventSourceType,
  EventStatus,
  EventStatusActionType,
  EventType,
  TimeSlotStatus
} from '../../domain/Calendar/CalendarEvent';
import { createTimeslot, TimeSlot } from '../../domain/Calendar/Timeslot';
import { APIError, getErrorMessage } from '../../utils/errorHandlerService';
import { isSameOrAfter, isSameOrBefore } from '../../utils/time';
import {
  changeStatus,
  getEventStatusByActionType,
  removeEventFromTimeSlot,
  sortPriority,
  putEventIntoCorrespondingTimeSlot,
  removeDeletedBlockTimeEvents,
  formatEvents,
  setSource
} from './eventsActions';

export const selectedDateFormat = 'MM/dd/yyyy';

export type Filters = {
  EventType: EventType[];
  EventStatus: EventStatus[];
  EventSource: EventSourceType[];
  showDeletedEvents: boolean;
};

export type MappedEvents = Record<string, CalendarEvent[]>;
export interface EventsState {
  events: MappedEvents;
  selectedDate: Date;
  timeSlots: TimeSlot[];
  isStoreClosed: boolean;
  hasApiError: boolean;
  filters: Filters;
}

export interface EventsDispatchState {
  updateEvent: (
    originalEvent: CalendarEvent,
    storeId: string,
    updatedEvent: CalendarEvent
  ) => Promise<void | CalendarEvent>;
  updateEventStatus: (
    eventId: string,
    storeId: string,
    timeSlotId: string,
    eventStatusActionType: EventStatusActionType,
    source: EventSourceType,
    reason?: string,
    skipErrorMessage?: boolean
  ) => Promise<void | CalendarEvent>;
  setSelectedDate: (date: Date) => void;
  reloadUpcomingEvents: boolean;
  setReloadUpcomingEvents: (shouldReload: boolean) => void;
  fetchTimeSlots: (
    selectedStore: string,
    selectedDate: Date,
    cancelToken: CancelToken
  ) => Promise<void>;
  fetchEvents: (
    selectedStore: string,
    selectedDate: Date,
    cancelToken: CancelToken
  ) => Promise<void>;
  setFilters: (filters: Filters) => void;
  unblockTimeslot: (
    storeId: string,
    eventSource: EventSourceType,
    originalEvent: CalendarEvent
  ) => Promise<void | CalendarEvent>;
  reopenWithNewTimeSlot: (
    eventId: string,
    storeId: string,
    source: EventSourceType,
    currentTimeSlotId: string,
    newTimeSlot: TimeSlotValue
  ) => Promise<void>;
}

export const EventsStateContext = createContext<EventsState>({} as EventsState);
export const EventsDispatchContext = createContext<EventsDispatchState>(
  {} as EventsDispatchState
);

export const addLunchSlot = (
  timeslots: TimeSlot[],
  addLunch: boolean,
  selectedDate: Date
) => {
  const lunchTimeslot = createTimeslot(
    {
      timeSlotId: '-1',
      startTime: '12:00:00',
      endTime: '13:00:00',
      status: TimeSlotStatus.Busy
    },
    selectedDate
  );
  lunchTimeslot.lunchBreak = true;

  if (addLunch) {
    // insert lunchTimeslot into apropriate timeslot
    for (let index = 0; index < timeslots.length - 1; index++) {
      const timeslot = timeslots[index];
      const nextTimeslot = timeslots[index + 1];

      if (
        isSameOrAfter(lunchTimeslot.startTime, timeslot.endTime) &&
        isSameOrBefore(lunchTimeslot.endTime, nextTimeslot.startTime)
      ) {
        timeslots.splice(index + 1, 0, lunchTimeslot);
        break;
      }
    }
  }
};

export const updateEventsWithoutFetch = (
  updatedEvent: CalendarEvent,
  currentTimeSlotId: string,
  events: MappedEvents,
  setEvents: (events: MappedEvents) => void
) => {
  const updatedEventShallowCopy = { ...updatedEvent };
  // Only events that have todo status can be updated.
  // Here we are overriding the status and priority, in order to handle the case when
  // user tries to reopen an event, but has to choose an another time slot.
  // Technically: first we update the event's time slot and than we change the trigger the status change request.
  updatedEventShallowCopy.status = EventStatus.ToDo;
  updatedEventShallowCopy.priority = EventPriority[EventStatus.ToDo];

  const eventsFromCurrentTimeSlotWithoutTheUpdatedEvent = removeEventFromTimeSlot(
    updatedEventShallowCopy.eventId,
    currentTimeSlotId,
    events
  );
  const eventsWithUpdatedEvent = putEventIntoCorrespondingTimeSlot(
    updatedEventShallowCopy,
    eventsFromCurrentTimeSlotWithoutTheUpdatedEvent
  );

  sortPriority(eventsWithUpdatedEvent[updatedEvent.timeSlot.timeSlotId]);
  setEvents(eventsWithUpdatedEvent);
};

export const reopenWithNewTimeSlotOnSuccess = (
  selectedDate: Date | number,
  currentTimeSlotId: string,
  updatedEvent: CalendarEvent,
  events: MappedEvents,
  setEvents: (events: MappedEvents) => void,
  setSelectedDate: (date: Date) => void,
  setReloadUpcomingEvents: (isReloadEnabled: boolean) => void
) => {
  const { eventDate } = updatedEvent;
  const newEventDate = new Date(eventDate);
  // If the new time slow is already rendered on the screen
  if (format(selectedDate, selectedDateFormat) === eventDate) {
    // then update existing data without fetching
    updateEventsWithoutFetch(
      updatedEvent,
      currentTimeSlotId,
      events,
      setEvents
    );
  } else {
    // otherwise fetch data(events and timeslots) from the BE
    setSelectedDate(newEventDate);
  }

  if (isSameDay(newEventDate, new Date())) {
    setReloadUpcomingEvents(true);
  }
};

export const EventsProvider = (props: { children: ReactNode }) => {
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [events, setEvents] = useState<MappedEvents>({});
  const [filters, setFilters] = useState<Filters>({
    EventType: [],
    EventStatus: [],
    EventSource: [],
    showDeletedEvents: false
  });
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [reloadUpcomingEvents, setReloadUpcomingEvents] = useState(false);
  const [hasApiError, setHasApiError] = useState<boolean>(false);

  const { showToast } = useToastActionsContext();

  const fetchTimeSlots = async (
    selectedStore: string,
    selectedDate: Date,
    cancelToken: CancelToken,
    addLunch = true
  ) => {
    if (timeSlots.length === 0) {
      const mockSlots = getMockTimeSlots(selectedDate);
      addLunchSlot(mockSlots, addLunch, selectedDate);
      setTimeSlots(mockSlots);
    }

    api
      .getTimeSlots(selectedStore, selectedDate, cancelToken)
      .then(timeslots => {
        addLunchSlot(timeslots, addLunch, selectedDate);
        setTimeSlots(timeslots);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          showToast(
            ToastType.Error,
            'Something went wrong while fetching time slots, please try again!'
          );
        }
      });
  };

  const fetchEvents = async (
    selectedStore: string,
    selectedDate: Date,
    cancelToken: CancelToken
  ) =>
    api
      .getEvents(
        selectedStore,
        format(selectedDate, selectedDateFormat),
        cancelToken
      )
      .then(eventsResponse => {
        const eventsWithoutDeletedBlockTime = removeDeletedBlockTimeEvents(
          eventsResponse
        );
        setEvents(formatEvents(eventsWithoutDeletedBlockTime));
        setHasApiError(false);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          setHasApiError(true);

          showToast(
            ToastType.Error,
            'Something went wrong while fetching events, please try again!'
          );
        }
      });

  const updateEventStatus = (
    eventId: string,
    storeId: string,
    timeSlotId: string,
    eventStatusActionType: EventStatusActionType,
    source: EventSourceType,
    reason?: string,
    skipErrorMessage?: boolean
  ) => {
    const onSuccess = () => {
      const updatedEvents = { ...events };
      const newStatus = getEventStatusByActionType(eventStatusActionType);

      const targetEvent = changeStatus(
        updatedEvents[timeSlotId],
        eventId,
        newStatus
      );

      setSource(targetEvent, source);

      const getEventActionText = () =>
        eventStatusActionType === EventStatusActionType.Cancel ||
        eventStatusActionType === EventStatusActionType.Reopen
          ? `${eventStatusActionType}ed`
          : `${eventStatusActionType}d`;

      if (targetEvent) {
        sortPriority(updatedEvents[targetEvent.timeSlot.timeSlotId]);
        setEvents(updatedEvents);
      }

      setReloadUpcomingEvents(true);
      showToast(
        ToastType.Success,
        `The event has been successfully ${getEventActionText()}`
      );
    };
    const onError = (error: AxiosError<APIError>) => {
      if (!skipErrorMessage) {
        const errorMessage = getErrorMessage(error.response);
        const message = errorMessage
          ? errorMessage
          : `Something went wrong when trying to ${eventStatusActionType} your event`;
        showToast(ToastType.Error, message);
      }

      return Promise.reject(error?.response?.status);
    };

    if (
      eventStatusActionType === EventStatusActionType.Cancel ||
      eventStatusActionType === EventStatusActionType.Delete
    ) {
      return api
        .updateEventStatusWithPayload(
          eventId,
          storeId,
          eventStatusActionType,
          source,
          reason
        )
        .then(onSuccess)
        .catch(onError);
    }
    return api
      .updateEventStatus(
        eventId,
        storeId,
        eventStatusActionType,
        source,
        reason
      )
      .then(onSuccess)
      .catch(onError);
  };

  /*
  Triggers the update of an event.
  This method is only used for updating customer events.
  For store and block time events, the forms are using the withEventsSubmit HOC.
  If update is successful, event is removed 
  */
  const updateEvent = (
    originalEvent: CalendarEvent,
    storeId: string,
    updatedEvent: CalendarEvent
  ) => {
    return api
      .updateEvent(storeId, updatedEvent, originalEvent.eventId)
      .then((response: CalendarEvent) => {
        let updatedEvents = removeEventFromTimeSlot(
          response.eventId,
          originalEvent.timeSlot.timeSlotId,
          events
        );
        if (isSameDay(new Date(response.eventDate), new Date())) {
          updatedEvents = putEventIntoCorrespondingTimeSlot(
            response,
            updatedEvents
          );
        }

        setEvents(updatedEvents);
        setReloadUpcomingEvents(true);
        showToast(
          ToastType.Success,
          'Your Event has been successfully updated!'
        );
      })
      .catch((error: AxiosError<APIError>) => {
        const errorMessage = getErrorMessage(error.response);
        const message = errorMessage
          ? errorMessage
          : 'Something went wrong when trying to update your event, please try again!';
        showToast(ToastType.Error, message);
      });
  };

  /*
  Handles the case when user tries to reopen an event, but the corresponding time slot is fully booked,
  therefore the user has to choose an another time slot.
  Currently we don't have an API to handle this case, but we are:
  1. Updating the event with the new time slot.
  2. Reopening the event, if update was successful.
  3. Setting the calendar date to the newly selected time slot, in order to show the updated event.
  4. Refreshing the upcoming section, only if the new time slot date is today.
  */
  const reopenWithNewTimeSlot = async (
    eventId: string,
    storeId: string,
    source: EventSourceType,
    currentTimeSlotId: string,
    newTimeSlot: TimeSlotValue
  ) => {
    try {
      const eventToUpdate = events[currentTimeSlotId].find(
        event => event.eventId === eventId
      );

      if (!eventToUpdate || !newTimeSlot || !newTimeSlot.timeSlotId) return;
      const payload = {
        type: eventToUpdate?.type,
        requiredCoworkers: eventToUpdate.requiredCoworkers,
        partyId: eventToUpdate?.customerInformation?.partyId,
        eventDate: newTimeSlot.date ? format(newTimeSlot.date, dateFormat) : '',
        timeSlot: { timeSlotId: newTimeSlot.timeSlotId },
        source: source
      } as any;

      const updatedEvent = await api.updateEvent(storeId, payload, eventId);
      await api.updateEventStatus(
        eventId,
        storeId,
        EventStatusActionType.Reopen,
        source
      );

      reopenWithNewTimeSlotOnSuccess(
        selectedDate,
        currentTimeSlotId,
        updatedEvent,
        events,
        setEvents,
        setSelectedDate,
        setReloadUpcomingEvents
      );

      showToast(ToastType.Success, 'Your Event has been successfully updated!');
    } catch (error) {
      const errorMessage = getErrorMessage(error.response);
      const message = errorMessage
        ? errorMessage
        : 'Something went wrong when trying to update your event, please try again!';
      showToast(ToastType.Error, message);
    }
  };

  const unblockTimeslot = (
    storeId: string,
    eventSource: EventSourceType,
    originalEvent: CalendarEvent
  ) => {
    return api
      .updateEventStatus(
        originalEvent.startDate
          ? originalEvent.recurringAppointmentId!
          : originalEvent.eventId,
        storeId,
        originalEvent.startDate
          ? EventStatusActionType.CancelRecur
          : EventStatusActionType.Delete,
        eventSource
      )
      .then(() => {
        const updatedEvents = { ...events };

        const filteredEvents =
          events[originalEvent.timeSlot.timeSlotId]?.filter(
            event => event.eventId !== originalEvent.eventId
          ) || [];

        updatedEvents[originalEvent.timeSlot.timeSlotId] = filteredEvents;

        setEvents(updatedEvents);
        setReloadUpcomingEvents(true);
        showToast(ToastType.Success, 'The timeslot has been unblocked!');
      })
      .catch((error: AxiosError<APIError>) => {
        const errorMessage = getErrorMessage(error.response);
        const message = errorMessage
          ? errorMessage
          : `Something went wrong when trying to unblock your event`;
        showToast(ToastType.Error, message);
      });
  };

  const isStoreClosed = !timeSlots.length;

  return (
    <EventsStateContext.Provider
      value={{
        events,
        selectedDate,
        timeSlots,
        isStoreClosed,
        hasApiError,
        filters
      }}
    >
      <EventsDispatchContext.Provider
        value={{
          updateEvent,
          updateEventStatus,
          setSelectedDate,
          fetchTimeSlots,
          fetchEvents,
          reloadUpcomingEvents,
          setReloadUpcomingEvents,
          setFilters,
          unblockTimeslot,
          reopenWithNewTimeSlot
        }}
      >
        {props.children}
      </EventsDispatchContext.Provider>
    </EventsStateContext.Provider>
  );
};
