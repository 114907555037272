import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';

import styles from '../../common/EventForm/EventFields.module.scss';

import { EventFormLayout } from '../../common/EventForm/EventFormLayout';
import { DescriptionField } from '../../common/DescriptionField/DescriptionField';
import { IE11SubmitButton } from '../../common/IE11SubmitButton/IE11SubmitButton';
import { TimeSlotField } from '../../common/TimeSlotField/TimeSlotField';
import { EventsStateContext } from '../../../context/events/EventsProvider';
import { TimeSlotValue } from '../TimeSlotSelector/TimeSlotSelector';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';

export interface BlockTimeEventFormData {
  readonly description: string;
  readonly timeSlot: TimeSlotValue;
}

enum FormFields {
  Description = 'description',
  TimeSlot = 'timeSlot'
}

const formId = 'blockTimeEventFormId';

export interface BlockTimeEventFormProps {
  readonly pending: boolean;
  readonly onSubmit: (formData: BlockTimeEventFormData) => void;
}

export const BlockTimeEventForm: FC<BlockTimeEventFormProps> = ({
  pending,
  onSubmit
}) => {
  const [hasError, setHasError] = useState(false);
  const { selectedDate } = useContext(EventsStateContext);

  const { isCreateEventForbidden } = useUserPermissions();

  const methods = useForm<BlockTimeEventFormData>({
    mode: 'onChange',
    defaultValues: {
      description: '',
      timeSlot: {
        date: selectedDate,
        timeSlotId: ''
      }
    }
  });

  const handleTimeSlotError = () => setHasError(true);

  return (
    <EventFormLayout
      title="Create Time-Blocked Event"
      sectionTitle="Time-Blocked Event Details"
      sectionDescription="Time-Blocked Events on the Store Calendar will mean that
     there can not be any other types of events in those timeslots."
      saveButtonText="Create Event"
      pending={pending}
      hasError={hasError}
      isDisabled={isCreateEventForbidden}
    >
      <FormProvider {...methods}>
        <form
          id={formId}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.container}
        >
          <div className={clsx(styles.row)}>
            <DescriptionField
              fieldName={FormFields.Description}
              disabled={pending}
            />
          </div>
          <div className={clsx(styles.row, styles.fullWidth)}>
            <TimeSlotField
              isBlockTimeEvent
              fieldName={FormFields.TimeSlot}
              onError={handleTimeSlotError}
            />
          </div>
          <IE11SubmitButton formId={formId} />
        </form>
      </FormProvider>
    </EventFormLayout>
  );
};
