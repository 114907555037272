import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@rentacenter/racstrap';

import { requiredErrorMessage } from '../constants';

export const fieldName = 'description';

interface DescriptionFieldProps {
  fieldName: string;
  disabled: boolean;
}

export const DescriptionField: FC<DescriptionFieldProps> = ({
  fieldName,
  disabled
}) => {
  const { control, errors, clearErrors } = useFormContext<any>();
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        maxLength: {
          value: 256,
          message:
            'The description text is limited to a number of 256 characters.'
        },
        required: requiredErrorMessage
      }}
      render={({ onChange, value, name }, { invalid }) => (
        <Input
          name={name}
          value={value}
          label="Description"
          placeholder="Write a description"
          size="large"
          invalid={invalid}
          errorMessage={errors?.description?.message}
          onChange={(e: any) => {
            clearErrors(fieldName);
            onChange(e);
          }}
          required
          disabled={disabled}
        />
      )}
    />
  );
};
