import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@rentacenter/racstrap';

import styles from './BackButton.module.scss';

interface Props {
  readonly onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  readonly disabled?: boolean;
}

export function BackButton(props: Props) {
  return (
    <Button
      variant="link"
      color="primary"
      icon={<ArrowBackIcon />}
      className={styles.button}
      data-testid="backButton"
      {...props}
    >
      Back
    </Button>
  );
}
