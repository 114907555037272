import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import {
  faFileContract,
  faTruck,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelTokenSource } from 'axios';

import styles from './StoreGoals.module.scss';

import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader';
import { StoreGoalCard } from './StoreGoalCard/StoreGoalCard';
import { getCancelTokenSource } from '../../../api/client';
import * as api from '../../../api/goals';
import { ApiError } from '../../common/ApiError/ApiError';
import { MappedGoals, StoreGoalResponse } from '../../../domain/Calendar/Goal';
import { StoreContext } from '../../../context/store/StoreProvider';

export const storeGoalsTestId = 'storeGoalsTestId';

export interface StoreGoalsProps extends HTMLProps<HTMLDivElement> {
  startDate: Date;
}

// eslint-disable-next-line
export const GoalsList = (props: StoreGoalsProps) => {
  const { startDate } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [goals, setGoals] = useState<MappedGoals>({} as MappedGoals);
  const { selectedStore, isFranchisee, storeGoalsSelectedDate } = useContext(
    StoreContext
  );

  // used the timestamp as the week picker returns a new date object
  // for any date in the same week, triggering an unnecessary render
  const unixStartDate = startDate.getTime();
  const unixStoreGoalsStartDate = storeGoalsSelectedDate?.getTime();

  const handleSave = (goal: StoreGoalResponse) => {
    setGoals(previous => {
      return {
        ...previous,
        [goal.metricType]: {
          ...previous[goal.metricType],
          ...goal
        }
      };
    });
  };

  function fetchStoreGoals(cancelToken: CancelTokenSource) {
    if (!selectedStore) {
      return;
    }

    setLoading(true);
    setError(false);
    if (!isFranchisee) {
      api
        .getGoalsCoreStore(
          selectedStore,
          storeGoalsSelectedDate,
          cancelToken.token
        )
        .then(response => {
          setGoals(response);
        })
        .catch(err => {
          if (!err.__CANCEL__) setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .getGoals(selectedStore, startDate, cancelToken.token)
        .then(response => {
          setGoals(response);
        })
        .catch(err => {
          if (!err.__CANCEL__) setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(
    function maintainStoreGoals() {
      const cancelToken = getCancelTokenSource();

      fetchStoreGoals(cancelToken);
      return () => {
        cancelToken.cancel();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStore, unixStartDate, unixStoreGoalsStartDate]
  );

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return (
      <ApiError
        className={styles.apiError}
        text="We are experiencing an internal server problem, please refresh the page or select another week"
      />
    );
  }

  return (
    <>
      {!isFranchisee && (
        <>
          <StoreGoalCard
            icon={<FontAwesomeIcon icon={faTruck} />}
            title="Deliveries (Daily)"
            goal={goals?.Deliveries}
            startDate={startDate}
            onSave={handleSave}
          />
          <StoreGoalCard
            icon={<FontAwesomeIcon icon={faTruck} />}
            title="Deliveries (Weekly)"
            goal={goals?.DeliveriesCoreStoreWeekly}
            startDate={startDate}
            onSave={handleSave}
          />
        </>
      )}
      {isFranchisee && (
        <>
          <StoreGoalCard
            icon={<FontAwesomeIcon icon={faTruck} />}
            title="Deliveries"
            goal={goals?.Deliveries}
            startDate={startDate}
            onSave={handleSave}
          />
          <StoreGoalCard
            icon={<FontAwesomeIcon icon={faFileContract} />}
            title="BOR"
            goal={goals?.AOR}
            startDate={startDate}
            onSave={handleSave}
          />
          <StoreGoalCard
            icon={<FontAwesomeIcon icon={faUser} />}
            title="Customer Gain"
            goal={goals?.CustomerGain}
            startDate={startDate}
            onSave={handleSave}
          />
        </>
      )}
    </>
  );
};

export const StoreGoals = (props: StoreGoalsProps) => {
  const { startDate, ...rest } = props;
  return (
    <div className={styles.storeGoals} data-testid={storeGoalsTestId} {...rest}>
      <GoalsList startDate={startDate} />
    </div>
  );
};
