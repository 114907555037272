import clsx from 'clsx';
import React, { HTMLProps } from 'react';

import styles from './BlockTimeEventCard.module.scss';

import {
  BaseEvent,
  EventStatus
} from '../../../../domain/Calendar/CalendarEvent';
import { EventCardLayout } from '../EventCardLayout/EventCardLayout';

const cardColors: Partial<Record<EventStatus, string>> = {
  [EventStatus.ToDo]: styles.todo,
  [EventStatus.Completed]: styles.completed,
  [EventStatus.Canceled]: styles.canceled,
  [EventStatus.Deleted]: styles.deleted
};

interface Props extends HTMLProps<HTMLDivElement> {
  event: BaseEvent;
}

export const BlockTimeEventCard = (props: Props) => {
  const { event, className, ...rest } = props;

  return (
    <EventCardLayout
      className={clsx(
        className,
        styles.blockTimeEvent,
        event && cardColors[event.status]
      )}
      {...rest}
    >
      <div className={styles.title}>Blocked Timeslot</div>
      <div className={styles.description}>{event.instruction}</div>
    </EventCardLayout>
  );
};
