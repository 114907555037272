import React, { HTMLProps, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarTimes,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { Button, Tabs } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ReactComponent as StoreEventIcon } from '../../EventCard/StoreEventCard/StoreEventIcon.svg';

import styles from './StoreEventDetails.module.scss';

import {
  cancelled,
  EventStatusActionType,
  formatTimeslot,
  toDo,
  StoreEvent
} from '../../../../domain/Calendar/CalendarEvent';
import { ParentType } from '../../../../domain/Note/Note';
import { Status } from '../../../common/EventStatus/EventStatus';
import { Notes } from '../../../common/Notes/Notes';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';
import { HistoryLog } from '../../../common/HistoryLog/HistoryLog';

export const storeEventDetailsTestId = 'storeEventDetailsTestId';
export const eventStatusTestId = 'eventStatusTestId';
export const coWorkersRequiredTestId = 'coWorkersRequiredTestId';
export const descriptionTestId = 'descriptionTestId';
export const eventDateTestId = 'eventDateTestId';

export interface StoreEventDetailsProps extends HTMLProps<HTMLDivElement> {
  event: StoreEvent;
  printMode?: boolean;
  onStatusChange: (eventStatusActionType: EventStatusActionType) => void;
}

export const StoreEventDetails = (props: StoreEventDetailsProps) => {
  const { event, onStatusChange, printMode, ...rest } = props;
  const [activeTab, setActiveTab] = useState<number>(0);

  const {
    canSubmit,
    canCancel,
    isSupportUser,
    isUpdateEventForbidden
  } = useUserPermissions();

  const canReopen = canCancel;

  const { eventDate } = event;

  const canChangeStatus =
    canSubmit && !printMode && !isSupportUser && !isUpdateEventForbidden;

  const handleTabsChange = (event: any, tabIndex: number) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div
        className={styles.eventDetails}
        data-testid={storeEventDetailsTestId}
        {...rest}
      >
        <div className={styles.name}>
          <StoreEventIcon className={styles.eventIcon} />
          {event?.title}
        </div>
        <div className={styles.row} data-testid={eventDateTestId}>
          <span className={styles.eventDate}>{`${format(
            new Date(eventDate),
            'eeee, MMMM dd'
          )} | ${formatTimeslot(event)}`}</span>
        </div>
        <div className={styles.eventStatus} data-testid={eventStatusTestId}>
          <div className={styles.statusField}>
            <span className={styles.label}>Status:</span>
            <Status className={styles.status} status={event.status} />
          </div>
          {canChangeStatus && (
            <>
              {cancelled(event.status) && canReopen && (
                <Button
                  color="primary"
                  className={styles.statusButton}
                  size="small"
                  onClick={() =>
                    onStatusChange &&
                    onStatusChange(EventStatusActionType.Reopen)
                  }
                >
                  Reopen Event
                </Button>
              )}

              {toDo(event.status) && (
                <>
                  {canCancel && (
                    <Button
                      color="primary"
                      icon={<FontAwesomeIcon icon={faCalendarTimes} />}
                      className={clsx(styles.statusButton, styles.cancelEvent)}
                      variant="link"
                      size="small"
                      onClick={() =>
                        onStatusChange(EventStatusActionType.Cancel)
                      }
                    >
                      Cancel Event
                    </Button>
                  )}
                  <Button
                    color="primary"
                    icon={<FontAwesomeIcon icon={faCheckCircle} />}
                    className={styles.statusButton}
                    size="small"
                    onClick={() =>
                      onStatusChange(EventStatusActionType.Complete)
                    }
                  >
                    Mark as Completed
                  </Button>
                </>
              )}
            </>
          )}
        </div>

        {event?.instruction && (
          <div className={clsx(styles.row)} data-testid={descriptionTestId}>
            <span className={styles.label}>Description:</span>
            <span>{event.instruction}</span>
          </div>
        )}

        <div
          className={clsx(styles.row, styles.coworkersRequired)}
          data-testid={coWorkersRequiredTestId}
        >
          <span className={styles.label}>Coworkers required:</span>
          <span>{event.requiredCoworkers}</span>
        </div>
      </div>
      <Tabs
        value={activeTab}
        classes={{ tabs: styles.tabs }}
        onChange={handleTabsChange}
      >
        <div title="Notes" className={styles.tabTitle}>
          <Notes
            parentId={event.eventId}
            parentType={ParentType.Event}
            readonly={printMode || isUpdateEventForbidden}
          />
        </div>
        <div title="History Log" className={styles.tabTitle}>
          <HistoryLog parentId={event.eventId} parentType={ParentType.Event} />
        </div>
      </Tabs>
    </>
  );
};
