import React from 'react';

import styles from './GoalCardAction.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';

export enum ActionType {
  Add = 'Add',
  Edit = 'Edit'
}

interface GoalCardActionProps {
  onClick: () => void;
  type: ActionType.Add | ActionType.Edit;
}

export const GoalCardAction = ({ type, onClick }: GoalCardActionProps) => {
  return (
    <div
      data-testid={`goalCardAction${type}`}
      className={styles.container}
      onClick={onClick}
    >
      {type === ActionType.Add && (
        <>
          <FontAwesomeIcon icon={faPlus} className={styles.plus} />
          Add Goal
        </>
      )}
      {type === ActionType.Edit && <FontAwesomeIcon icon={faPen} />}
    </div>
  );
};
