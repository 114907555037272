import React, { FC, useState } from 'react';
import { Button } from '@rentacenter/racstrap';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './EditBlockTimeEventForm.module.scss';

import { BlockTimeEvent } from '../../../../domain/Calendar/CalendarEvent';
import { noOp } from '../../../../utils/helper-functions';

import { DescriptionField } from '../../../common/DescriptionField/DescriptionField';
import { TimeSlotField } from '../../../common/TimeSlotField/TimeSlotField';
import { TimeSlotValue } from '../../TimeSlotSelector/TimeSlotSelector';

export const editBlockTimeEventTestId = 'editBlockTimeEventTestId';
export const formId = 'editBlockTimeEventId';

export interface EditBlockTimeEventFormData {
  readonly requiredCoworkers: string;
  readonly description: string;
  readonly timeSlot: TimeSlotValue;
}

enum FormFields {
  Description = 'description',
  TimeSlot = 'timeSlot'
}

export interface EditBlockTimeEvenFormProps {
  readonly pending: boolean;
  readonly event: BlockTimeEvent;
  readonly onCancel?: () => void;
  readonly onSubmit: (formData: EditBlockTimeEventFormData) => void;
}

export const EditBlockTimeEventForm: FC<EditBlockTimeEvenFormProps> = ({
  pending,
  event,
  onCancel = noOp,
  onSubmit
}) => {
  const [hasError, setHasError] = useState(false);

  const methods = useForm<EditBlockTimeEventFormData>({
    mode: 'onChange',
    defaultValues: {
      description: event.instruction,
      requiredCoworkers: event.requiredCoworkers,
      timeSlot: {
        date: new Date(event.eventDate),
        timeSlotId: event?.timeSlot?.timeSlotId
      }
    }
  });

  const { isDirty } = methods.formState;

  const handleTimeSlotError = () => setHasError(true);

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <FontAwesomeIcon icon={faCalendarTimes} />
        Blocked Timeslot
      </div>
      <FormProvider {...methods}>
        <form
          id={formId}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.container}
        >
          <div className={styles.row}>
            <DescriptionField
              fieldName={FormFields.Description}
              disabled={pending}
            />
          </div>
          <div className={styles.row}>
            <TimeSlotField
              fieldName={FormFields.TimeSlot}
              editMode
              onError={handleTimeSlotError}
            />
          </div>
        </form>
        <div className={clsx(styles.buttons, styles.editMode)}>
          <Button
            data-testid="cancelEventEdit"
            disabled={pending}
            size="large"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-testid="submitEventEdit"
            disabled={!isDirty || pending || hasError}
            size="large"
            type="submit"
            form={formId}
            {...(pending && {
              icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            })}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};
