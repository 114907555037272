import { Card, CardProps } from '@rentacenter/racstrap';
import React, { ReactNode, useContext, useState } from 'react';
import { isThisWeek, isFuture } from 'date-fns';

import styles from './StoreGoalCard.module.scss';

import { StoreGoalView } from './StoreGoalView/StoreGoalView';
import { StoreGoalForm } from './StoreGoalForm/StoreGoalForm';
import { StoreGoal, StoreGoalResponse } from '../../../../domain/Calendar/Goal';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';
import {
  ActionType,
  GoalCardAction
} from '../../common/GoalCardAction/GoalCardAction';
import { StoreContext } from '../../../../context/store/StoreProvider';

export const storeGoalCardTestId = 'storeGoalCardTestId';

export interface StoreGoalCardProps extends CardProps {
  icon: ReactNode;
  title: string;
  startDate: Date;
  goal?: StoreGoal;
  onSave: (goal: StoreGoalResponse) => void;
}

export const StoreGoalCard = (props: StoreGoalCardProps) => {
  const { title, icon, goal, startDate, onSave, ...rest } = props;
  const [showForm, setShowForm] = useState(false);
  const { canCreateGoal, canEditGoal } = useUserPermissions();
  const { isFranchisee } = useContext(StoreContext);

  const hasMetricGoal = goal?.metricGoal !== 0;

  if (!goal) {
    return <></>;
  }

  const showAddIcon =
    !showForm &&
    canCreateGoal &&
    (isThisWeek(startDate) || isFuture(startDate)) &&
    isFranchisee &&
    !hasMetricGoal;
  const showEditIcon =
    !showForm && canEditGoal && hasMetricGoal && isFranchisee;

  return (
    <Card
      className={styles.storeGoalCard}
      data-testid={storeGoalCardTestId}
      {...rest}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {icon} {title}
        </div>
        <div>
          {showAddIcon && (
            <GoalCardAction
              type={ActionType.Add}
              onClick={() => setShowForm(true)}
            />
          )}
          {showEditIcon && (
            <GoalCardAction
              type={ActionType.Edit}
              onClick={() => setShowForm(true)}
            />
          )}
        </div>
      </div>

      {showForm ? (
        <StoreGoalForm
          goal={goal}
          onCancel={() => setShowForm(false)}
          startDate={startDate}
          onSave={onSave}
        />
      ) : (
        <StoreGoalView
          value={goal?.metricCount}
          max={isFranchisee ? goal?.metricGoal : goal?.targetGoal}
        />
      )}
    </Card>
  );
};
