import { CalendarEvent, StoreEvent } from './CalendarEvent';

export enum SearchCriteria {
  Name = 'ownerName',
  Description = 'description',
  Title = 'title'
}

export type SearchParams = Partial<Record<SearchCriteria, string>>;
export type SearchResult = CalendarEvent | StoreEvent;
