import React, { ReactNode, useState, createContext } from 'react';
import { ToastType, useToastActionsContext } from '@rentacenter/racstrap';
import { CancelToken } from 'axios';
import { isSameDay } from 'date-fns';

import * as api from '../../api/tasks';
import { Task, TaskStatus } from '../../domain/Task/Task';

export interface TasksState {
  tasks: MappedTasks;
  hasApiError: boolean;
}

export interface TasksDispatchState {
  fetchTasks: (
    store: string,
    date: string,
    cancelToken: CancelToken
  ) => Promise<void>;
  createTask: (store: string, task: Task) => Promise<Task>;
  updateTask: (task: Task, updatedTask: Task) => Promise<Task>;
  deleteTask: (task: Task) => Promise<void>;
  changeTaskStatus: (task: Task, taskStatus: TaskStatus) => Promise<void>;
}

export const TasksStateContext = createContext<TasksState>({} as TasksState);
export const TasksDispatchContext = createContext<TasksDispatchState>(
  {} as TasksDispatchState
);

type MappedTasks = Record<TaskStatus, Task[]>;

export const TasksProvider = (props: { children: ReactNode }) => {
  const [tasks, setTasks] = useState<MappedTasks>({
    [TaskStatus.ToDo]: [] as Task[],
    [TaskStatus.Cancelled]: [] as Task[],
    [TaskStatus.Completed]: [] as Task[]
  });
  const [hasApiError, setHasApiError] = useState<boolean>(false);

  const { showToast } = useToastActionsContext();

  const showError = (actionType: string) => {
    showToast(
      ToastType.Error,
      `Something went wrong when trying to ${actionType} your task, please try again!`
    );
  };

  const fetchTasks = async (
    store: string,
    date: string,
    cancelToken: CancelToken
  ) => {
    setHasApiError(false);
    return api
      .getTasksForDay(store, date, cancelToken)
      .then(response => {
        const mappedTasks: MappedTasks = {} as MappedTasks;
        Object.values(TaskStatus).forEach(taskStatus => {
          mappedTasks[taskStatus] = [];
        });
        response?.forEach((task: Task) => {
          mappedTasks[task.taskStatus]?.push(task);
        });
        setTasks(mappedTasks);
      })
      .catch(err => {
        if (!err.__CANCEL__) {
          setHasApiError(true);
        }
      });
  };

  const createTask = (selectedStore: string, task: Task) => {
    return api
      .createTask(selectedStore, task)
      .then(response => {
        showToast(
          ToastType.Success,
          'Your Task has been successfully created!'
        );
        return response;
      })
      .catch(() => showError('create'));
  };

  const updateTask = (originalTask: Task, updatedTask: Task) => {
    return api
      .updateTask(originalTask.id, updatedTask)
      .then(response => {
        const updatedTasks = {
          ...tasks,
          [originalTask.taskStatus]: tasks[originalTask.taskStatus].filter(
            t => t.id !== originalTask.id
          )
        };

        const result = {
          ...updatedTasks,
          [response.taskStatus]: [
            ...updatedTasks[response.taskStatus as TaskStatus],
            { ...originalTask, ...response }
          ]
        };

        setTasks(
          isSameDay(
            Date.parse(updatedTask.dueDate),
            Date.parse(originalTask.dueDate)
          )
            ? result
            : updatedTasks
        );

        showToast(
          ToastType.Success,
          'Your Task has been successfully updated!'
        );

        return response;
      })
      .catch(() => showError('update'));
  };

  const deleteTask = (task: Task) => {
    return api
      .deleteTask(task.id)
      .then(() => {
        showToast(
          ToastType.Success,
          'Your Task has been successfully deleted!'
        );
        const updatedTasks = {
          ...tasks,
          [task.taskStatus]: tasks[task.taskStatus].filter(
            t => t.id !== task.id
          )
        };
        setTasks(updatedTasks);
      })
      .catch(() => showError('delete'));
  };

  const changeTaskStatus = (task: Task, taskStatus: TaskStatus) => {
    const payload = {
      taskStatus: taskStatus
    };

    return api
      .updateTask(task.id, payload)
      .then(() => {
        const updatedTasks = {
          ...tasks,
          [task.taskStatus]: tasks[task.taskStatus].filter(
            t => t.id !== task.id
          ),
          [taskStatus]: [...tasks[taskStatus], { ...task, taskStatus }]
        };
        setTasks(updatedTasks);
      })
      .catch(() => showError('update'));
  };

  return (
    <TasksStateContext.Provider value={{ tasks, hasApiError }}>
      <TasksDispatchContext.Provider
        value={{
          fetchTasks,
          createTask,
          deleteTask,
          updateTask,
          changeTaskStatus
        }}
      >
        {props.children}
      </TasksDispatchContext.Provider>
    </TasksStateContext.Provider>
  );
};
