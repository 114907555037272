import React, { useContext, useEffect, useState } from 'react';
import { Button, Table } from '@rentacenter/racstrap';
import { format, parseISO } from 'date-fns';

import styles from './SelectAgreement.module.scss';

import { Footer } from '../../../layout/footer/Footer';
import { nextStepTestId } from '../SelectCustomer/SelectCustomer';
import { SetStepProp } from '../NewEvent';
import { BackButton } from '../BackButton';
import { CancelButton } from '../CancelButton';
import {
  useNewEventDispatch,
  useNewEventState
} from '../../../../context/events/NewEventProvider';
import { getAgreements } from '../../../../api/customer';
import { SelectedCustomer } from '../SelectedCustomer/SelectedCustomer';
import { SelectableRow } from '../SelectableRow/SelectableRow';
import { Loading } from '../Loading/Loading';
import { Agreement } from '../../../../domain/Customer/Agreement';
import { NoItems } from '../NoItems/NoItems';
import { StoreContext } from '../../../../context/store/StoreProvider';
import { ApiError } from '../../../common/ApiError/ApiError';
import { Divider } from '../Divider';
import { CancelCreate } from '../CancelCreate';

export const selectAgreementTestId = 'selectAgreementTestId';
export const agreementsListTestId = 'agreementsListTestId';

export const SelectAgreement = (props: SetStepProp) => {
  const { setStep, ...rest } = props;
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [hasApiError, setHasApiError] = useState(false);
  const [cancelCreate, setCancelCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    selectCustomer: { selectedCustomer },
    selectedAgreement
  } = useNewEventState();

  const { setSelectedAgreement } = useNewEventDispatch();
  const { selectedStore } = useContext(StoreContext);

  type ListStatus = 'initial' | 'loading' | 'apiError' | 'empty' | 'success';

  const getStatus = (): ListStatus => {
    if (loading) return 'loading';
    if (hasApiError) return 'apiError';

    if (agreements) {
      return agreements.length > 0 ? 'success' : 'empty';
    } else {
      return 'initial';
    }
  };

  useEffect(
    function fetchAgreements() {
      if (!selectedCustomer || !selectedStore) {
        return;
      }

      setLoading(true);
      setHasApiError(false);
      getAgreements(selectedCustomer?.partyId, selectedStore)
        .then(response => {
          setAgreements(response.getAgreementInfo || []);
        })
        .catch(() => {
          setSelectedAgreement();
          setAgreements([]);
          setHasApiError(true);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomer]
  );

  return (
    <div data-testid={selectAgreementTestId} {...rest}>
      <SelectedCustomer />

      {
        {
          initial: null,
          loading: <Loading className={styles.loading} />,
          apiError: <ApiError className={styles.apiError} />,
          empty: <NoItems>No Search Results Found</NoItems>,
          success: (
            <Table data-testid={agreementsListTestId}>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Agreement Number</th>
                  <th scope="col">Date Created</th>
                  <th scope="col">Status</th>
                  <th scope="col">Description</th>
                  <th scope="col">Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {agreements
                  .filter(
                    agr =>
                      agr.agreementOpenDate &&
                      (agr.agreementOpenDate !== null ||
                        agr.agreementOpenDate !== undefined)
                  )
                  .map((agreement: Agreement, index) => (
                    <SelectableRow
                      key={index}
                      selected={
                        selectedAgreement?.agreementId === agreement.agreementId
                      }
                      onClick={() => {
                        if (
                          selectedAgreement?.agreementId ===
                          agreement.agreementId
                        ) {
                          setSelectedAgreement();
                        } else {
                          setSelectedAgreement(agreement);
                        }
                      }}
                    >
                      <td>{agreement.agreementNumber}</td>
                      <td>
                        {agreement?.agreementOpenDate
                          ? format(
                              parseISO(agreement?.agreementOpenDate),
                              'MM/dd/yyyy'
                            )
                          : 'N/A'}
                      </td>
                      <td>{agreement.agreementStatus.description}</td>
                      <td>{agreement.description || 'N/A'}</td>
                      <td>${agreement.agreementTotalCost}</td>
                    </SelectableRow>
                  ))}
              </tbody>
            </Table>
          )
        }[getStatus()]
      }

      <CancelCreate show={cancelCreate} setShow={setCancelCreate} />

      <Footer>
        <div>
          <BackButton onClick={() => setStep(0)} />
          <Divider />
          <CancelButton onClick={() => setCancelCreate(true)} />
        </div>
        <div>
          <Button
            data-testid={nextStepTestId}
            color="primary"
            disabled={!selectedAgreement}
            onClick={() => setStep(2)}
          >
            Next Step
          </Button>
        </div>
      </Footer>
    </div>
  );
};
