import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Button, Input } from '@rentacenter/racstrap';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';

import styles from './AddNote.module.scss';

import { addNote, replyNote } from '../../../../api/notes';
import { Note, NotePayload, ParentType } from '../../../../domain/Note/Note';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';

export const addNoteFormId = 'addNoteFormId';

interface AddNoteForm {
  readonly content: string;
}

interface AddNoteProps {
  parentId: string;
  parentType: ParentType;
  onAdd: () => void;
  note?: Note;
  inputRef?: MutableRefObject<ReactElement>;
}

enum AddNoteFields {
  content = 'content'
}

export const AddNote = ({
  parentId,
  parentType,
  onAdd,
  note,
  inputRef
}: AddNoteProps) => {
  const [pending, setPending] = useState<boolean>(false);
  const [apiError, setApiError] = useState('');
  const { isUpdateEventForbidden } = useUserPermissions();

  const { control, handleSubmit, errors, reset, setValue, getValues } = useForm<
    AddNoteForm
  >({
    mode: 'onSubmit',
    defaultValues: {
      content: ''
    }
  });

  useEffect(() => {
    if (note?.id && getValues('content') === '') {
      setValue(
        'content',
        `@${note?.userInfo.firstName} ${note?.userInfo.lastName} - `
      );
    }
  }, [getValues, note, setValue]);

  const onSubmit = (data: AddNoteForm) => {
    setPending(true);
    setApiError('');
    const newNote: NotePayload = {
      parentId,
      parentType,
      content: data.content
    };

    const payload = { parentId, parentType, ...data };

    if (!note || !data.content.startsWith('@')) {
      addNote(newNote)
        .then(() => {
          reset();
          onAdd();
        })
        .catch(() => setApiError('Something went wrong, try later!'))
        .finally(() => setPending(false));
    } else if (note?.id && data.content.startsWith('@')) {
      replyNote(payload, note?.id)
        .then(() => {
          reset();
          onAdd();
        })
        .catch(() => setApiError('Something went wrong, try later!'))
        .finally(() => setPending(false));
    }
  };

  const isAddNoteDisabled = pending || isUpdateEventForbidden;
  return (
    <form
      id={addNoteFormId}
      data-testid={addNoteFormId}
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.content}>
        <Controller
          control={control}
          name={AddNoteFields.content}
          rules={{
            required: {
              value: true,
              message: "Note can't be empty"
            }
          }}
          render={({ onChange, name, value }, { invalid }) => (
            <Input
              name={name}
              value={value}
              inputRef={(e: any) => {
                Object(inputRef).current = e;
              }}
              label="Write a New Note"
              placeholder="Enter Text Here"
              onChange={onChange}
              invalid={invalid || !!apiError}
              errorMessage={errors?.content?.message || apiError}
              disabled={isAddNoteDisabled}
            />
          )}
        />
      </div>
      <div className={clsx(styles.add)}>
        <Button
          data-testid="addNote"
          disabled={isAddNoteDisabled}
          color="secondary"
          size="large"
          type="submit"
          form={addNoteFormId}
        >
          Add
        </Button>
      </div>
    </form>
  );
};
