import {
  faCalendarTimes,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Tabs,
  ToastType,
  useToastActionsContext
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import styles from './TaskDetails.module.scss';

import { getTask } from '../../../api/tasks';
import { TasksDispatchContext } from '../../../context/tasks/TasksProvider';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';
import { ParentType } from '../../../domain/Note/Note';
import {
  Task,
  TaskStatus,
  completed,
  cancelled,
  TaskStatusActionType
} from '../../../domain/Task/Task';
import { Category } from '../../common/Category/Category';
import { Notes } from '../../common/Notes/Notes';
import { HistoryLog } from '../../common/HistoryLog/HistoryLog';
import { Priority } from '../../common/Priority/Priority';
import { Status } from '../../common/Status/Status';
import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';

export const taskViewDetailsTestId = 'taskViewDetailsTestId';

export interface TaskDetailsProps {
  task?: Task;
}

// eslint-disable-next-line
export const TaskDetails = (props: TaskDetailsProps) => {
  const { task: propsTask } = props;
  const { changeTaskStatus } = useContext(TasksDispatchContext);
  const { showToast } = useToastActionsContext();

  const { canSubmit, isUpdateTaskForbidden } = useUserPermissions();

  const [loading, setLoading] = useState<boolean>(false);
  const [
    viewStatusChangeConfirmModal,
    setViewStatusChangeConfirmModal
  ] = useState<boolean>(false);

  const [printMode, setPrintMode] = useState(false);
  const [task, setTask] = useState(propsTask);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [taskStatusActionType, setTaskStatusActionType] = useState<
    TaskStatusActionType
  >(TaskStatusActionType.Reopen);

  const match = useRouteMatch();

  useEffect(
    function fetchEventIfMissing() {
      if (!task) {
        setPrintMode(true);
        const { taskId } = match.params as any;
        getTask(taskId).then(response => {
          setTask(response);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!task) {
    return <div>Loading...</div>;
  }

  const canMarkAsComplete =
    !printMode &&
    canSubmit &&
    !completed(task) &&
    !cancelled(task) &&
    !isUpdateTaskForbidden;
  const canBeCancelled =
    !printMode &&
    canSubmit &&
    !cancelled(task) &&
    !completed(task) &&
    !isUpdateTaskForbidden;
  const canBeReopened =
    !printMode &&
    (cancelled(task) || completed(task)) &&
    !isUpdateTaskForbidden;

  const handleChangeTaskStatus = (action: TaskStatusActionType) => {
    setViewStatusChangeConfirmModal(true);
    setTaskStatusActionType(action);
  };

  const onStatusChange = (task: Task, taskStatus: TaskStatus) => {
    setLoading(true);
    switch (taskStatusActionType) {
      case TaskStatusActionType.Cancel:
        taskStatus = TaskStatus.Cancelled;
        break;
      case TaskStatusActionType.Reopen:
        taskStatus = TaskStatus.ToDo;
        break;
      case TaskStatusActionType.Complete:
        taskStatus = TaskStatus.Completed;
        break;
    }

    const message = taskStatus === TaskStatus.ToDo ? 'Reopened' : taskStatus;

    changeTaskStatus(task, taskStatus)
      .then(() => {
        showToast(
          ToastType.Success,
          `Your Task has been successfully ${message}`
        );
      })
      .finally(() => {
        setLoading(false);
        setViewStatusChangeConfirmModal(false);
      });
  };

  const date = new Date(task.dueDate);
  const getFormattedDate = () => {
    return format(date, 'MM/dd/yy');
  };

  const handleTabsChange = (event: any, tabIndex: number) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className={styles.taskDetail} data-testid={taskViewDetailsTestId}>
        <div data-testid="title" className={styles.name}>
          {task.title}
        </div>
        <div className={styles.taskStatus}>
          <div data-testid="status" className={styles.statusField}>
            <span className={styles.label}>Status:</span>
            <Status className={styles.status} status={task.taskStatus} />
          </div>

          {canBeCancelled && (
            <Button
              color="primary"
              icon={<FontAwesomeIcon icon={faCalendarTimes} />}
              className={clsx(styles.statusButton, styles.cancelTask)}
              variant="link"
              size="small"
              onClick={() =>
                handleChangeTaskStatus(TaskStatusActionType.Cancel)
              }
            >
              Cancel Task
            </Button>
          )}
          {canMarkAsComplete && canBeCancelled && (
            <Button
              color="primary"
              icon={<FontAwesomeIcon icon={faCheckCircle} />}
              className={styles.statusButton}
              disabled={loading}
              onClick={() =>
                handleChangeTaskStatus(TaskStatusActionType.Complete)
              }
            >
              Mark as Completed
            </Button>
          )}
          {canBeReopened && (
            <Button
              color="primary"
              className={styles.statusButton}
              size="small"
              onClick={() =>
                handleChangeTaskStatus(TaskStatusActionType.Reopen)
              }
            >
              Reopen Task
            </Button>
          )}
        </div>
        {task.description && (
          <div
            data-testid="description"
            className={clsx(styles.row, styles.description)}
          >
            <span className={styles.label}>Description:</span>
            <span>{task.description}</span>
          </div>
        )}
        <div data-testid="assignee" className={styles.row}>
          <span className={styles.label}>Assignee:</span>
          <span>{task.assignee.name}</span>
        </div>
        <div data-testid="category" className={styles.row}>
          <span className={styles.label}>Category:</span>
          <Category className={styles.category} category={task.category} />
        </div>
        <div data-testid="priority" className={styles.row}>
          <span className={styles.label}>Priority:</span>
          <Priority priority={task.priority} />
        </div>
        <div className={styles.row}>
          <div data-testid="dueDate" className={styles.dueDate}>
            <span className={styles.label}>Due Date:</span>
            <span>{getFormattedDate()}</span>
          </div>
          <div data-testid="dueTime" className={styles.dueTime}>
            <span className={styles.label}>Due Time:</span>
            <span>{format(date, 'p')}</span>
          </div>
        </div>
      </div>
      <ModalConfirmation
        open={viewStatusChangeConfirmModal}
        title="Please confirm"
        description={
          <>
            Are you sure you want to <b>{taskStatusActionType}</b> this task?
          </>
        }
        cancelButtonText="No"
        confirmButtonText="Confirm"
        confirmButtonProps={{ color: 'primary' }}
        loading={loading}
        onCancel={() => setViewStatusChangeConfirmModal(false)}
        onConfirm={() => onStatusChange(task, TaskStatus.Cancelled)}
      />
      <Tabs
        value={activeTab}
        classes={{ tabs: styles.tabs }}
        onChange={handleTabsChange}
      >
        <div title="Notes" className={styles.tabTitle}>
          <Notes
            parentId={task.id}
            parentType={ParentType.Task}
            readonly={printMode || isUpdateTaskForbidden}
          />
        </div>
        <div title="History Log" className={styles.tabTitle}>
          <HistoryLog parentId={task.id} parentType={ParentType.Task} />
        </div>
      </Tabs>
    </>
  );
};
