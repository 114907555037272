import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { format, sub } from 'date-fns';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Checkbox,
  KeyboardDatePicker,
  Input
} from '@rentacenter/racstrap';

import styles from './SelectCustomer.module.scss';

import { StoreContext } from '../../../../context/store/StoreProvider';
import {
  defaultSearchFormData,
  useNewEventDispatch,
  useNewEventState
} from '../../../../context/events/NewEventProvider';
import { getCustomers } from '../../../../api/customer';
import { Footer } from '../../../layout/footer/Footer';
import { SetStepProp } from '../NewEvent';
import { CancelButton } from '../CancelButton';
import { CustomerSearchParams } from '../../../../domain/Customer/Customer';
import { CustomerList } from './CustomerList/CustomerList';

export interface SearchFormData {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  activeAgreements: boolean;
}

const commonValidations = {
  required: {
    value: true,
    message: 'This field is required'
  },
  minLength: {
    value: 2,
    message: 'Need at least 2 characters'
  },
  validate: (value: string) =>
    value.trim() !== '' ? true : 'This field is required'
};

const searchFormTesstId = 'selectCustomerSearchForm';
export const nextStepTestId = 'nextStep';

export function SelectCustomer({ setStep }: SetStepProp) {
  const { selectedStore } = useContext(StoreContext);
  const {
    setSearchFormData,
    setCustomers,
    setSelectedAgreement,
    setSelectedCustomer
  } = useNewEventDispatch();
  const {
    selectCustomer: { searchFormData, selectedCustomer }
  } = useNewEventState();

  const [loading, setLoading] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  const formMethods = useForm<SearchFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { ...searchFormData, activeAgreements: true }
  });
  const {
    watch,
    control,
    errors,
    handleSubmit,
    clearErrors,
    reset,
    formState
  } = formMethods;
  const { isDirty } = formState;

  const formData = watch();

  useEffect(
    function saveFormData() {
      if (!isEqual(searchFormData, formData)) {
        setSearchFormData(formData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  useEffect(
    function resetForm() {
      if (isDirty && isEqual(searchFormData, defaultSearchFormData)) {
        reset(defaultSearchFormData);
        setHasApiError(false);
        // TODO: also cancel the request and set loading to false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFormData]
  );

  useEffect(
    function resetAgreement() {
      setSelectedAgreement();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomer]
  );

  const onSubmit = (data: SearchFormData) => {
    if (!selectedStore) return;

    const {
      activeAgreements,
      dateOfBirth,
      firstName,
      lastName,
      ...rest
    } = data;
    const params: CustomerSearchParams = {
      dateOfBirth: dateOfBirth ? format(dateOfBirth, 'yyyy-MM-dd') : undefined,
      activeAgreements: activeAgreements.toString(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      ...rest
    };

    setLoading(true);
    setHasApiError(false);
    setSelectedCustomer();
    getCustomers(selectedStore, params)
      .then(response => {
        setCustomers(response);
      })
      .catch(() => {
        setHasApiError(true);
        setCustomers();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <form
        data-testid={searchFormTesstId}
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            control={control}
            name="firstName"
            rules={commonValidations}
            render={({ ref, onChange, ...rest }, { invalid }) => (
              <Input
                label="First Name"
                required={true}
                placeholder="Insert First Name"
                size="large"
                onChange={e => {
                  clearErrors('firstName');
                  onChange(e);
                }}
                invalid={invalid}
                errorMessage={errors?.firstName?.message}
                {...rest}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="lastName"
            rules={commonValidations}
            render={({ ref, onChange, ...rest }, { invalid }) => (
              <Input
                label="Last Name"
                required={true}
                placeholder="Insert Last Name"
                size="large"
                onChange={e => {
                  clearErrors('lastName');
                  onChange(e);
                }}
                invalid={invalid}
                errorMessage={errors?.lastName?.message}
                {...rest}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ onBlur, ref, ...rest }) => (
              <KeyboardDatePicker
                className={styles.datePicker}
                label="Date of Birth"
                placeholder="MM/dd/yyyy"
                datePickerProps={{
                  disableFuture: true,
                  maxDate: sub(new Date(), { years: 18 })
                }}
                clearIcon
                {...rest}
              />
            )}
          />
        </div>
        <div className={styles.withoutLabel}>
          <Controller
            name="activeAgreements"
            control={control}
            render={({ name, onChange, value }) => (
              <Checkbox
                name={name}
                labelText="Active Agreements"
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
        </div>
        <div className={styles.withoutLabel}>
          <Button
            type="submit"
            color="secondary"
            icon={<FontAwesomeIcon icon={faSearch} />}
            disabled={loading}
          >
            Search
          </Button>
        </div>
      </form>

      <CustomerList loading={loading} hasApiError={hasApiError} />

      <Footer>
        <CancelButton />

        <Button
          data-testid={nextStepTestId}
          color="primary"
          disabled={!selectedCustomer}
          onClick={() => setStep(1)}
        >
          Next Step
        </Button>
      </Footer>
    </>
  );
}
