import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, Select } from '@rentacenter/racstrap';
import { format } from 'date-fns';
import { compact } from 'lodash';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './EventForm.module.scss';

import { StoreContext } from '../../../../context/store/StoreProvider';
import { buildOptions } from '../../../../utils/buildOptions';
import { EventsDispatchContext } from '../../../../context/events/EventsProvider';
import {
  CalendarEvent,
  EventStatus
} from '../../../../domain/Calendar/CalendarEvent';
import { EventSource } from '../../../common/EventSource/EventSource';
import { EventType } from '../../../common/EventType/EventType';
import { dateFormat } from '../../../../api/calendar';
import { noOp } from '../../../../utils/helper-functions';
import { eventTypeTestId } from '../CalendarSideModal';
import {
  timeSlotRules,
  TimeSlotSelector
} from '../../TimeSlotSelector/TimeSlotSelector';
import { useEventSource } from '../../useEventSource';

export const createEditEventFormId = 'createEditEventFormId';
export const sourceTestId = 'sourceTestId';

interface EventFormData {
  readonly timeSlot: any;
  readonly requiredCoworkers: string;
  readonly status: EventStatus;
  readonly address: string;
}

enum FormFields {
  timeSlot = 'timeSlot',
  eventDate = 'eventDate',
  requiredCoworkers = 'requiredCoworkers',
  status = 'status',
  address = 'address'
}

interface Props {
  readonly editMode?: boolean;
  readonly onCancel?: () => void;
  readonly onSave?: (event: CalendarEvent) => void;
  readonly event?: CalendarEvent;
  readonly setDisableModalClose?: (shouldDisable: boolean) => void;
}

const coworkersRequiredOptions = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5'
};

const getDefaultValues = (event: CalendarEvent | undefined): EventFormData => {
  const address = event?.customerInformation?.address;

  return event
    ? {
        ...event,
        address: compact([
          address?.addressLine1,
          address?.city,
          address?.state,
          address?.zipCode
        ]).join(', '),
        timeSlot: {
          date: new Date(event.eventDate),
          timeSlotId: event?.timeSlot?.timeSlotId
        }
      }
    : {
        timeSlot: {
          date: null,
          timeSlotId: ''
        },
        status: EventStatus.ToDo,
        requiredCoworkers: '',
        address: ''
      };
};

// eslint-disable-next-line
export const EventForm = (props: Props) => {
  const {
    onCancel: handleClose = noOp,
    editMode,
    onSave = noOp,
    event,
    setDisableModalClose = noOp
  } = props;
  const { selectedStore } = useContext(StoreContext);
  const { updateEvent } = useContext(EventsDispatchContext);

  const [pending, setPending] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const eventSource = useEventSource();

  const defaultValues = getDefaultValues(event);
  const methods = useForm<EventFormData>({ mode: 'onChange', defaultValues });
  const {
    control,
    handleSubmit,
    formState,
    errors,
    clearErrors,
    trigger
  } = methods;
  const { isDirty } = formState;

  const onSubmit = (data: EventFormData) => {
    if (!selectedStore || !editMode || event === undefined) return;

    setPending(true);
    setDisableModalClose(true);

    const formFields = ({
      eventDate: data.timeSlot.date
        ? format(data.timeSlot.date, dateFormat)
        : '',
      type: event?.type,
      source: eventSource,
      requiredCoworkers: data.requiredCoworkers,
      partyId: event.customerInformation?.partyId,
      timeSlot: { timeSlotId: data.timeSlot.timeSlotId }
    } as unknown) as CalendarEvent;

    updateEvent(event, selectedStore, formFields)
      .then((response: any) => {
        handleClose();
        onSave(response);
      })
      .finally(() => {
        setPending(false);
        setDisableModalClose(false);
      });
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleTimeSlotError = () => setHasError(true);

  return (
    <>
      <div className={clsx(styles.content, editMode && styles.editMode)}>
        <form
          id={createEditEventFormId}
          data-testid={createEditEventFormId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.name}>
            <FontAwesomeIcon icon={faUserCircle} />
            {event?.customerInformation.firstName}{' '}
            {event?.customerInformation.lastName}
          </div>
          {event && (
            <>
              <div
                className={styles.nonEditableWrapper}
                data-testid={sourceTestId}
              >
                <span className={styles.label}>Source:</span>
                <EventSource
                  className={styles.eventSourceType}
                  source={event.source}
                />
              </div>
              <div
                className={styles.nonEditableWrapper}
                data-testid={eventTypeTestId}
              >
                <span className={styles.label}>Type:</span>
                <EventType className={styles.eventType} type={event.type} />
              </div>
            </>
          )}
          <div className={styles.filtersRow}>
            <div className={styles.select}>
              <Controller
                control={control}
                name={FormFields.requiredCoworkers}
                rules={{ required: true }}
                render={({ ref, ...rest }) => (
                  <Select
                    options={buildOptions(coworkersRequiredOptions)}
                    required={true}
                    label="Coworkers Required"
                    disabled={pending}
                    placeholder="Coworkers Required"
                    size="large"
                    {...rest}
                  />
                )}
              />
            </div>
          </div>
          <div className={clsx(styles.address)}>
            <div>
              <Controller
                control={control}
                name={FormFields.address}
                rules={{
                  maxLength: {
                    value: 256,
                    message:
                      'The address text is limited to a number of 256 characters.'
                  },
                  required: true
                }}
                render={({ ref, onChange, ...rest }) => (
                  <Input
                    required
                    label="Address"
                    placeholder="Address"
                    size="large"
                    disabled
                    errorMessage={errors?.address?.message}
                    onChange={(e: any) => {
                      clearErrors(FormFields.address);
                      onChange(e);
                    }}
                    {...rest}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.timeSlotRow}>
            <Controller
              control={control}
              name={FormFields.timeSlot}
              required
              rules={timeSlotRules}
              render={({ onChange, value }) => (
                <TimeSlotSelector
                  editMode
                  errorMessage={errors?.timeSlot?.message}
                  value={value}
                  onChange={onChange}
                  onError={handleTimeSlotError}
                />
              )}
            />
          </div>

          <div className={clsx(styles.buttons, editMode && styles.editMode)}>
            <Button
              data-testid="cancelEvent"
              disabled={pending}
              size="large"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="submitEvent"
              disabled={!isDirty || pending || hasError}
              size="large"
              type="submit"
              form={createEditEventFormId}
              {...(pending && {
                icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
              })}
            >
              {editMode ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
