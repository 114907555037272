import React, { HTMLProps, useState } from 'react';
import {
  faSortAmountUp,
  faSortAlphaUp,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './TasksColumn.module.scss';

import {
  Task,
  TaskStatus,
  SortBasis,
  completed
} from '../../../../domain/Task/Task';
import { handleSorting } from '../../../../utils/helper-functions';
import { TaskCard } from './TaskCard/TaskCard';
import { EmptyColumn } from './EmptyColumn/EmptyColumn';
import { TasksColumnSkeleton } from './TasksColumnSkeleton';

export interface TasksColumnProps extends HTMLProps<HTMLDivElement> {
  loading: boolean;
  tasks: Task[];
  status: TaskStatus;
}

const getColumnStyle = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.ToDo:
      return styles.todoColumn;
    case TaskStatus.Completed:
      return styles.completedColumn;
  }
};

export const TasksColumn = ({
  loading,
  tasks,
  status,
  ...rest
}: TasksColumnProps) => {
  const { Priority, Category } = SortBasis;

  const [sortMethod, setSortMethod] = useState(Priority);

  const handleSort = (sortMethod: SortBasis) => {
    setSortMethod(sortMethod);
  };

  const prioritySort = (tasks: Task[]) => {
    const priorityMap = { High: 3, Medium: 2, Low: 1 };

    return tasks.sort((a, b) => {
      const result = priorityMap[b.priority] - priorityMap[a.priority];
      return handleSorting(a, b, result);
    });
  };

  const categorySort = (tasks: Task[]) => {
    return tasks.sort((a, b) => {
      const result = a.category.localeCompare(b.category);
      return handleSorting(a, b, result);
    });
  };

  return (
    <div className={clsx(styles.column, getColumnStyle(status))} {...rest}>
      <div className={styles.columnTitle}>
        {completed(status) && (
          <FontAwesomeIcon className={styles.iconStyle} icon={faCheck} />
        )}
        {status}
        <div className={styles.sorting}>
          <span
            onClick={() => handleSort(Priority)}
            className={clsx(
              styles.sortText,
              sortMethod === Priority && styles.highlighted
            )}
          >
            <FontAwesomeIcon
              data-testid="priority-sort-btn"
              icon={faSortAmountUp}
              className={styles.actionButton}
            />
            Priority
          </span>
          <span
            onClick={() => handleSort(Category)}
            className={clsx(
              styles.sortText,
              sortMethod === Category && styles.highlighted
            )}
          >
            <FontAwesomeIcon
              data-testid="categort-sort-btn"
              icon={faSortAlphaUp}
              className={styles.actionButton}
            />
            Category
          </span>
        </div>
      </div>
      {loading ? (
        <TasksColumnSkeleton />
      ) : tasks?.length > 0 ? (
        <div className={styles.listContainer}>
          {sortMethod === Priority
            ? prioritySort(tasks).map(task => (
                <TaskCard task={task} key={task.id} />
              ))
            : categorySort(tasks).map((task: Task) => (
                <TaskCard task={task} key={task.id} />
              ))}
        </div>
      ) : (
        <EmptyColumn>There are no tasks in {status}</EmptyColumn>
      )}
    </div>
  );
};
