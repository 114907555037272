import React, { HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { format } from 'date-fns';

import styles from './BlockTimeEventDetails.module.scss';

import {
  CalendarEvent,
  EventStatusActionType,
  formatTimeslot
} from '../../../domain/Calendar/CalendarEvent';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';

export const eventDetailsTestId = 'eventDetailsTestId';
export const eventStatusTestId = 'eventStatusTestId';
export const descriptionTestId = 'descriptionTestId';
export const eventDateTestId = 'eventDateTestId';
export const unblockTestId = 'unblockTestId';

export interface EventDetailsProps extends HTMLProps<HTMLDivElement> {
  event?: CalendarEvent;
  onStatusChange?: (eventStatusActionType: EventStatusActionType) => void;
}

// eslint-disable-next-line
export const BlockTimeEventDetails = (props: EventDetailsProps) => {
  const { event, onStatusChange, ...rest } = props;
  const {
    canBlockTimeslot,
    isSupportUser,
    isUpdateEventForbidden
  } = useUserPermissions();

  if (!event) {
    return <div>Loading...</div>;
  }
  let recurrent = false;
  if (event.recurringAppointmentId !== '0') {
    recurrent = true;
  }
  const { eventDate } = event;

  return (
    <>
      <div
        className={styles.eventDetails}
        data-testid={eventDetailsTestId}
        {...rest}
      >
        <div className={styles.name}>
          <FontAwesomeIcon icon={faCalendarTimes} />
          Blocked Timeslot
        </div>
        <div className={styles.row} data-testid={eventDateTestId}>
          <span className={styles.eventDate}>{`${format(
            new Date(eventDate),
            'eeee, MMMM dd'
          )} | ${formatTimeslot(event)}`}</span>
        </div>
        {recurrent && (
          <div>
            {' '}
            <span className={styles.eventDate}>
              {' '}
              Start Date: {event.startDate}
            </span>{' '}
            <br />
            <span className={styles.eventDate}>
              {' '}
              &nbsp;&nbsp;End Date: {event.endDate}{' '}
            </span>
            <br />
            <br />
            Note: By unblocking this event will unblock the entire series of
            events
          </div>
        )}
        <div className={styles.eventStatus} data-testid={eventStatusTestId}>
          {canBlockTimeslot && !isSupportUser && !isUpdateEventForbidden && (
            <Button
              data-testid={unblockTestId}
              color="primary"
              icon={<FontAwesomeIcon icon={faCalendarTimes} />}
              className={clsx(styles.statusButton, styles.cancelEvent)}
              variant="link"
              size="small"
              onClick={() =>
                onStatusChange && onStatusChange(EventStatusActionType.Cancel)
              }
            >
              Unblock
            </Button>
          )}
        </div>
        <div
          className={clsx(styles.row, styles.fieldWithText)}
          data-testid={descriptionTestId}
        >
          <span className={styles.label}>Description:</span>
          <span>{event.instruction}</span>
        </div>
      </div>
    </>
  );
};
