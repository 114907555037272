import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@rentacenter/racstrap';
import clsx from 'clsx';

import styles from '../EventForm/EventFields.module.scss';

import { requiredErrorMessage } from '../constants';
import { buildOptions } from '../../../utils/buildOptions';

interface RequiredCoworkersFieldProps {
  fieldName: string;
  disabled: boolean;
}

export const CoworkersRequired = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5'
};

export const RequiredCoworkersField: FC<RequiredCoworkersFieldProps> = ({
  fieldName,
  disabled
}) => {
  const { control, errors } = useFormContext<any>();
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: requiredErrorMessage
      }}
      render={({ onChange, value, name }) => (
        <Select
          name={name}
          label="Coworkers Required"
          placeholder="Select No."
          options={buildOptions(CoworkersRequired)}
          onChange={onChange}
          required
          className={clsx(styles.selectField, styles.right)}
          value={value}
          size="large"
          errorMessage={errors?.requiredCoworkers?.message}
          disabled={disabled}
        />
      )}
    />
  );
};
