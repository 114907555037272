import { client } from './client';
import {
  Customer,
  CustomerDetailsDto,
  CustomerSearchParams
} from '../domain/Customer/Customer';
import { appConfig } from '../config/app-config';

export const getCustomers = (
  storeId: string,
  filters: CustomerSearchParams
): Promise<Customer[]> =>
  client(
    'customers',
    {
      method: 'GET',
      params: {
        storeNumber: storeId,
        includeAgreements: 'true',
        ...filters
      }
    },
    appConfig.apiUrls.customers
  );

export const getCustomerDetails = (
  customerId?: string,
  oktaId?: string
): Promise<CustomerDetailsDto> =>
  client(
    'customers/details',
    {
      method: 'GET',
      params: {
        customerId,
        oktaId
      }
    },
    appConfig.apiUrls.customers
  );

export const updateCustomer = (partyId: string, payload: any): Promise<any> =>
  client(
    `customers/${partyId}`,
    {
      method: 'POST',
      body: payload
    },
    appConfig.apiUrls.customers
  );

export const getAgreements = async (
  partyId: string,
  storeId: string
): Promise<any> =>
  client(
    'agreements/search',
    {
      method: 'POST',
      body: {
        partyId,
        pageInfo: {
          pageSize: '30',
          startingRow: '0'
        },
        globalSearch: 'true',
        agreementStatus: 'All',
        storeInfo: {
          storeList: [storeId]
        }
      }
    },
    appConfig.apiUrls.customers
  );

export const getItems = async (
  agreementId: string,
  agreementType: string
): Promise<any> =>
  client(
    `agreements/${agreementId}`,
    {
      method: 'GET',
      params: {
        type: agreementType
      }
    },
    appConfig.apiUrls.customers
  );
