import React from 'react';
import { Router } from 'react-router';
import { History } from 'history';
import { StylesProvider } from '@material-ui/core';
import { StyleProvider } from '@rentacenter/racstrap';

import styles from './App.module.scss';

import { ErrorBoundary } from './ErrorBoundary';
import { InContainerAuthProvider } from '../../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../../context/auth/StandaloneAuthProvider';
import { AuthGuard } from './AuthGuard';

interface Props {
  history: History;
}

export const appTestId = 'appTestId';

export const App = ({ history }: Props) => {
  const { isRenderedByContainer } = window;

  return (
    <div data-testid={appTestId} className={styles.app}>
      <ErrorBoundary>
        <StylesProvider injectFirst>
          <StyleProvider>
            <Router history={history}>
              {isRenderedByContainer ? (
                <InContainerAuthProvider>
                  <AuthGuard />
                </InContainerAuthProvider>
              ) : (
                <StandaloneAuthProvider>
                  <AuthGuard />
                </StandaloneAuthProvider>
              )}
            </Router>
          </StyleProvider>
        </StylesProvider>
      </ErrorBoundary>
    </div>
  );
};
