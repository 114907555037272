import React, { HTMLProps } from 'react';
import clsx from 'clsx';

import styles from './StoreEventCard.module.scss';

import {
  EventStatus,
  StoreEvent
} from '../../../../domain/Calendar/CalendarEvent';
import { CBStatus } from '../../CBStatus/CBStatus';
import { EventCardLayout } from '../EventCardLayout/EventCardLayout';
import { ReactComponent as StoreEventIcon } from './StoreEventIcon.svg';

const cardColors: Partial<Record<EventStatus, string>> = {
  [EventStatus.ToDo]: styles.todo,
  [EventStatus.Completed]: styles.completed,
  [EventStatus.Canceled]: styles.canceled,
  [EventStatus.Deleted]: styles.deleted
};

interface Props extends HTMLProps<HTMLDivElement> {
  event: StoreEvent;
}

export const StoreEventCard = (props: Props) => {
  const { event, className, ...rest } = props;

  return (
    <EventCardLayout
      className={clsx(
        className,
        styles.storeEvent,
        event && cardColors[event.status]
      )}
      {...rest}
    >
      <div className={styles.title}>
        <CBStatus status={event.status} className={styles.statusIcon} />
        {event.title}
      </div>
      <div className={styles.description}>{event.instruction}</div>

      <div className={styles.footer}>
        <StoreEventIcon />
        Store Event
      </div>
    </EventCardLayout>
  );
};
