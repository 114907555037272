export enum ProductPermissions {
  DELETE_DAP = 'RACPAD_DAP_DELETE',
  SUBMIT_DAP = 'RACPAD_DAP_SUBMIT',
  CANCEL_DAP = 'RACPAD_DAP_CANCEL',
  CREATE_GOAL = 'RACPAD_DAP_GOAL_CREATE',
  EDIT_GOAL = 'RACPAD_DAP_GOAL_EDIT',
  // Permission for create, edit and unblock
  // All users have view permission
  BLOCK_TIMESLOT = 'RACPAD_DAP_EVENT_BLOCK',
  SUPPORT_USER = 'RACPAD_DAP_SUPPORT',
  DAP_VIEW_ONLY_TASKS_EVENTS = 'DAP_VIEW_ONLY_TASKS_EVENTS'
}
