import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './Notes.module.scss';

import * as notesApi from '../../../api/notes';
import { Note, ParentType } from '../../../domain/Note/Note';
import { AddNote } from './AddNote/AddNote';
import { NoteCard } from './NoteCard/NoteCard';
import { useUserStateContext } from '../../../context/user/user-contexts';
import { NotesSkeleton } from './NotesSkeleton/NotesSkeleton';

export const notesTestId = 'notesTestId';

export interface NotesProps extends HTMLProps<HTMLDivElement> {
  parentId: string;
  parentType: ParentType;
  readonly?: boolean;
}

export const Notes = (props: NotesProps) => {
  const { parentId, parentType, readonly, ...rest } = props;
  const [notes, setNotes] = useState<Note[]>([]);
  const [currentNote, setCurrentNote] = useState<Note>();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { user } = useUserStateContext();

  const inputRef = useRef<any>();

  const fetchNotes = useCallback(() => {
    setLoading(true);
    setApiError(false);
    notesApi
      .getNotes(parentId)
      .then((response: Note[]) => {
        const sortedNotes = orderBy(
          response,
          [n => new Date(n.createdAt)],
          ['desc']
        );
        setNotes(sortedNotes);
      })
      .catch(() => {
        setApiError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [parentId]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleReplyNote = (note: Note) => {
    setCurrentNote(note);
    Object(inputRef)?.current.focus();
  };

  return useMemo(
    () => (
      <div className={styles.notes} data-testid={notesTestId} {...rest}>
        <div className={styles.title}>
          Notes {notes.length > 0 && <>({notes.length})</>}
        </div>
        {!readonly && (
          <AddNote
            note={currentNote}
            parentId={parentId}
            parentType={parentType}
            onAdd={fetchNotes}
            inputRef={inputRef}
          />
        )}
        <div className={styles.notesList}>
          {!loading ? (
            notes.length > 0 ? (
              notes.map(note => (
                <NoteCard
                  key={note.id}
                  note={note}
                  currentUser={user}
                  readonly={readonly}
                  handleReplyNote={() => handleReplyNote(note)}
                />
              ))
            ) : apiError ? (
              <div className={styles.apiError}>
                <span>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  We cannot retrieve the notes
                </span>
              </div>
            ) : (
              <div className={styles.emptyList}>There are no notes yet</div>
            )
          ) : (
            <NotesSkeleton readonly={readonly} />
          )}
        </div>
      </div>
    ),
    [
      currentNote,
      fetchNotes,
      loading,
      notes,
      parentId,
      parentType,
      readonly,
      rest,
      user,
      apiError
    ]
  );
};
