import {
  CalendarEvent,
  EventPriority,
  StoreEvent,
  BlockTimeEvent,
  EventStatusActionType,
  EventStatus,
  EventType,
  EventSourceType
} from '../../domain/Calendar/CalendarEvent';
import { MappedEvents } from './EventsProvider';

type Event = CalendarEvent | StoreEvent | BlockTimeEvent;

export const sortPriority = (events?: Event[]): Event[] | undefined =>
  events?.sort((a, b) => a.priority - b.priority);

export const changeStatus = (
  events: Event[],
  eventId: string,
  newStatus: EventStatus
) => {
  const targetEvent = events.find(e => e.eventId === eventId) as
    | CalendarEvent
    | StoreEvent
    | undefined;

  if (targetEvent) {
    targetEvent.status = newStatus;
    targetEvent.priority = EventPriority[newStatus];
  }

  return targetEvent;
};

export const setSource = (
  event: CalendarEvent | StoreEvent | undefined,
  sourceType: EventSourceType
) => {
  const updatedEvent = event as CalendarEvent;

  if (updatedEvent?.source) {
    updatedEvent.source = sourceType;
  }
};

export const getEventStatusByActionType = (
  eventStatusActionType: EventStatusActionType
) => {
  switch (eventStatusActionType) {
    case EventStatusActionType.Cancel:
      return EventStatus.Canceled;
    case EventStatusActionType.CancelRecur:
      return EventStatus.Canceled;
    case EventStatusActionType.Reopen:
      return EventStatus.ToDo;
    case EventStatusActionType.Complete:
      return EventStatus.Completed;
    case EventStatusActionType.Delete:
      return EventStatus.Deleted;
  }
};

export const removeEventFromTimeSlot = (
  eventId: string,
  currentTimeSlotId: string,
  events: MappedEvents
) => {
  const eventsFromCurrentTimeSlotWithoutTheUpdatedEvent =
    events[currentTimeSlotId]?.filter(event => event.eventId !== eventId) || [];

  return {
    ...events,
    [currentTimeSlotId]: eventsFromCurrentTimeSlotWithoutTheUpdatedEvent
  };
};

export const putEventIntoCorrespondingTimeSlot = (
  event: CalendarEvent,
  events: MappedEvents
) => {
  const eventsShallowCopy = { ...events };
  if (eventsShallowCopy[event.timeSlot.timeSlotId]) {
    eventsShallowCopy[event.timeSlot.timeSlotId].push(event);
  } else {
    eventsShallowCopy[event.timeSlot.timeSlotId] = [event];
  }

  return eventsShallowCopy;
};

export const removeDeletedBlockTimeEvents = (events: any) =>
  events?.filter((event: any) => {
    return (
      event.type !== EventType.BlockTime ||
      (event.status !== EventStatus.Deleted &&
        event.status !== EventStatus.Canceled)
    );
  });

export const formatEvents = (
  eventsResponse: CalendarEvent[]
): Record<string, CalendarEvent[]> => {
  const events: Record<string, CalendarEvent[]> = {};

  eventsResponse.forEach((event: CalendarEvent) => {
    events[event.timeSlot.timeSlotId] = events[event.timeSlot.timeSlotId] || [];

    event.priority = EventPriority[event.status];

    events[event.timeSlot.timeSlotId].push(event);
  });

  Object.values(events).forEach(sortPriority);
  return events;
};
