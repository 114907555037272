import {
  Button,
  Modal,
  ModalButtons,
  ModalContent
} from '@rentacenter/racstrap';
import React, { useRef } from 'react';

import styles from './PrintPreviewModal.module.scss';

export const printEventModalPreview = 'printEventModalPreview';
export const cancelButtonTestId = 'cancelButtonTestId';
export const printButtonTestId = 'printButtonTestId';

export interface PrintEventModalProps {
  url: string;
  onClose: () => void;
}

export const PrintPreviewModal = (props: PrintEventModalProps) => {
  const eventFrame = useRef<HTMLIFrameElement>(null);
  const { url, onClose } = props;

  const print = () => {
    const result = eventFrame.current?.contentWindow?.document.execCommand(
      'print',
      false,
      undefined
    );

    if (!result) {
      eventFrame.current?.contentWindow?.print();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      title="Print Preview"
      className={styles.printEventModal}
      onClose={onClose}
    >
      <ModalContent className={styles.modalContent}>
        <iframe
          data-testid={printEventModalPreview}
          src={url}
          frameBorder="0"
          ref={eventFrame}
          className={styles.preview}
          title="preview"
        ></iframe>
      </ModalContent>
      <ModalButtons>
        <Button
          data-testid={cancelButtonTestId}
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button data-testid={printButtonTestId} onClick={print}>
          Print
        </Button>
      </ModalButtons>
    </Modal>
  );
};
