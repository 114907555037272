import React, { HTMLProps, useContext, useState } from 'react';
import { DatePicker, Button, DropdownButton } from '@rentacenter/racstrap';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { isSameDay } from 'date-fns';
import { useHistory } from 'react-router-dom';

import styles from './CalendarToolbar.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';
import { CalendarEvent } from '../../../domain/Calendar/CalendarEvent';
import { FilterEvents } from './FilterEvents/FilterEvents';
import { SelectedFilterList } from '../SelectedFilterList/SelectedFilterList';
import { SearchEvent } from './SearchEvent/SearchEvent';
import { DapRoute } from '../../../config/route-config';
import { useEffect } from 'react';

export interface TasksToolbarProps extends HTMLProps<HTMLDivElement> {
  selectedDate: Date;
  onDateChange: (event: Date) => void;
  onCreateEvent: () => void;
  eventsLoading?: boolean;
  onShowEvent: (event: CalendarEvent) => void;
}

enum EventKind {
  Customer = 'Customer Event',
  Store = 'Store Event',
  BlockTime = 'Block Time'
}

const addEventTestId = 'addEventButton';

export const CalendarToolbar = ({
  selectedDate,
  onDateChange,
  onCreateEvent,
  eventsLoading,
  onShowEvent
}: TasksToolbarProps) => {
  const history = useHistory();
  const { selectedStore } = useContext(StoreContext);
  const {
    canSubmit,
    isSupportUser,
    canBlockTimeslot,
    isCreateEventForbidden
  } = useUserPermissions();

  const [open, setOpen] = useState(false);
  // EventKind.BlockTime will be inserted only if permission is granted
  const [eventOptions, setEventOptions] = useState([EventKind.Customer]);

  useEffect(() => {
    if (canBlockTimeslot && !isSupportUser)
      setEventOptions(options => [...options, EventKind.BlockTime]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canBlockTimeslot]);

  useEffect(() => {
    if (isSupportUser || isSupportUser === undefined) return;
    setEventOptions(options => [...options, EventKind.Store]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportUser]);

  function addEventSelected({ value }: { value: EventKind }) {
    if (value === EventKind.Customer) {
      onCreateEvent();
    } else if (value === EventKind.Store) {
      history.push(DapRoute.CalendarNewStoreEvent);
    } else if (value === EventKind.BlockTime) {
      history.push(DapRoute.CalendarNewBlockEvent);
    }
  }

  const canAddEvent = canSubmit && !isCreateEventForbidden;
  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.datePickerWrap}>
          <DatePicker
            className={styles.datePicker}
            name="date"
            placeholder="Select a date"
            onChange={onDateChange}
            value={selectedDate}
            format="eee, MMM dd, yyyy"
            disabled={eventsLoading}
          />
          <Button
            data-testid="todayBtn"
            variant="outlined"
            disabled={isSameDay(selectedDate, new Date())}
            onClick={() => onDateChange(new Date())}
            className={styles.todayBtn}
          >
            Today
          </Button>
        </div>
        <div className={styles.search}>
          <SearchEvent onShowEvent={onShowEvent} />
        </div>
        <div className={styles.toolbarButtons}>
          <FilterEvents />
          {canAddEvent && (
            <div className={styles.createEvent}>
              <Tooltip title="Add Event" placement="top">
                <span>
                  <DropdownButton
                    data-testid={addEventTestId}
                    className={styles.collpsibleButtons}
                    disabled={!selectedStore}
                    icon={<FontAwesomeIcon icon={faCalendarPlus} />}
                    classes={{
                      startIcon: styles.btnIcon,
                      options: styles.createEventOptions
                    }}
                    open={open}
                    options={eventOptions.map(v => ({
                      label: v,
                      value: v
                    }))}
                    onClose={() => setOpen(false)}
                    onClick={() => setOpen(!open)}
                    onSelect={addEventSelected}
                  >
                    <span className={styles.buttonText}>Add Event</span>
                  </DropdownButton>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <SelectedFilterList />
    </>
  );
};
