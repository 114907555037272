import React, { HTMLProps, useContext, useEffect, useState } from 'react';

import styles from './SalesGoals.module.scss';

import { SalesGoalCard } from './SalesGoalCard/SalesGoalCard';

import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader';
import { getSalesGoals } from '../../../api/goals';
import { StoreContext } from '../../../context/store/StoreProvider';
import { SalesGoal } from '../../../domain/Calendar/Goal';
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from '../../../api/client';
import { ApiError } from '../../common/ApiError/ApiError';
import { NoItems } from '../../Calendar/NewEvent/NoItems/NoItems';

export const salesGoalsTestId = 'salesGoalsTestId';

export interface SalesGoalsProps extends HTMLProps<HTMLDivElement> {
  startDate: Date;
}

const GoalsList = (props: SalesGoalsProps) => {
  const { startDate } = props;
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState<SalesGoal[]>([]);
  const [error, setError] = useState(false);

  const { selectedStore } = useContext(StoreContext);

  function fetchSalesGoals(
    cancelToken: CancelTokenSource,
    showLoader: boolean
  ) {
    if (!selectedStore) {
      return;
    }

    showLoader && setLoading(true);
    setError(false);
    getSalesGoals(selectedStore, startDate, cancelToken.token)
      .then(response => {
        setGoals(response);
      })
      .catch(err => {
        if (!err.__CANCEL__) setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const cancelToken: CancelTokenSource = getCancelTokenSource();

    fetchSalesGoals(cancelToken, true);

    return () => {
      cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore, startDate]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return (
      <ApiError
        className={styles.apiError}
        text="We are experiencing an internal server problem, please refresh the page or select another week"
      />
    );
  }

  if (goals.length === 0) {
    return <NoItems className={styles.noResults}>No Results Found</NoItems>;
  }

  return (
    <>
      {goals.map(goal => (
        <SalesGoalCard
          key={goal.coworkerId}
          goal={goal}
          startDate={startDate}
        />
      ))}
    </>
  );
};

export const SalesGoals = (props: SalesGoalsProps) => {
  const { startDate, ...rest } = props;
  return (
    <div className={styles.salesGoals} data-testid={salesGoalsTestId} {...rest}>
      <GoalsList startDate={startDate} />
    </div>
  );
};
