import { Table } from '@rentacenter/racstrap';
import React, { HTMLProps } from 'react';

import styles from './SelectionTable.module.scss';

export const selectionTestId = 'selectionTestId';

export const SelectionTable = ({ children }: HTMLProps<HTMLTableElement>) => {
  return (
    <div data-testid={selectionTestId}>
      <Table classes={{ table: styles.selectionTable }}>{children}</Table>
    </div>
  );
};
