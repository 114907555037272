import clsx from 'clsx';
import React, { CSSProperties, HTMLProps, useMemo } from 'react';

import styles from './ProgressBar.module.scss';

export const progressBarTestId = 'progressBarTestId';
export const metricCountTestId = 'metricCountTestId';
export const metricGoalTestId = 'metricGoalTestId';

export interface ProgressBarProps extends HTMLProps<HTMLDivElement> {
  value: number;
  max: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { value, max, className, ...rest } = props;

  const valuePercent = useMemo(() => {
    if (max === 0 && value === 0) return 0;
    if (Math.abs(value) >= max) return 50;

    return Math.abs((value * 100) / (max || 1) / 2);
  }, [value, max]);

  const getValuePosition = (): CSSProperties => {
    const position = `${50 - valuePercent}%`;
    if (value >= 0) {
      return { right: position };
    }

    return { left: position };
  };

  const valueColorClass = () => {
    if (max === 0) return;

    if (value < 0) return styles.negative;

    if (valuePercent * 2 <= 80) return styles.medium;

    return styles.high;
  };

  return (
    <div
      className={clsx(styles.progressBar, className)}
      data-testid={progressBarTestId}
      {...rest}
    >
      <div className={styles.value}>
        <span
          data-testid={metricCountTestId}
          style={getValuePosition()}
          className={valueColorClass()}
        >
          {value}
        </span>
      </div>

      <div className={styles.bar}>
        <div
          className={clsx(
            value >= 0 ? styles.positiveDir : styles.negativeDir,
            valueColorClass()
          )}
          style={{ width: `${valuePercent}%` }}
        ></div>
      </div>

      <div className={styles.ticks}>
        <div className={styles.min}>0</div>
        <div data-testid={metricGoalTestId} className={styles.max}>
          {max}
        </div>
      </div>
    </div>
  );
};
