import React from 'react';
import { format, parseISO } from 'date-fns';
import { Table } from '@rentacenter/racstrap';

import styles from './CustomerList.module.scss';

import { Customer } from '../../../../../domain/Customer/Customer';
import {
  useNewEventDispatch,
  useNewEventState
} from '../../../../../context/events/NewEventProvider';
import { Loading } from '../../Loading/Loading';
import { SelectableRow } from '../../SelectableRow/SelectableRow';
import { ApiError } from '../../../../common/ApiError/ApiError';
import { NoItems } from '../../NoItems/NoItems';

type ListStatus = 'initial' | 'loading' | 'apiError' | 'empty' | 'success';

interface Props {
  readonly loading: boolean;
  readonly hasApiError: boolean;
}
export const customersListTestId = 'customersTable';
export function CustomerList({ loading, hasApiError }: Props) {
  const { setSelectedCustomer } = useNewEventDispatch();
  const {
    selectCustomer: { customers, selectedCustomer }
  } = useNewEventState();

  const getStatus = (): ListStatus => {
    if (loading) return 'loading';
    if (hasApiError) return 'apiError';

    if (customers) {
      return customers.length > 0 ? 'success' : 'empty';
    } else {
      return 'initial';
    }
  };

  return (
    <div className={styles.list}>
      {
        {
          initial: null,
          loading: <Loading className={styles.loading} />,
          apiError: <ApiError className={styles.apiError} />,
          empty: <NoItems>No Search Results Found</NoItems>,
          success: (
            <Table
              classes={{ table: styles.activeTable }}
              data-testid={customersListTestId}
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Customers Name</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col"># Agreements</th>
                  <th scope="col">Date of latest Agreement</th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer: Customer, index) => (
                  <SelectableRow
                    key={index}
                    selected={selectedCustomer?.partyId === customer.partyId}
                    onClick={() => {
                      if (selectedCustomer?.partyId === customer.partyId) {
                        setSelectedCustomer();
                      } else {
                        setSelectedCustomer(customer);
                      }
                    }}
                  >
                    <td>
                      {customer.firstName} {customer.lastName}
                    </td>
                    <td>
                      {customer.dateOfBirth
                        ? format(parseISO(customer.dateOfBirth), 'MM/dd/yyyy')
                        : 'N/A'}
                    </td>
                    <td>{customer.agreementCountByStore || '0'}</td>
                    <td>
                      {customer.recentAgreementCreated
                        ? format(
                            parseISO(customer.recentAgreementCreated),
                            'MM/dd/yyyy'
                          )
                        : 'N/A'}
                    </td>
                  </SelectableRow>
                ))}
              </tbody>
            </Table>
          )
        }[getStatus()]
      }
    </div>
  );
}
