import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@rentacenter/racstrap';
import React, { HTMLProps } from 'react';

import styles from './GoalFormButtons.module.scss';

export const goalFormButtonsTestId = 'goalFormButtonsTestId';

export interface GoalFormButtonsProps extends HTMLProps<HTMLDivElement> {
  pending?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

export const GoalFormButtons = (props: GoalFormButtonsProps) => {
  const { pending, onCancel, onSave, ...rest } = props;
  return (
    <div
      className={styles.goalFormButtons}
      data-testid={goalFormButtonsTestId}
      {...rest}
    >
      <Button
        data-testid="cancel"
        size="small"
        className={styles.cancel}
        onClick={onCancel}
        variant="outlined"
        disabled={pending}
      >
        Cancel
      </Button>
      <Button
        data-testid="save"
        size="small"
        className={styles.save}
        type="submit"
        disabled={pending}
        {...(pending && {
          icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
        })}
      >
        Save
      </Button>
    </div>
  );
};
