import React, { HTMLProps, useEffect, useState } from 'react';

import {
  CalendarEvent,
  EventStatusActionType,
  EventType,
  StoreEvent
} from '../../../domain/Calendar/CalendarEvent';
import { StoreEventDetails } from './StoreEventDetails/StoreEventDetails';
import { CustomerEventDetails } from './CustomerEventDetails/CustomerEventDetails';
import { useRouteMatch } from 'react-router-dom';
import { getEvent } from '../../../api/calendar';
import { BlockTimeEventDetails } from '../BlockTimeEventDetails/BlockTimeEventDetails';

export interface EventDetailsProps extends HTMLProps<HTMLDivElement> {
  event?: CalendarEvent | StoreEvent;
  onStatusChange: (eventStatusActionType: EventStatusActionType) => void;
}

export const EventDetails = ({
  event: propsEvent,
  onStatusChange,
  ...rest
}: EventDetailsProps) => {
  const [event, setEvent] = useState(propsEvent);
  const [printMode, setPrintMode] = useState(false);

  const match = useRouteMatch();

  useEffect(
    function fetchEventIfMissing() {
      if (event && event.recurringAppointmentId !== '0') {
        const getType =
          event!.recurringAppointmentId !== '0' ? 'recur' : undefined;
        getEvent(
          event.storeId as string,
          event.recurringAppointmentId as string,
          getType
        )
          .then((response: any) => {
            event!.startDate = response.startDate;
            event!.endDate = response.endDate;
            setEvent(event);
          })
          .finally(() => {
            setPrintMode(true);
          });
      }
      if (!event) {
        const { storeId, eventId } = match.params as any;
        getEvent(storeId, eventId)
          .then(response => {
            setEvent(response[0]);
          })
          .finally(() => {
            setPrintMode(true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!event) {
    return <div>Loading...</div>;
  }

  switch (event?.type) {
    case EventType.Store:
      return (
        <StoreEventDetails
          event={event as StoreEvent}
          onStatusChange={onStatusChange}
          printMode={printMode}
          {...rest}
        />
      );
    case EventType.BlockTime:
      return (
        <BlockTimeEventDetails
          event={event as CalendarEvent}
          onStatusChange={onStatusChange}
          {...rest}
        />
      );
    default:
      // not so good default
      return (
        <CustomerEventDetails
          event={event as CalendarEvent}
          onStatusChange={onStatusChange}
          printMode={printMode}
          {...rest}
        />
      );
  }
};
