import React from 'react';
import clsx from 'clsx';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './EventStatus.module.scss';
import {
  cancelled,
  completed,
  deleted,
  EventStatus,
  EventStatusNames
} from '../../../domain/Calendar/CalendarEvent';

interface StatusProps {
  status: EventStatus;
  className?: string;
}

const getStatusStyle: Partial<Record<EventStatus, string>> = {
  [EventStatus.ToDo]: styles.toDo,
  [EventStatus.Canceled]: styles.canceled,
  [EventStatus.Completed]: styles.completed,
  [EventStatus.Deleted]: styles.deleted
};

export const Status = (props: StatusProps) => {
  const { status, className } = props;

  return (
    <div className={clsx(getStatusStyle[status], className)}>
      {completed(status) && <FontAwesomeIcon icon={faCheck} />}
      {cancelled(status) && <FontAwesomeIcon icon={faTimes} />}
      {deleted(status) && <FontAwesomeIcon icon={faTrash} />}
      {EventStatusNames[status]}{' '}
    </div>
  );
};
