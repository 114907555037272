import React, { useContext, useEffect, useState } from 'react';
import styles from './SelectedFilterList.module.scss';
import calendarToolbarStyles from './../CalendarToolbar/CalendarToolbar.module.scss';
import {
  EventsDispatchContext,
  EventsStateContext,
  Filters
} from '../../../context/events/EventsProvider';
import { Chip } from '../../common/Chip/Chip';
import {
  EventSourceType,
  EventType as EventTypeEnum
} from '../../../domain/Calendar/CalendarEvent';
import { EventType } from '../../common/EventType/EventType';
import { EventStatus } from '../../../domain/Calendar/CalendarEvent';
import { Status } from '../../common/EventStatus/EventStatus';
import { EventSource } from '../../common/EventSource/EventSource';

export const SelectedFilterList = () => {
  const { setFilters } = useContext(EventsDispatchContext);
  const { filters } = useContext(EventsStateContext);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({
    EventType: [],
    EventStatus: [],
    EventSource: [],
    showDeletedEvents: false
  });

  useEffect(() => {
    if (filters) setSelectedFilters(filters);
  }, [filters]);

  const onDeselectFilter = (
    filterName: EventStatus | EventSourceType | EventTypeEnum
  ) => {
    if (Object.values(EventTypeEnum).includes(filterName as EventTypeEnum)) {
      setFilters({
        ...selectedFilters,
        EventType: selectedFilters.EventType.filter(
          filter => filter !== filterName
        )
      });
    } else if (Object.values(EventStatus).includes(filterName as EventStatus)) {
      setFilters({
        ...selectedFilters,
        EventStatus: selectedFilters.EventStatus.filter(
          filter => filter !== filterName
        )
      });
    } else if (
      Object.values(EventSourceType).includes(filterName as EventSourceType)
    ) {
      setFilters({
        ...selectedFilters,
        EventSource: selectedFilters.EventSource.filter(
          filter => filter !== filterName
        )
      });
    }
  };

  return (
    <div className={styles.selectedFilters}>
      {Object.values(selectedFilters.EventType).map((key, index) => {
        return (
          <Chip
            className={calendarToolbarStyles.selected}
            selected
            showClose
            onCloseClick={() => onDeselectFilter(key)}
            key={index}
          >
            <EventType
              type={key as EventTypeEnum}
              isSelected
              isFilterEvent
              iconClassName={calendarToolbarStyles.iconPadding}
            />
          </Chip>
        );
      })}
      {Object.values(selectedFilters.EventSource).map((key, index) => {
        const eventSourceKeys = key as EventSourceType;
        return (
          <Chip
            className={calendarToolbarStyles.selected}
            showClose
            selected
            onCloseClick={() => onDeselectFilter(key)}
            key={index}
          >
            <EventSource source={eventSourceKeys} />
          </Chip>
        );
      })}
      {Object.values(selectedFilters.EventStatus).map((key, index) => {
        const eventStatusKeys = key as EventStatus;
        return (
          <Chip
            className={calendarToolbarStyles[eventStatusKeys]}
            showClose
            selected
            onCloseClick={() => onDeselectFilter(key)}
            key={index}
          >
            <Status status={eventStatusKeys} />
          </Chip>
        );
      })}
    </div>
  );
};
