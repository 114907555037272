import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  timeSlotRules,
  TimeSlotSelector
} from '../../Calendar/TimeSlotSelector/TimeSlotSelector';

interface TimeSlotFieldProps {
  fieldName: string;
  editMode?: boolean;
  isBlockTimeEvent?: boolean;
  onError: () => void;
}

export const TimeSlotField: FC<TimeSlotFieldProps> = ({
  fieldName,
  editMode,
  isBlockTimeEvent,
  onError
}) => {
  const { control, errors } = useFormContext<any>();

  return (
    <Controller
      control={control}
      name={fieldName}
      required
      rules={timeSlotRules}
      render={({ onChange, ...rest }) => (
        <TimeSlotSelector
          errorMessage={errors?.timeSlot?.message}
          isBlockTimeEvent={isBlockTimeEvent}
          onChange={onChange}
          editMode={editMode}
          onError={onError}
          {...rest}
        />
      )}
    />
  );
};
