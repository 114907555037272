import React, { HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { SelectionTable } from '../SelectionTable/SelectionTable';
import { useNewEventState } from '../../../../context/events/NewEventProvider';

export const selectedCustomerTestId = 'selectedCustomerTestId';

export interface SelectedCustomerProps extends HTMLProps<HTMLDivElement> {
  firstProp?: any;
}

export const SelectedCustomer = (props: SelectedCustomerProps) => {
  const { ...rest } = props;
  const {
    selectCustomer: { selectedCustomer }
  } = useNewEventState();

  return (
    <div data-testid={selectedCustomerTestId} {...rest}>
      <SelectionTable>
        <thead>
          <tr>
            <th scope="col" data-check-cell></th>
            <th scope="col">Customers Name</th>
            <th scope="col">Date of Birth</th>
            <th scope="col"># Agreements</th>
            <th scope="col">Date of latest Agreement</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <FontAwesomeIcon icon={faCheck} />
            </td>
            <td>
              {selectedCustomer?.firstName} {selectedCustomer?.lastName}
            </td>
            <td>{selectedCustomer?.dateOfBirth || 'N/A'}</td>
            <td>{selectedCustomer?.agreementCountByStore || '0'}</td>
            <td>{selectedCustomer?.recentAgreementCreated || 'N/A'}</td>
          </tr>
        </tbody>
      </SelectionTable>
    </div>
  );
};
