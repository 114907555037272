import { format } from 'date-fns';

import { dateFormat } from './calendar';

export const formatDate = (date: Date): string => {
  return format(date, dateFormat);
};

export const ensureNumber = (value: any): number => {
  if (value !== undefined) {
    return Number(value);
  }

  return value;
};
