import React, { HTMLProps } from 'react';

import styles from './StoreGoalView.module.scss';

import { ProgressBar } from './ProgressBar/ProgressBar';

export const storeGoalViewTestId = 'storeGoalViewTestId';

export interface StoreGoalViewProps extends HTMLProps<HTMLDivElement> {
  value: number;
  max: number;
}

export const StoreGoalView = ({ value, max, ...rest }: StoreGoalViewProps) => {
  return (
    <div
      className={styles.storeGoalView}
      data-testid={storeGoalViewTestId}
      {...rest}
    >
      <ProgressBar value={value} max={max} className={styles.progressBar} />
    </div>
  );
};
