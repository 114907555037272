import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { compact } from 'lodash';

import styles from './CustomerEventCard.module.scss';

import {
  CalendarEvent,
  EventSourceType,
  EventStatus
} from '../../../../domain/Calendar/CalendarEvent';
import { EventType } from '../../../common/EventType/EventType';
import { EventSource } from '../../../common/EventSource/EventSource';
import { CBStatus } from '../../CBStatus/CBStatus';
import { EventCardLayout } from '../EventCardLayout/EventCardLayout';

const cardColors: Partial<Record<EventStatus, string>> = {
  [EventStatus.ToDo]: styles.todo,
  [EventStatus.Completed]: styles.completed,
  [EventStatus.Canceled]: styles.canceled,
  [EventStatus.Deleted]: styles.deleted
};

interface Props extends HTMLProps<HTMLDivElement> {
  event: CalendarEvent;
}

export const CustomerEventCard = (props: Props) => {
  const { event, className, ...rest } = props;
  const { customerInformation } = event;
  const { address, firstName, lastName } = customerInformation || {};

  return (
    <EventCardLayout
      className={clsx(className, event && cardColors[event.status])}
      {...rest}
    >
      <>
        <div
          className={`${
            event.source === EventSourceType.OffSite
              ? clsx(styles.customer, styles.w60)
              : styles.customer
          }`}
        >
          <CBStatus status={event.status} className={styles.statusIcon} />
          {firstName} {lastName}
        </div>
        <>
          {event.source === EventSourceType.OffSite ? (
            <span className={styles.tireBuyerBatch}>{`Tire Buyer`}</span>
          ) : null}
        </>
      </>
      <div className={styles.address}>
        {compact([
          address?.addressLine1,
          address?.city,
          address?.state,
          address?.zipCode
        ]).join(', ')}
      </div>
      <div className={styles.footer}>
        <EventType type={event.type} className={styles.type} />
        <EventSource source={event.source} className={styles.source} />
      </div>
    </EventCardLayout>
  );
};
