import React, { FC } from 'react';
import { Input, InputProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { TaskFormFields } from '../../CreateTask/CreateTask';

export const TaskTitle: FC<Partial<InputProps>> = props => {
  const { control, errors, clearErrors } = useFormContext();
  return (
    <Controller
      control={control}
      name={TaskFormFields.title}
      rules={{
        required: true,
        maxLength: {
          value: 100,
          message: 'Maximum of 100 characters'
        }
      }}
      render={({ ref, onChange, ...rest }, { invalid }) => (
        <Input
          label="Task Title"
          required={true}
          placeholder="Insert Task Title"
          size="large"
          invalid={invalid}
          errorMessage={errors?.title?.message}
          onChange={value => {
            clearErrors(TaskFormFields.title);
            onChange(value);
          }}
          {...rest}
          {...props}
        />
      )}
    />
  );
};
