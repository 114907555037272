import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import { Checkbox, DatePicker } from '@rentacenter/racstrap';
import { add, format, isBefore, startOfDay } from 'date-fns';
import styles from './TimeSlotSelector.module.scss';

import { getIntervalTimeSlots } from '../../../api/calendar';
import { StoreContext } from '../../../context/store/StoreProvider';
import { TimeSlot } from '../../../domain/Calendar/Timeslot';
import { ApiError } from '../../common/ApiError/ApiError';
import { Loading } from '../NewEvent/Loading/Loading';
import { TimeSlotColumn } from './TimeSlotColumn/TimeSlotColumn';
import { getCancelTokenSource } from '../../../api/client';
import { EventSourceType } from '../../../domain/Calendar/CalendarEvent';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';
import { RecurrentPeriodField } from './RecurrentPeriodField';

export const timeSlotSelectorTestId = 'timeSlotSelectorTestId';

export const timeSlotRules = {
  validate: (value: any) => {
    return !!value.timeSlotId || 'Please select timeslot';
  }
};

export interface TimeSlotValue {
  date?: Date;
  timeSlotId?: string;
}

export interface TimeSlotSelectorProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  onChange: (value: TimeSlotValue) => void;
  errorMessage?: string;
  editMode?: boolean;
  value?: TimeSlotValue;
  onError: () => void;
  isBlockTimeEvent?: boolean;
}

// eslint-disable-next-line
export const TimeSlotSelector = (props: TimeSlotSelectorProps) => {
  const {
    onChange,
    errorMessage,
    editMode,
    isBlockTimeEvent,
    value,
    onError,
    ...rest
  } = props;
  const [timeSlotsApiError, setTimeSlotsApiError] = useState<string>();
  const [timeslots, setTimeslots] = useState<TimeSlot[][]>([]);
  const [pickedDate, setPickedDate] = useState(value?.date || new Date());
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>();
  const [recurrentEvent, setRecurrentEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isSupportUser } = useUserPermissions();

  const sourceType = isSupportUser
    ? EventSourceType.Support
    : EventSourceType.Store;

  const { selectedStore } = useContext(StoreContext);

  const handleChange = (date?: Date, timeSlot?: TimeSlot) => {
    setSelectedTimeSlot(timeSlot);
    setSelectedDate(date);
    onChange({ date, timeSlotId: timeSlot?.id });
  };

  const pickedDateUnix = pickedDate.getTime();

  useEffect(
    function fetchTimeslots() {
      if (!selectedStore || !pickedDate) return;

      setTimeSlotsApiError('');

      setLoading(true);
      handleChange(undefined, undefined);

      const cancelToken = getCancelTokenSource();

      getIntervalTimeSlots(
        selectedStore,
        pickedDate,
        add(pickedDate, { days: editMode ? 1 : 3 }),
        sourceType,
        cancelToken.token
      )
        .then((timeSlots: TimeSlot[][]) => {
          setTimeslots(timeSlots);
        })
        .catch(err => {
          if (!err.__CANCEL__) {
            onError();
            setTimeSlotsApiError(
              'Something went wrong, try later or another date!'
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });

      return cancelToken.cancel;
    },
    //eslint-disable-next-line
    [selectedStore, pickedDateUnix]);

  useEffect(
    function syncValueWithState() {
      if (!value) {
        return;
      }

      let selectedTimeSlot;

      mainLoop: for (let f = 0; f < timeslots.length; f++) {
        for (let g = 0; g < timeslots[f].length; g++) {
          if (timeslots[f][g].id === value.timeSlotId) {
            selectedTimeSlot = timeslots[f][g];
            break mainLoop;
          }
        }
      }

      setSelectedDate(value.date);
      setSelectedTimeSlot(selectedTimeSlot);
    },
    [timeslots, value]
  );

  const formatedSelection = () => {
    if (!selectedDate || !selectedTimeSlot) {
      return '---';
    }

    const formattedDate = format(selectedDate, 'LLL dd');
    const formattedStart = format(selectedTimeSlot.startTime, 'h a');
    const formattedEnd = format(selectedTimeSlot.endTime, 'h a');

    return `${formattedStart} to ${formattedEnd}, ${formattedDate}`;
  };

  const getDateError = (date: Date) => {
    if (isBefore(date, startOfDay(new Date()))) {
      return 'Cannot be in the past';
    }
  };

  const render = () => {
    if (loading) {
      return <Loading />;
    }

    if (timeSlotsApiError) {
      return (
        <ApiError text="We are experiencing an internal server problem, please try to refresh the page." />
      );
    }

    return (
      <>
        <div className={styles.subtitle}>
          You have selected: {formatedSelection()}
        </div>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        <div className={styles.days}>
          {timeslots.map((timeSlots, index) => {
            const day = add(pickedDate, { days: index });

            return (
              <TimeSlotColumn
                key={index}
                timeslots={timeSlots}
                day={day}
                className={styles.day}
                selectedTimeSlot={selectedTimeSlot}
                selectedDate={selectedDate}
                onSelect={timeSlot => handleChange(day, timeSlot)}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div
      className={styles.timeSlotSelector}
      data-testid={timeSlotSelectorTestId}
      {...rest}
    >
      <div className={styles.datePicker}>
        <DatePicker
          required
          label="Date"
          placeholder="Select a date"
          name="date"
          onChange={date => setPickedDate(date)}
          errorMessage={getDateError(pickedDate)}
          value={pickedDate}
          datePickerProps={{
            disablePast: true
          }}
        />
        {isBlockTimeEvent && (
          <>
            <Checkbox
              labelText="Recurrent Event"
              onChange={() => setRecurrentEvent(!recurrentEvent)}
              checked={recurrentEvent}
            />
            <RecurrentPeriodField
              fieldName="period"
              disabled={!recurrentEvent}
            ></RecurrentPeriodField>
            {/* <Select
              options={buildOptions(RecurrencyPeriod)}
              size="large"
              placeholder="Select Recurrency Period"
              name="period"
              onChange={onChange}
              label="Select Recurrency Period"
              disabled={!recurrentEvent}
            ></Select> */}
          </>
        )}
      </div>

      <div className={styles.legend}>
        <div className={styles.available}>Available</div>
        <div className={styles.blocked}>Blocked</div>
        <div className={styles.busy}>Busy</div>
      </div>

      <div className={styles.title}>Select a timeslot:</div>
      {render()}
    </div>
  );
};
