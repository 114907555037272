import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectOption } from '@rentacenter/racstrap';
import clsx from 'clsx';

import styles from '../CreateTask/CreateTask.module.scss';

import { Task, TaskPriority, TaskStatus } from '../../../domain/Task/Task';
import { StoreContext } from '../../../context/store/StoreProvider';
import { TasksDispatchContext } from '../../../context/tasks/TasksProvider';
import { TaskFormData } from '../CreateTask/CreateTask';
import { FormActions } from '../TaskForm/FormActions';
import { TaskTitle } from '../TaskForm/TaskTitle/TaskTitle';
import { TaskCategorySelect } from '../TaskForm/TaskCategorySelect/TaskCategorySelect';
import { TaskAssigneesSelect } from '../TaskForm/TaskAssigneesSelect/TaskAssigneesSelect';
import { TaskPrioritySelect } from '../TaskForm/TaskPrioritySelect/TaskPrioritySelect';
import { TaskStatusSelect } from '../TaskForm/TaskStatusSelect/TaskStatusSelect';
import { TaskDueDate } from '../TaskForm/TaskDueDate/TaskDueDate';
import { TaskDueTime } from '../TaskForm/TaskDueTime/TaskDueTime';
import { TaskDescription } from '../TaskForm/TaskDescription/TaskDescription';
import { noOp } from '../../../utils/helper-functions';

export const editTaskFormId = 'editTaskFormId';

interface EditTaskFormProps {
  onCancel?: () => void;
  onSave?: (task: Task) => void;
  onPending?: (pending: boolean) => void;
  task: Task;
}

export const EditTask: FC<EditTaskFormProps> = props => {
  const {
    onCancel: handleClose = noOp,
    onSave = noOp,
    task,
    onPending = noOp
  } = props;
  const { selectedStore } = useContext(StoreContext);
  const [assignee, setAssignee] = useState<SelectOption>({
    label: task.assignee.name,
    value: task.assignee.userId
  });
  const [pending, setPending] = useState<boolean>(false);
  const { updateTask } = useContext(TasksDispatchContext);

  const formMethods = useForm<TaskFormData>({
    mode: 'onChange',
    defaultValues: {
      ...task,
      assignee: task.assignee.userId,
      dueDate: new Date(task.dueDate),
      dueTime: new Date(task.dueDate)
    }
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: TaskFormData) => {
    if (!selectedStore) return;

    setPending(true);
    onPending(true);

    const payload = {
      title: data.title,
      category: data.category,
      assignee: {
        userId: assignee?.value,
        name: assignee?.label
      },
      priority: data.priority as TaskPriority,
      taskStatus: data.taskStatus as TaskStatus,
      dueDate: data.dueTime.toISOString(),
      description: data.description?.trim()
    } as Task;

    updateTask(task, payload)
      .then((response: Task) => {
        handleClose();
        onSave(response);
      })
      .finally(() => {
        setPending(false);
        onPending(false);
      });
  };

  return (
    <>
      <div className={clsx(styles.container, styles.editMode)}>
        <FormProvider {...formMethods}>
          <form
            id={editTaskFormId}
            data-testid={editTaskFormId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.row}>
              <TaskTitle disabled={pending} />
            </div>
            <div className={clsx(styles.row, styles.split)}>
              <div className={styles.left}>
                <TaskCategorySelect disabled={pending} />
              </div>
              <div className={styles.right}>
                <TaskAssigneesSelect
                  onChange={setAssignee}
                  disabled={pending}
                />
              </div>
            </div>
            <div className={clsx(styles.row, styles.split)}>
              <div className={styles.left}>
                <TaskPrioritySelect disabled={pending} />
              </div>
              <div className={styles.right}>
                <TaskStatusSelect disabled />
              </div>
            </div>
            <div className={clsx(styles.row, styles.split)}>
              <div className={styles.left}>
                <TaskDueDate disabled={pending} />
              </div>
              <div className={styles.right}>
                <TaskDueTime disabled={pending} />
              </div>
            </div>
            <div className={clsx(styles.row, styles.multiline)}>
              <TaskDescription disabled={pending} multiline rows={3} />
            </div>

            <div className={clsx(styles.buttons, styles.editMode)}>
              <FormActions
                form={editTaskFormId}
                pending={pending}
                onClose={handleClose}
                submitButtonText="Save"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
