import React, { HTMLProps } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { EventSourceType } from '../../../domain/Calendar/CalendarEvent';
import { EventSource } from '../EventSource/EventSource';
import { EventLog } from '../../../domain/HistoryLog/HistoryLog';

import styles from './EventHistoryLog.module.scss';

interface EventHistoryLogProps extends HTMLProps<HTMLDivElement> {
  eventLogs: EventLog[];
}

export const eventHistoryLogsTestId = 'eventHistoryLogsTestId';

export const EventHistoryLog = (props: EventHistoryLogProps) => {
  const { eventLogs } = props;

  return (
    <>
      {eventLogs?.map(
        (
          {
            userFirstName,
            userLastName,
            eventSource,
            eventAction,
            eventInstruction,
            eventDateTime
          },
          index: number
        ) => (
          <tr
            key={index}
            className={styles.logsTable}
            data-testid={eventHistoryLogsTestId}
          >
            <td className={styles.userName}>
              {eventSource === EventSourceType.Online
                ? 'Online customer'
                : `${userFirstName} ${userLastName}`}
              <EventSource className={styles.source} source={eventSource} />
            </td>
            <td className={clsx(styles.action, styles[eventAction])}>
              {eventAction}
              {eventInstruction && (
                <tr className={styles.reason}>{`"${eventInstruction}"`}</tr>
              )}
            </td>
            <td className={styles.dateColumn}>
              <>{format(new Date(eventDateTime), 'MMM dd yyyy')}</>
              <div className={styles.time}>
                {format(new Date(eventDateTime), 'h:mm a')}
              </div>
            </td>
          </tr>
        )
      )}
    </>
  );
};
