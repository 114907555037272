import React, { FC } from 'react';
import { Button } from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type FormActionsProps = {
  form: string;
  pending?: boolean;
  onClose?: () => void;
  submitButtonText?: string;
};

export const FormActions: FC<FormActionsProps> = ({
  form,
  pending,
  onClose,
  submitButtonText = 'Create'
}) => {
  return (
    <>
      <Button
        data-testid="cancelTask"
        disabled={pending}
        size="large"
        variant="outlined"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        data-testid="submitTask"
        disabled={pending}
        size="large"
        type="submit"
        form={form}
        {...(pending && {
          icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
        })}
      >
        {submitButtonText}
      </Button>
    </>
  );
};
