import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import {
  DatePicker,
  Button,
  Label,
  Select,
  SelectOption
} from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { isSameDay } from 'date-fns';
import clsx from 'clsx';

import styles from './TasksToolbar.module.scss';

import { getEmployeeList } from '../../../../api/tasks';
import { StoreContext } from '../../../../context/store/StoreProvider';
import { makeEmployeesOptions } from '../../TaskForm/TaskAssigneesSelect/TaskAssigneesSelect';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';
import { getCancelTokenSource } from '../../../../api/client';

export interface TasksToolbarProps extends HTMLProps<HTMLDivElement> {
  selectedDate: Date;
  coworker: string;
  onDateChange: (event: Date) => void;
  onChangeCoworker: (value: string) => void;
  onCreateNewTask: () => void;
  disabledSelectedDate?: boolean;
}

// enum FilterMode {
//   Day = 'Day',
//   Month = 'Month'
// }

export const TasksToolbar = (props: TasksToolbarProps) => {
  const {
    selectedDate,
    onDateChange,
    coworker,
    onChangeCoworker,
    onCreateNewTask,
    disabledSelectedDate
  } = props;
  const { canSubmit, isCreateTaskForbidden } = useUserPermissions();
  const { selectedStore } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeesOptions, setEmployeesOptions] = useState<SelectOption[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [coWorkerApiError, setCoWorkerApiError] = useState<string>();

  useEffect(
    function fetchCoworkers() {
      if (!selectedStore) return;

      setPending(true);
      setIsLoading(true);
      setCoWorkerApiError('');
      const cancelToken = getCancelTokenSource();

      getEmployeeList(selectedStore, cancelToken.token)
        .then(({ employeeInfoList }) => {
          setCoWorkerApiError('');
          setEmployeesOptions(makeEmployeesOptions(employeeInfoList));
        })
        .catch(() => {
          setCoWorkerApiError('The values cannot be retrieved at the moment');
        })
        .finally(() => {
          setPending(false);
          setIsLoading(false);
        });

      return () => cancelToken.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStore]
  );

  const canCreateTask = canSubmit && !isCreateTaskForbidden;
  return (
    <div className={styles.toolbar}>
      <div>
        {/*<Select
          className={clsx(styles.select, styles.modeSelect)}
          name="mode"
          value={(FilterMode.Day as unknown) as number}
          options={buildOptions(FilterMode)}
        />*/}
        <DatePicker
          className={styles.datePicker}
          name="date"
          placeholder="Select a date"
          onChange={onDateChange}
          value={selectedDate}
          format="eee, MMM dd, yyyy"
          disabled={disabledSelectedDate}
        />
        <Button
          variant="outlined"
          disabled={isSameDay(selectedDate, new Date())}
          onClick={() => onDateChange(new Date())}
        >
          Today
        </Button>
      </div>
      <div>
        <Label forInput="coworker" className={styles.coworkerLabel}>
          Filter by Coworker
        </Label>
        <div className={clsx(styles.select, styles.coworkerSelect)}>
          <Select
            name="coworker"
            classes={{
              selection: styles.selection
            }}
            clearIcon
            placeholder="Select Coworker"
            value={(coworker as unknown) as number}
            options={employeesOptions}
            disabled={pending}
            errorMessage={coWorkerApiError}
            onChange={onChangeCoworker}
            isLoading={isLoading}
          />
        </div>
        {canCreateTask && (
          <Button
            data-testid="createTask"
            className={styles.newTask}
            onClick={onCreateNewTask}
            disabled={!selectedStore}
            icon={<FontAwesomeIcon icon={faCalendarPlus} />}
          >
            Create New Task
          </Button>
        )}
      </div>
    </div>
  );
};
