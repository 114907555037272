import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './Footer.module.scss';

export const footerTestId = 'footerTestId';

export const Footer = ({ children }: PropsWithChildren<{}>) => {
  const { isRenderedByContainer } = window;

  return (
    <footer
      className={clsx(
        styles.footer,
        isRenderedByContainer && styles.inContainer
      )}
      data-testid={footerTestId}
    >
      <div className={styles.btnWrapper}>{children}</div>
    </footer>
  );
};
