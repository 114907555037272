import React, { useContext, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import styles from './FilterEvents.module.scss';
import calendarToolbarStyles from '../CalendarToolbar.module.scss';

import clsx from 'clsx';
import {
  Button,
  Checkbox,
  Popover,
  PopoverHeader
} from '@rentacenter/racstrap';
import { faSlidersH, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '../../../common/Chip/Chip';
import { EventTypeNames } from '../../NewEvent/EventDetails/EventDetailsForm/EventDetailsForm';
import { EventType } from '../../../common/EventType/EventType';
import { EventSource } from '../../../common/EventSource/EventSource';
import {
  EventSourceType,
  EventStatus
} from '../../../../domain/Calendar/CalendarEvent';
import {
  EventType as EventTypeEnum,
  EventStatusNames
} from '../../../../domain/Calendar/CalendarEvent';
import { Status } from '../../../common/EventStatus/EventStatus';
import {
  EventsDispatchContext,
  EventsStateContext,
  Filters
} from '../../../../context/events/EventsProvider';

export const eventFiltersBtnTestId = 'eventFiltersBtnTestId';
export const deSelectAllFiltersTestId = 'deSelectAllFiltersTestId';
export const cancelFilterBtnTestId = 'cancelFilterBtnTestId';
export const applyFilterBtnTestId = 'applyFilterBtnTestId';
export const filterPopupFooterTestId = 'filterPopupFooterTestId';
export const eventTypeListTestId = 'eventTypeListTestId';
export const eventStatusListTestId = 'eventStatusListTestId';
export const filterPopupSubHeaderTestId = 'filterPopupSubHeaderTestId';
export const eventSourceListTestId = 'eventSourceListTestId';
interface FilterEventsProps {
  disable?: boolean;
}

interface SubHeaderProps {
  title: string;
}

interface FooterProps {
  onCancel?: () => void;
  onApply?: () => void;
}

interface FilterEventListBase {
  onClick: (eventType: EventTypeEnum | EventStatus | EventSourceType) => void;
}
interface EventTypeListProps extends FilterEventListBase {
  selectedFilters: EventTypeEnum[];
}

interface EventStatusListProps extends FilterEventListBase {
  selectedFilters: EventStatus[];
  showDelete: boolean;
}

interface EventSourceListProps extends FilterEventListBase {
  selectedFilters: EventSourceType[];
}

const EventTypeList = ({ selectedFilters, onClick }: EventTypeListProps) => (
  <div className={styles.list} data-testid={eventTypeListTestId}>
    {Object.keys({
      ...EventTypeNames,
      [EventTypeEnum.Store]: 'Store Event'
    } as Partial<Record<EventTypeEnum, string>>).map((key, index) => {
      const eventTypeKeys = key as EventTypeEnum;
      const selected = selectedFilters.includes(eventTypeKeys);
      return (
        <Chip
          onClick={() => onClick(eventTypeKeys)}
          className={clsx(selected && calendarToolbarStyles.selected)}
          key={index}
        >
          <EventType
            type={eventTypeKeys}
            isSelected={selected}
            isFilterEvent
            iconClassName={calendarToolbarStyles.iconPadding}
          />
        </Chip>
      );
    })}
  </div>
);

const EventSourceList = ({
  selectedFilters,
  onClick
}: EventSourceListProps) => (
  <div className={styles.list} data-testid={eventSourceListTestId}>
    {Object.keys(EventSourceType).map((key, index) => {
      const eventSourceKeys = key as EventSourceType;
      const selected = selectedFilters.includes(eventSourceKeys);
      return (
        <Chip
          onClick={() => onClick(eventSourceKeys)}
          selected={selected}
          className={clsx(selected && calendarToolbarStyles.selected)}
          key={index}
        >
          <EventSource source={eventSourceKeys} />
        </Chip>
      );
    })}
  </div>
);

const FilterEventStatus = {
  [EventStatus.ToDo]: EventStatusNames[EventStatus.ToDo],
  [EventStatus.Completed]: EventStatusNames[EventStatus.Completed],
  [EventStatus.Canceled]: EventStatusNames[EventStatus.Canceled],
  [EventStatus.Deleted]: EventStatusNames[EventStatus.Deleted]
};
const EventStatusList = ({
  selectedFilters,
  onClick,
  showDelete
}: EventStatusListProps) => (
  <div className={styles.list} data-testid={eventStatusListTestId}>
    {Object.keys(FilterEventStatus).map((key, index) => {
      const eventStatusKeys = key as EventStatus;

      if (eventStatusKeys === EventStatus.Deleted && !showDelete) {
        return <></>;
      }

      const selected = selectedFilters.includes(eventStatusKeys);
      return (
        <Chip
          onClick={() => onClick(eventStatusKeys)}
          selected={selected}
          className={clsx(selected && calendarToolbarStyles[eventStatusKeys])}
          key={index}
        >
          <Status status={eventStatusKeys} />
        </Chip>
      );
    })}
  </div>
);

const SubHeader = ({ title }: SubHeaderProps) => (
  <div className={styles.subHeader} data-testid={filterPopupSubHeaderTestId}>
    {title}
  </div>
);

export const PopoverFooter = (props: FooterProps) => (
  <div className={styles.popoverFooter} data-testid={filterPopupFooterTestId}>
    <div className={styles.btnWrapper}>
      <Button
        className={styles.cancelBtn}
        size="small"
        variant="outlined"
        color="primary"
        onClick={props.onCancel}
        data-testid={cancelFilterBtnTestId}
      >
        Cancel
      </Button>
      <Button
        className={styles.applyBtn}
        size="small"
        onClick={props.onApply}
        data-testid={applyFilterBtnTestId}
      >
        Apply
      </Button>
    </div>
  </div>
);

export const FilterEvents = (props: FilterEventsProps) => {
  const { setFilters } = useContext(EventsDispatchContext);
  const { filters } = useContext(EventsStateContext);

  const { disable } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const initialFilterState = {
    EventType: [],
    EventStatus: [],
    EventSource: [],
    showDeletedEvents: false
  };
  const [selectedFilters, setSelectedFilters] = useState<Filters>(
    initialFilterState
  );
  const [open, setOpen] = useState(false);

  const handleClick = (event: any) => {
    setSelectedFilters({ ...filters });
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const alreadyExists = (filterName: string) =>
    selectedFilters.EventType.includes(filterName as EventTypeEnum) ||
    selectedFilters.EventStatus.includes(filterName as EventStatus) ||
    selectedFilters.EventSource.includes(filterName as EventSourceType);

  const toggleFilter = (filterName: string) => {
    if (selectedFilters.EventType.find(filter => filter === filterName)) {
      return {
        ...selectedFilters,
        EventType: selectedFilters.EventType.filter(
          filter => filter !== filterName
        )
      };
    }

    if (selectedFilters.EventStatus.includes(filterName as EventStatus)) {
      return {
        ...selectedFilters,
        EventStatus: selectedFilters.EventStatus.filter(
          filter => filter !== filterName
        )
      };
    }

    if (selectedFilters.EventSource.includes(filterName as EventSourceType)) {
      return {
        ...selectedFilters,
        EventSource: selectedFilters.EventSource.filter(
          filter => filter !== filterName
        )
      };
    }

    return initialFilterState;
  };

  const handleFilterItemClick = (
    eventType: EventTypeEnum | EventStatus | EventSourceType
  ) => {
    if (!alreadyExists(eventType)) {
      if (Object.values(EventStatus).includes(eventType as EventStatus)) {
        setSelectedFilters({
          ...selectedFilters,
          EventStatus: [
            ...selectedFilters.EventStatus,
            eventType as EventStatus
          ]
        });
      } else if (
        Object.values(EventTypeEnum).includes(eventType as EventTypeEnum)
      ) {
        setSelectedFilters({
          ...selectedFilters,
          EventType: [...selectedFilters.EventType, eventType as EventTypeEnum]
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          EventSource: [
            ...selectedFilters.EventSource,
            eventType as EventSourceType
          ]
        });
      }
    } else {
      setSelectedFilters(toggleFilter(eventType));
    }
  };

  const handleDeselectAll = () => {
    setSelectedFilters(initialFilterState);
  };

  const handleCancel = () => {
    setSelectedFilters(initialFilterState);
    setOpen(false);
  };

  const handleApplyFilter = () => {
    setFilters(selectedFilters);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Filters">
        <span>
          <Button
            data-testid={eventFiltersBtnTestId}
            onClick={handleClick}
            variant="outlined"
            disabled={disable}
            icon={<FontAwesomeIcon icon={faSlidersH} />}
            classes={{
              startIcon: calendarToolbarStyles.btnIcon
            }}
            className={calendarToolbarStyles.collpsibleButtons}
          >
            <span className={calendarToolbarStyles.buttonText}>Filters</span>
          </Button>
        </span>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ arrow: styles.arrow }}
        className={styles.popover}
      >
        <PopoverHeader onClose={() => setOpen(false)} title="Filters" />
        <div>
          <SubHeader title="Types of Events" />
          <EventTypeList
            onClick={handleFilterItemClick}
            selectedFilters={selectedFilters.EventType}
          />
          <div className={styles.inlineFilters}>
            <div className={styles.sourceListWrapper}>
              <SubHeader title="Source" />
              <EventSourceList
                onClick={handleFilterItemClick}
                selectedFilters={selectedFilters.EventSource}
              />
            </div>
            <div>
              <SubHeader title="Status" />
              <EventStatusList
                onClick={handleFilterItemClick}
                selectedFilters={selectedFilters.EventStatus}
                showDelete={selectedFilters.showDeletedEvents}
              />
            </div>
          </div>
          <div>
            <span
              className={styles.deselectAll}
              onClick={handleDeselectAll}
              data-testid={deSelectAllFiltersTestId}
            >
              <FontAwesomeIcon icon={faRedo} /> Deselect All Filters
            </span>

            <div className={styles.deletedCheckbox}>
              <SubHeader title="Deleted Events" />
              <Checkbox
                labelText="Show Deleted Events in Calendar"
                checked={selectedFilters.showDeletedEvents}
                onChange={event => {
                  setSelectedFilters({
                    ...selectedFilters,
                    EventStatus: selectedFilters.EventStatus.filter(
                      filter => filter !== EventStatus.Deleted
                    ),
                    showDeletedEvents: event.target.checked
                  });
                }}
              />
            </div>
          </div>
          <PopoverFooter onCancel={handleCancel} onApply={handleApplyFilter} />
        </div>
      </Popover>
    </>
  );
};
