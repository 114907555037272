import { client } from './client';

export const getTaskHistoryLog = async (taskId: string) => {
  return client(`tasks/${taskId}/history`, { method: 'GET' });
};

export const getEventHistoryLog = async (
  storeNumber: string,
  eventId: string
) => {
  return client(`stores/${storeNumber}/events/${eventId}/history`, {
    method: 'GET'
  });
};
