import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from '@rentacenter/racstrap';
import { format, isSameDay, parseISO } from 'date-fns';
import { CancelTokenSource } from 'axios';

import styles from './TasksList.module.scss';

import { Task, TaskStatus } from '../../../domain/Task/Task';
import { TasksColumn } from './TasksColumn/TasksColumn';
import { TasksToolbar } from './TasksToolbar/TasksToolbar';
import {
  TasksStateContext,
  TasksDispatchContext
} from '../../../context/tasks/TasksProvider';
import { StoreContext } from '../../../context/store/StoreProvider';
import { ApiError } from '../../common/ApiError/ApiError';
import { getCancelTokenSource } from '../../../api/client';
import { CreateTask } from '../CreateTask/CreateTask';

export const toDoColumnTestId = 'toDoColumnTestId';
export const canceledColumnTestId = 'canceledColumnTestId';
export const completedColumnTestId = 'completedColumnTestId';

const columnTestIds = {
  [TaskStatus.ToDo]: toDoColumnTestId,
  [TaskStatus.Cancelled]: canceledColumnTestId,
  [TaskStatus.Completed]: completedColumnTestId
};

type ListStatus = 'initial' | 'apiError' | 'success';

export const TasksList = () => {
  const { selectedStore } = useContext(StoreContext);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [coworker, setCoworker] = useState<string>('');
  const [tasksLoading, setTasksLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [disableModalClose, setDisableModalClose] = useState<boolean>(false);

  const { tasks, hasApiError } = useContext(TasksStateContext);
  const { fetchTasks } = useContext(TasksDispatchContext);
  const initialCallToken = useRef<CancelTokenSource>();

  const updateTasks = () => {
    if (!selectedStore) return;
    setTasksLoading(true);

    if (initialCallToken.current) {
      initialCallToken.current.cancel();
    }

    initialCallToken.current = getCancelTokenSource();

    fetchTasks(
      selectedStore,
      format(selectedDate, 'yyyy-MM-dd'),
      initialCallToken.current.token
    ).finally(() => {
      setTasksLoading(false);
    });
  };

  useEffect(() => {
    updateTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedStore]);

  const changeDate = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const createNewTask = () => {
    setShowCreateForm(true);
  };

  const handleClose = () => {
    setShowCreateForm(false);
  };

  const handleTaskCreated = (task: Task) => {
    if (isSameDay(selectedDate, parseISO(task.dueDate))) {
      updateTasks();
    }
  };

  const getStatus = (): ListStatus => {
    if (hasApiError) return 'apiError';
    if (tasks) return 'success';

    return 'initial';
  };

  const completedAndCancelledTasks = [
    ...tasks?.[TaskStatus.Completed].filter(task =>
      coworker ? task.assignee.userId === coworker : true
    ),
    ...tasks?.[TaskStatus.Cancelled].filter(task =>
      coworker ? task.assignee.userId === coworker : true
    )
  ];

  return (
    <div className={styles.tasksList}>
      <TasksToolbar
        disabledSelectedDate={tasksLoading}
        selectedDate={selectedDate}
        onDateChange={changeDate}
        coworker={coworker}
        onChangeCoworker={value => {
          // limitation from Select#value prop in racstrap
          // which is number and has -1 as reset value
          if (typeof value === 'number' && value === -1) {
            setCoworker('');
          } else {
            setCoworker(value);
          }
        }}
        onCreateNewTask={createNewTask}
      />
      {showCreateForm && (
        <Modal
          isOpen
          onClose={handleClose}
          title="Create new task"
          disableClose={disableModalClose}
          // Fix for Material-UI Uncaught RangeError on TimePicker in Dialog
          // See https://stackoverflow.com/questions/54133326/material-ui-uncaught-rangeerror-maximum-call-stack-size-exceeded
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          disableEnforceFocus
        >
          <CreateTask
            onCancel={handleClose}
            onSave={handleTaskCreated}
            onPending={setDisableModalClose}
          />
        </Modal>
      )}
      {
        {
          initial: null,
          apiError: (
            <ApiError
              className={styles.apiError}
              text="We are experiencing an internal server problem,
            please refresh the page or select another date"
            />
          ),
          success: (
            <div className={styles.columns}>
              <TasksColumn
                key={TaskStatus.ToDo}
                data-testid={columnTestIds[TaskStatus.ToDo]}
                loading={tasksLoading}
                tasks={tasks?.[TaskStatus.ToDo].filter(task =>
                  coworker ? task.assignee.userId === coworker : true
                )}
                status={TaskStatus.ToDo}
              />
              <TasksColumn
                key={TaskStatus.Completed}
                data-testid={columnTestIds[TaskStatus.Completed]}
                loading={tasksLoading}
                tasks={completedAndCancelledTasks}
                status={TaskStatus.Completed}
              />
            </div>
          )
        }[getStatus()]
      }
    </div>
  );
};
