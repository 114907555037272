import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '@rentacenter/racstrap';
import { compact } from 'lodash';
import { format } from 'date-fns';

import styles from './DeleteEvent.module.scss';

import {
  CalendarEvent,
  EventType,
  StoreEvent,
  formatTimeslot,
  EventStatusActionType
} from '../../../../domain/Calendar/CalendarEvent';
import { EventsDispatchContext } from '../../../../context/events/EventsProvider';
import { StoreContext } from '../../../../context/store/StoreProvider';
import { ModalConfirmation } from '../../../common/ModalConfirmation/ModalConfirmation';
import { EventTypeNames } from '../../NewEvent/EventDetails/EventDetailsForm/EventDetailsForm';
import { useEventSource } from '../../useEventSource';

export const deleteFormTestId = 'deleteForm';

export interface DeleteEventkProps {
  event: StoreEvent | CalendarEvent;
  open: boolean;
  onClose: (cancelled: boolean) => void;
}

export const DeleteEvent = (props: DeleteEventkProps) => {
  const { event, open, onClose } = props;

  const { selectedStore } = useContext(StoreContext);
  const { updateEventStatus } = useContext(EventsDispatchContext);

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors, formState } = useForm({
    mode: 'onSubmit',
    defaultValues: { reason: '' }
  });
  const { isDirty } = formState;

  const eventSource = useEventSource();

  const onSubmit = (data: { reason: string }) => {
    if (!selectedStore) return;
    setLoading(true);

    updateEventStatus(
      event.eventId,
      selectedStore,
      event.timeSlot.timeSlotId,
      EventStatusActionType.Delete,
      eventSource,
      data.reason
    ).finally(() => {
      setLoading(false);
      onClose(false);
    });
  };

  function eventInfo() {
    const dateString = format(new Date(event.eventDate), 'MMMM dd');
    const timeSlotString = formatTimeslot(event, 'h', 'h a');

    if (event.type === EventType.Store) {
      return compact([
        (event as StoreEvent).title,
        'Store Event',
        dateString,
        timeSlotString
      ]).join(', ');
    } else {
      const {
        customerInformation: { firstName, lastName }
      } = event as CalendarEvent;

      return compact([
        `${firstName} ${lastName}`,
        EventTypeNames[event.type],
        dateString,
        timeSlotString
      ]).join(', ');
    }
  }

  return (
    <ModalConfirmation
      open={open}
      title="Enter Reason for Deleting Event"
      description={
        <div className={styles.subtitle}>
          Please write the motive for deleting the event: <br />
          {eventInfo()}
          <form data-testid={deleteFormTestId}>
            <Controller
              name="reason"
              control={control}
              rules={{ required: "Can't be empty" }}
              render={({ ref, ...rest }) => (
                <Input
                  classes={{ label: styles.label }}
                  label="Reason for Deleting the Event:"
                  required
                  errorMessage={errors.reason?.message}
                  {...rest}
                />
              )}
            />
          </form>
        </div>
      }
      loading={loading}
      cancelButtonText="Cancel"
      confirmButtonText="Delete Event"
      confirmButtonProps={{ color: 'primary', disabled: !isDirty || loading }}
      onCancel={() => onClose(true)}
      onConfirm={handleSubmit(onSubmit)}
    />
  );
};
