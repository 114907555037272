import { EventType } from '../../../domain/Calendar/CalendarEvent';
import { withEventSubmit } from '../../common/EventForm/withEventSubmit';
import { BlockTimeEventForm } from './BlockTimeEventForm';
import { createEvent } from '../../../api/calendar';

export default withEventSubmit(
  BlockTimeEventForm,
  EventType.BlockTime,
  createEvent
);
