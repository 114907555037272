import React, { HTMLProps } from 'react';
import { Skeleton } from '@material-ui/lab';

import styles from './EventCardSkeleton.module.scss';

import { EventCardLayout } from '../EventCardLayout/EventCardLayout';

export const eventCardSkeletonTestId = 'eventCardSkeletonTestId';

export const EventCardSkeleton = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return (
    <EventCardLayout
      className={className}
      data-testid={eventCardSkeletonTestId}
      {...rest}
    >
      <div className={styles.title}>
        <Skeleton variant="text" height={20} animation="wave" />
      </div>
      <div className={styles.content}>
        <Skeleton variant="text" height={40} animation="wave" />
      </div>
      <div className={styles.footer}>
        <Skeleton variant="text" height={20} animation="wave" />
        <Skeleton variant="text" height={20} animation="wave" />
      </div>
    </EventCardLayout>
  );
};
