import { client } from './client';
import { NotePayload } from '../domain/Note/Note';

export const getNotes = async (parentId: string) => {
  return client(`notes`, { method: 'GET', params: { parentId } });
};

export const addNote = (payload: NotePayload) =>
  client(`notes`, {
    method: 'POST',
    body: payload
  });

export const replyNote = (payload: NotePayload, id: string) =>
  client(`notes/${id}/reply`, {
    method: 'POST',
    body: payload
  });
