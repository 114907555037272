import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './SelectableRow.module.scss';

interface Props {
  readonly selected: boolean;
  readonly onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
}

export function SelectableRow(props: PropsWithChildren<Props>) {
  const { selected, onClick, children } = props;
  return (
    <tr
      className={clsx(styles.selectableRow, selected && styles.selected)}
      onClick={onClick}
    >
      <td className={styles.checkCell}>
        {selected && <FontAwesomeIcon icon={faCheck} />}
      </td>
      {children}
    </tr>
  );
}
