import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@rentacenter/racstrap';

import { requiredErrorMessage } from '../constants';

interface TitleFieldProps {
  fieldName: string;
  disabled: boolean;
}

export const TitleField: FC<TitleFieldProps> = ({ fieldName, disabled }) => {
  const { control, errors, clearErrors } = useFormContext<any>();
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        maxLength: {
          value: 256,
          message: 'The title text is limited to a number of 256 characters.'
        },
        required: requiredErrorMessage
      }}
      render={({ onChange, value, name }, { invalid }) => (
        <Input
          name={name}
          value={value}
          label="Title"
          placeholder="Write an event title"
          size="large"
          invalid={invalid}
          errorMessage={errors?.title?.message}
          onChange={(e: any) => {
            clearErrors(fieldName);
            onChange(e);
          }}
          required
          disabled={disabled}
        />
      )}
    />
  );
};
