import React, { FC } from 'react';
import { Select, SelectProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';

import { TaskStatusNames } from '../../../../domain/Task/Task';
import { TaskFormFields } from '../../CreateTask/CreateTask';
import { buildOptions } from '../../../../utils/buildOptions';

export const TaskStatusSelect: FC<Partial<SelectProps>> = props => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={TaskFormFields.status}
      rules={{ required: true }}
      render={({ ref, ...rest }) => (
        <Select
          options={buildOptions(TaskStatusNames)}
          required={true}
          label="Status"
          placeholder="Select"
          size="large"
          {...rest}
          {...props}
        />
      )}
    />
  );
};
