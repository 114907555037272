import React, { FC } from 'react';
import { format } from 'date-fns';
import { Input } from '@rentacenter/racstrap';
import { Controller, useForm } from 'react-hook-form';

import styles from './EventCancel.module.scss';

import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';
import {
  CalendarEvent,
  EventType,
  StoreEvent
} from '../../../domain/Calendar/CalendarEvent';
import { isCalendarEvent, noOp } from '../../../utils/helper-functions';

export interface GoalFormData {
  reason: string;
}

interface EventCancelProps {
  open: boolean;
  event: CalendarEvent | StoreEvent;
  onCancel: () => void;
  pending: boolean;
  onConfirm: (reason: string) => void;
}

export const eventCancelFormTestId = 'eventCancelFormTestId';

export const EventCancel: FC<EventCancelProps> = ({
  event,
  open = true,
  onCancel = noOp,
  onConfirm = noOp,
  pending
}) => {
  const { control, handleSubmit, errors, formState } = useForm<GoalFormData>({
    mode: 'onSubmit',
    defaultValues: {
      reason: ''
    }
  });

  const { isDirty } = formState;

  const onSubmit = (formData: GoalFormData) => {
    onConfirm(formData.reason);
  };

  let cancelEventSubtitleText = '';
  if (event.type === EventType.Store) {
    const { title } = event as StoreEvent;
    cancelEventSubtitleText = `${title}, Store Event, `;
  } else if (isCalendarEvent(event)) {
    const {
      type,
      customerInformation: { firstName, lastName }
    } = event;
    cancelEventSubtitleText = `${firstName} ${lastName}, ${type}, `;
  }
  return (
    <ModalConfirmation
      open={open}
      title="Enter Reason for Canceling Event"
      description={
        <form id={eventCancelFormTestId}>
          <div className={styles.subtitle}>
            {event.eventDate && (
              <>
                {`Please write the reason for canceling the event:`}
                <br />
                {cancelEventSubtitleText}
                <span className={styles.eventDate}>{`${format(
                  new Date(event?.eventDate),
                  'MMMM dd'
                )}, ${format(
                  new Date(`${event?.eventDate} ${event.timeSlot.startTime}`),
                  'h a'
                )} - ${format(
                  new Date(`${event?.eventDate} ${event.timeSlot.endTime}`),
                  'h a'
                )}`}</span>
              </>
            )}
          </div>
          <div className={styles.reason}>
            <Controller
              control={control}
              name="reason"
              rules={{
                required: 'This field is required.',
                maxLength: {
                  value: 256,
                  message:
                    'The reason text is limited to a number of 256 characters.'
                }
              }}
              render={({ ref, onChange, ...rest }, { invalid }) => (
                <Input
                  label="Reason for Canceling the Event"
                  placeholder="Enter Reason"
                  required
                  disabled={pending}
                  invalid={invalid}
                  onChange={onChange}
                  errorMessage={errors?.reason?.message}
                  {...rest}
                />
              )}
            />
          </div>
        </form>
      }
      cancelButtonText="Back"
      confirmButtonText="Cancel Event"
      confirmButtonProps={{ color: 'primary', disabled: !isDirty || pending }}
      loading={pending}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
    />
  );
};
