import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@rentacenter/racstrap';
// import clsx from 'clsx';

// import styles from '../EventForm/EventFields.module.scss';

import { buildOptions } from '../../../utils/buildOptions';

interface RecurrentPeriodFieldProps {
  fieldName: string;
  disabled: boolean;
}

const RecurrencyPeriod = {
  1: '1 Month',
  3: '3 Months',
  6: '6 Months'
};

const ruleRequired = {
  required: 'This field is required'
};

export const RecurrentPeriodField: FC<RecurrentPeriodFieldProps> = ({
  fieldName,
  disabled
}) => {
  const { control, errors } = useFormContext<any>();
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={!disabled ? ruleRequired : {}}
      render={({ onChange, value, name }) => (
        <Select
          options={buildOptions(RecurrencyPeriod)}
          value={value}
          size="large"
          placeholder="Select Recurrent Period"
          name={name}
          onChange={onChange}
          label="Select Recurrent Period"
          disabled={disabled}
          errorMessage={!disabled ? errors?.period?.message : ''}
        ></Select>
      )}
    />
  );
};
