import { client } from './client';
import { appConfig } from '../config/app-config';
import { Task } from '../domain/Task/Task';
import { CancelToken } from 'axios';

export const getTasksForDay = (
  store: string,
  date: string,
  cancelToken: CancelToken
) => {
  const selectedDate = new Date(date);
  const totalDaySeconds = 86399;
  const offset = new Date().getTimezoneOffset();
  const startDateISO = new Date(
    selectedDate.setSeconds(selectedDate.getSeconds() + offset * 60)
  ).toISOString();

  const endDate = new Date(startDateISO);
  const endDateISO = new Date(
    endDate.setSeconds(endDate.getSeconds() + totalDaySeconds)
  ).toISOString();

  return client(`stores/${store}/tasks`, {
    method: 'GET',
    params: {
      startDate: startDateISO,
      endDate: endDateISO
    },
    cancelToken
  });
};

export const getTask = (taskId: string) =>
  client(`tasks/${taskId}`, {
    method: 'GET'
  });

export const getEmployeeList = (storeId: string, cancelToken?: CancelToken) =>
  client(
    `misc/employees/${storeId}`,
    { method: 'GET', cancelToken },
    appConfig.apiUrls.container
  );

export const createTask = (storeId: string, payload: Partial<Task>) =>
  client(`stores/${storeId}/tasks`, {
    method: 'POST',
    body: payload
  });

export const updateTask = (taskId: string, payload: Partial<Task>) =>
  client(`tasks/${taskId}`, { method: 'PUT', body: payload });

export const deleteTask = (taskId: string) =>
  client(`tasks/${taskId}`, { method: 'DELETE' });
