import React, { useContext, useEffect, useRef, useState } from 'react';

import { StoreContext } from '../../../context/store/StoreProvider';
import {
  useNewEventDispatch,
  useNewEventState
} from '../../../context/events/NewEventProvider';
import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';
import { SetStepProp } from './NewEvent';

export const StoreChange = ({ setStep }: SetStepProp) => {
  const {
    selectedStore,
    setSelectedStore,
    setAutoPreferences,
    saveToPreferences
  } = useContext(StoreContext);
  const { dirty } = useNewEventState();
  const { clearState } = useNewEventDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const initialStore = useRef<string>('');

  useEffect(() => {
    setAutoPreferences(false);
  }, [setAutoPreferences]);

  useEffect(
    function storeChanged() {
      if (!selectedStore || initialStore.current === selectedStore) return;

      if (dirty) {
        setShowConfirmation(true);
      } else {
        initialStore.current = selectedStore;
        saveToPreferences(selectedStore);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStore]
  );

  const cancel = () => {
    setSelectedStore && setSelectedStore(initialStore.current);
    setShowConfirmation(false);
  };

  const confirm = () => {
    if (!selectedStore) return;

    initialStore.current = selectedStore;
    saveToPreferences(selectedStore);
    setShowConfirmation(false);
    clearState();
    setStep(0);
  };

  return (
    <>
      <ModalConfirmation
        open={showConfirmation}
        title="Please confirm"
        description="Changing the store will result in losing all your event data. Are you sure you want to continue?"
        cancelButtonText="No"
        confirmButtonText="Yes, Change Store"
        confirmButtonProps={{ color: 'primary' }}
        onCancel={cancel}
        onConfirm={confirm}
      />
    </>
  );
};
