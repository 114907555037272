import React, { useState } from 'react';
import { Stepper } from '@rentacenter/racstrap';

import styles from './NewEvent.module.scss';

import { NewEventProvider } from '../../../context/events/NewEventProvider';
import { SelectCustomer } from './SelectCustomer/SelectCustomer';
import { SelectAgreement } from './SelectAgreement/SelectAgreement';
import { SelectItem } from './SelectItem/SelectItem';
import { EventDetails } from './EventDetails/EventDetails';
import { StoreChange } from './StoreChange';

export interface SetStepProp {
  readonly setStep: (step: number) => void;
}

const stepsTestId = 'stepsTestId';
export function NewEvent() {
  const [step, setStep] = useState<number>(0);

  const steps = [
    { title: 'Select Customer' },
    { title: 'Select Agreement' },
    { title: 'Select Item' },
    { title: 'Add Event Details' }
  ];

  return (
    <NewEventProvider>
      <StoreChange setStep={setStep} />
      <h1 className={styles.title}>Create New Event</h1>
      <Stepper
        data-testid={stepsTestId}
        className={styles.stepper}
        steps={steps}
        activeStep={step}
      />
      <div className={styles.body}>
        {step === 0 && <SelectCustomer setStep={setStep} />}
        {step === 1 && <SelectAgreement setStep={setStep} />}
        {step === 2 && <SelectItem setStep={setStep} />}
        {step === 3 && <EventDetails setStep={setStep} />}
      </div>
    </NewEventProvider>
  );
}
