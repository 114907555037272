import React, { FC, useEffect } from 'react';
import { TimePicker, TimePickerProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';

import { TaskFormFields } from '../../CreateTask/CreateTask';
import {
  addDays,
  differenceInCalendarDays,
  isBefore,
  isValid,
  parse
} from 'date-fns';

export function setDueTimeDate(referenceDate: Date, dueTime: Date) {
  return addDays(dueTime, differenceInCalendarDays(referenceDate, dueTime));
}

export function validateTaskTime(date: Date | string) {
  const dateObj =
    typeof date === 'string' ? parse(date, 'MM/dd/yyyy', new Date()) : date;

  if (!isValid(dateObj)) {
    return 'Invalid date';
  }

  if (isBefore(dateObj, new Date())) {
    return 'Cannot be in the past';
  }
}

export const TaskDueTime: FC<Partial<TimePickerProps>> = props => {
  const { control, errors, watch, setValue, trigger } = useFormContext();

  const dueDate: Date = watch(TaskFormFields.dueDate);
  const dueTime: Date = watch(TaskFormFields.dueTime);

  useEffect(() => {
    if (!dueDate || !dueTime) return;
    const newDueTime = setDueTimeDate(dueDate, dueTime);
    setValue(TaskFormFields.dueTime, newDueTime);

    if (dueTime && dueDate) {
      trigger(TaskFormFields.dueTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate]);

  return (
    <Controller
      control={control}
      name={TaskFormFields.dueTime}
      rules={{
        required: true,
        validate: validateTaskTime
      }}
      render={({ ref, onChange, ...rest }, { invalid }) => (
        <TimePicker
          required={true}
          label="Due Time"
          placeholder="08:00 AM"
          invalid={invalid}
          onChange={value => {
            const newDueTime = dueDate ? setDueTimeDate(dueDate, value) : value;
            onChange(newDueTime);
          }}
          errorMessage={errors[TaskFormFields.dueTime]?.message}
          {...rest}
          {...props}
        />
      )}
    />
  );
};
