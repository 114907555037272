import React, { HTMLProps, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Card } from '@rentacenter/racstrap';

import styles from './EventFormLayout.module.scss';

import { Footer } from '../../layout/footer/Footer';
import { CancelButton } from '../../Calendar/NewEvent/CancelButton';

export interface EventFormLayoutProps extends HTMLProps<HTMLDivElement> {
  title: string;
  sectionTitle: string;
  sectionDescription: string;
  saveButtonText: string;
  pending: boolean;
  children?: React.ReactNode;
  hasError: boolean;
  isDisabled: boolean;
}

export const createStoreEventTestId = 'createStoreEventTestId';

export function EventFormLayout({
  title,
  sectionTitle,
  sectionDescription,
  saveButtonText,
  pending,
  hasError,
  isDisabled,
  children
}: EventFormLayoutProps) {
  const onSave = useCallback(() => {
    document.querySelector<HTMLButtonElement>(`#ie11SubmitBtn`)?.click();
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <Card className={styles.card}>
          <div className={styles.sectionTitle}>{sectionTitle}</div>
          <div className={styles.sectionDescription}>{sectionDescription}</div>
          {children}
        </Card>
      </div>

      <Footer>
        <div>
          <CancelButton data-testid="cancelEventCreate" disabled={pending} />
        </div>
        <Button
          data-testid={createStoreEventTestId}
          color="primary"
          disabled={pending || hasError || isDisabled}
          type="submit"
          onClick={onSave}
          {...(pending && {
            icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          })}
        >
          {saveButtonText}
        </Button>
      </Footer>
    </>
  );
}
