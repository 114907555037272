import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';
import { noOp } from '../../../utils/helper-functions';
import { TimeSlotValue } from '../TimeSlotSelector/TimeSlotSelector';
import { TimeSlotField } from '../../common/TimeSlotField/TimeSlotField';

export interface FormData {
  timeSlot: TimeSlotValue;
}

interface EventReopenWithTimeSlotsProps {
  onCancel: () => void;
  onConfirm: (timeSlot: TimeSlotValue) => void;
  pending: boolean;
  timeSlot: TimeSlotValue;
}

export const formId = 'eventReopenWithTimeSlotsId';
export const fieldName = 'timeSlot';

export const EventReopenWithTimeSlots: FC<EventReopenWithTimeSlotsProps> = ({
  onCancel = noOp,
  onConfirm = noOp,
  pending,
  timeSlot
}) => {
  const [hasError, setHasError] = useState(false);
  const methods = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      timeSlot: {
        date: timeSlot.date,
        timeSlotId: timeSlot.timeSlotId
      }
    }
  });

  const { isDirty } = methods.formState;
  const { setError } = methods;

  useEffect(() => {
    setError(fieldName, {
      message:
        "Can't be Reopened, Timeslot Busy. To Reopen, please choose another timeslot."
    });
  }, [setError]);

  useEffect(() => {
    if (isDirty) {
      setError(fieldName, { message: '' });
    }
  }, [isDirty, setError]);

  const onSubmit = (formData: FormData) => {
    onConfirm(formData.timeSlot);
  };

  return (
    <ModalConfirmation
      open
      title="Reopen Event"
      description={
        <FormProvider {...methods}>
          <form id={formId}>
            <TimeSlotField
              fieldName={fieldName}
              onError={() => setHasError(true)}
              editMode
            />
          </form>
        </FormProvider>
      }
      cancelButtonText="Back"
      confirmButtonText="Confirm"
      confirmButtonProps={{
        color: 'primary',
        disabled: !isDirty || pending || hasError
      }}
      loading={pending}
      onCancel={onCancel}
      onConfirm={methods.handleSubmit(onSubmit)}
    />
  );
};
