import React from 'react';

import styles from './NoStoreSelected.module.scss';

import noStore from './no-store-selected.png';

import { appConfig } from '../../../config/app-config';

export const NoStoreSelected = () => {
  return (
    <div className={styles.noStoreSelected}>
      <img src={`${appConfig.microUrl}${noStore}`} alt="No Store Selected" />
      <h1>No Store Selected</h1>
      <span>Please select one of your stores to view the daily activities</span>
    </div>
  );
};
