/* eslint-disable */
/* eslint-disable prettier/prettier */
import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { compact } from 'lodash';
import styles from './AcimaEventCard.module.scss';
import {
    CalendarEvent,
    EventStatus
} from '../../../../domain/Calendar/CalendarEvent';
import { EventType } from '../../../common/EventType/EventType';
import { EventSource } from '../../../common/EventSource/EventSource';
import { CBStatus } from '../../CBStatus/CBStatus';
import { EventCardLayout } from '../EventCardLayout/EventCardLayout';

const cardColors: Partial<Record<EventStatus, string>> = {
    [EventStatus.ToDo]: styles.todo,
    [EventStatus.Completed]: styles.completed,
    [EventStatus.Canceled]: styles.canceled,
    [EventStatus.Deleted]: styles.deleted
};

interface Props extends HTMLProps<HTMLDivElement> {
    event: CalendarEvent;
}

export const AcimaEventCard = (props: Props) => {
    console.log("AcimaEvent", props.event);

    const { event, className, ...rest } = props;
    // const { customerInformation } = event;
    // const { address, firstName, lastName } = customerInformation || {};

    return (
        <EventCardLayout
            className={clsx(className, event && cardColors[event.status])}
            {...rest}
        >
            <div className={styles.customer}>
                <CBStatus status={event.status} className={styles.statusIcon} />
                {event?.additionalDetails?.customerInfo?.firstName} {event?.additionalDetails?.customerInfo?.lastName}
            </div>

            <div className={styles.address}>
                {compact([
                    event?.additionalDetails?.customerInfo?.addressLine1,
                    event?.additionalDetails?.customerInfo?.city,
                    event?.additionalDetails?.customerInfo?.state,
                    event?.additionalDetails?.customerInfo?.zip
                ]).join(', ')}
            </div>
            <div className={styles.footer}>
                <EventType type={event.type} className={styles.type} />
                <EventSource source={event.source} className={styles.source} />
            </div>
        </EventCardLayout>
    );
};

