export enum GovernmentIdType {
  DriverLicense = 'DL',
  State = 'ST'
}

export enum AddressType {
  Primary = 'Primary',
  Mailing = 'Mailing',
  Delivery = 'Delivery'
}

export interface Customer {
  readonly partyId: string;
  readonly addresses: CustomerAddress[];
  readonly oktaId: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly dateOfBirth: string | null;
  readonly storeId: string;
  readonly storeNumber: string;
  readonly agreementCount: number | null;
  readonly agreementCountByStore: number | null;
  readonly recentAgreementCreated: string | null;
}

export interface CustomerDetailsDto extends Customer {
  readonly email: string;
  readonly governmentId: string;
  readonly governmentIdTypeRefCode: string;
  readonly issuedStateCode: string;
  readonly phoneNumbers: CustomerPhoneDto[];
}

export interface CustomerCreateDto {
  readonly customerDetails?: CustomerCreateDetailsDto;
  readonly primaryAddress: CustomerAddressDto;
  readonly mailingAddressSameAsPrimary?: boolean;
  readonly mailingAddress?: CustomerAddressDto;
  readonly customerPhones?: CustomerPhoneDto[];
}

export interface CustomerCreateDetailsDto {
  readonly firstName: string;
  readonly lastName: string;
  readonly dateOfBirth: string;
  readonly emailAddress: string;
  readonly storeNumber: string;
  readonly governmentIdNumber: string;
  readonly governmentIdTypeRefCode: string;
  readonly idIssuingStateAbbreviation: string;
}

export interface CustomerPhoneOptional {
  readonly extension?: string;
  readonly validated?: string;
  readonly bestTimeToCallRefCode?: string;
  readonly isPrimary?: string;
  readonly phoneInstructions?: string;
  readonly phoneTypeRefCode?: string;
  readonly active?: string;
}

export interface CustomerPhoneDto {
  readonly extension?: string;
  readonly phoneNumber: string;
  readonly validated?: string;
  readonly bestTimeToCallRefCode?: string;
  readonly isPrimary?: string;
  readonly phoneInstructions?: string;
  readonly phoneTypeRefCode?: string;
  readonly doNotCallNumber: string;
  readonly active: string;
}

export interface CustomerAddress {
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  stateCode: string;
  postalCode: string;
  country: string;
  primary: boolean;
  active: boolean;
}

export interface CustomerAddressWithIndex extends CustomerAddress {
  index: number;
}

export interface CustomerAddressDto {
  readonly poBox?: string;
  readonly apartmentUnitNumber?: string;
  readonly city: string;
  readonly postalCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly stateProvinceAbbreviation: string;
}

export interface CustomerSearchParams {
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  activeAgreements?: string;
  includeAgreements?: string;
}
