import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { format, isBefore, isSameHour } from 'date-fns';
import clsx from 'clsx';

import styles from './TimeSlotRow.module.scss';
import cardStyles from '../EventCard/EventCardLayout/EventCardLayout.module.scss';

import { TimeSlot } from '../../../domain/Calendar/Timeslot';
import { ActiveTimeSlot } from '../Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHamburger,
  faChevronRight,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

enum ScrollDirection {
  right = 1,
  left = -1
}

const { cardWidth, cardMargin } = cardStyles;
const cardSpacing = parseInt(cardWidth, 10) + parseInt(cardMargin, 10);

export const lunchDisplayTestId = 'lunchDisplayTestId';
export const timeSlotRowTestId = 'timeSlotRowTestId';

export interface TimeslotProps {
  interval: TimeSlot;
  activeTimeSlot?: ActiveTimeSlot;
  last?: boolean;
}

// eslint-disable-next-line
export const TimeSlotRow = (props: PropsWithChildren<TimeslotProps>) => {
  const { interval, last, children, activeTimeSlot } = props;
  const cardsList = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [cardsOnRight, setCardsOnRight] = useState(0);
  const [cardsOnLeft, setCardsOnLeft] = useState(0);

  const displayedTime = last ? interval.endTime : interval.startTime;
  const title = format(displayedTime, 'h a');

  const isActive =
    activeTimeSlot &&
    activeTimeSlot.index !== -1 &&
    (isSameHour(displayedTime, activeTimeSlot?.timeslot.startTime) ||
      isSameHour(displayedTime, activeTimeSlot?.timeslot.endTime));

  const isPast =
    activeTimeSlot &&
    isBefore(interval.startTime, activeTimeSlot?.timeslot.startTime);

  const getItemsPerPage = () => {
    if (!cardsList.current) {
      return 0;
    }

    return Math.floor(cardsList.current?.clientWidth / cardSpacing);
  };

  const scroll = (direction: ScrollDirection) => () => {
    const nextPage = currentPage + direction;
    setCurrentPage(nextPage);
    const position = nextPage * getItemsPerPage() * cardSpacing;
    cardsList.current?.scrollTo({
      left: position,
      behavior: 'smooth'
    });
  };

  useEffect(
    function updateCardsCountOnArrows() {
      const cardList = cardsList.current;

      if (!cardList) {
        return;
      }
      const itemsPerPage = getItemsPerPage();
      const cardsCount = cardsList.current?.childElementCount || 0;
      const pagesCount = Math.ceil(cardsCount / itemsPerPage);

      const isLastPage = currentPage === pagesCount - 1;

      const cardsScrolled = currentPage * itemsPerPage;

      // on the last screen, not all cards from previous
      // screen will leave the screen
      const cardsLeftOnScreen = isLastPage
        ? itemsPerPage - (cardsCount - cardsScrolled)
        : 0;

      const cardsOnLeft = cardsScrolled - cardsLeftOnScreen;
      const cardsOnRight = cardsCount - cardsOnLeft - itemsPerPage;

      setCardsOnRight(cardsOnRight);
      setCardsOnLeft(cardsOnLeft);
    },
    [children, currentPage, cardsList]
  );

  return (
    <div
      className={clsx(
        styles.timeslot,
        last && styles.last,
        isActive && styles.active,
        interval.lunchBreak && styles.lunch
      )}
      data-testid={timeSlotRowTestId}
    >
      <div className={styles.title}>{title}</div>

      {interval.lunchBreak ? (
        <div className={styles.lunchCard} data-tesid={lunchDisplayTestId}>
          <FontAwesomeIcon className={styles.burger} icon={faHamburger} />
          Lunch Time!
        </div>
      ) : (
        !last && (
          <>
            {cardsOnRight > 0 && (
              <div className={clsx(styles.arrowBkg, styles.arrowBkgRight)}>
                <div
                  className={clsx(styles.arrow, styles.arrowRight)}
                  onClick={scroll(ScrollDirection.right)}
                >
                  <FontAwesomeIcon icon={faChevronRight} /> {cardsOnRight}
                </div>
              </div>
            )}
            {cardsOnLeft > 0 && (
              <div className={styles.arrowBkg}>
                <div
                  className={styles.arrow}
                  onClick={scroll(ScrollDirection.left)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} /> {cardsOnLeft}
                </div>
              </div>
            )}

            <div
              className={clsx(styles.cards, isPast && styles.past)}
              ref={cardsList}
            >
              {children}
            </div>
          </>
        )
      )}
    </div>
  );
};
