import { EventSourceType } from '../Calendar/CalendarEvent';

export enum ParentType {
  Task = 'task',
  Event = 'event'
}

export interface TaskLog {
  actionDate: string;
  actionName: string;
  id: string;
  taskId: string;
  userId: string;
  userName: string;
}

export interface EventLog {
  id: string;
  eventAction: string;
  eventDateTime: string;
  eventInstruction?: string;
  eventSource: EventSourceType;
  userFirstName: string;
  userLastName: string;
}
