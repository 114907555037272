import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import styles from './Main.module.scss';

import { EventsProvider } from '../../../context/events/EventsProvider';
import { DapRoute } from '../../../config/route-config';
import { Calendar } from '../../Calendar/Calendar';
import { NewEvent } from '../../Calendar/NewEvent/NewEvent';
import NewStoreEventForm from '../../Calendar/StoreEvent/NewStoreEvent';
import BlockTimeEventForm from '../../Calendar/BlockTimeEventForm';

export const CalendarRoutes = () => {
  const history = useHistory();

  return (
    <EventsProvider>
      <Switch>
        <Route exact path={DapRoute.Calendar}>
          <div className={styles.fullview}>
            <Calendar />
          </div>
        </Route>

        <Route exact path={DapRoute.CalendarNewEvent}>
          <NewEvent />
        </Route>

        <Route exact path={DapRoute.CalendarNewStoreEvent}>
          <NewStoreEventForm onSave={() => history.push(DapRoute.Calendar)} />
        </Route>

        <Route exact path={DapRoute.CalendarNewBlockEvent}>
          <BlockTimeEventForm onSave={() => history.push(DapRoute.Calendar)} />
        </Route>
        <Route>
          <Redirect to={DapRoute.Calendar} />
        </Route>
      </Switch>
    </EventsProvider>
  );
};
