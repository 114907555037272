/* eslint-disable */
import React, { HTMLProps } from 'react';

import {
  BaseEvent,
  CalendarEvent,
  EventType,
  StoreEvent
} from '../../../domain/Calendar/CalendarEvent';
import { StoreEventCard } from './StoreEventCard/StoreEventCard';
import { CustomerEventCard } from './CustomerEventCard/CustomerEventCard';
import { AcimaEventCard } from './AcimaEventCard/AcimaEventCard';
import { EventCardSkeleton } from './EventCardSkeleton/EventCardSkeleton';
import { BlockTimeEventCard } from './BlockTimeEventCard/BlockTimeEventCard';

export const eventCardTestId = 'eventCardTestId';

export interface EventCardProps extends HTMLProps<HTMLDivElement> {
  event?: CalendarEvent | StoreEvent;
}

export const EventCard = ({ event, className, ...rest }: EventCardProps) => {
  if (!event) {
    return <EventCardSkeleton />;
  }

  switch (event.type) {
    case EventType.Store:
      return (
        <StoreEventCard
          data-testid={eventCardTestId}
          event={event as StoreEvent}
          className={className}
          {...rest}
        />
      );
    case EventType.BlockTime:
      return (
        <BlockTimeEventCard
          data-testid={eventCardTestId}
          event={event as BaseEvent}
          className={className}
          {...rest}
        />
      );
    case EventType.AcimaDelivery:
      return (
        <AcimaEventCard
          data-testid={eventCardTestId}
          event={event as any}
          className={className}
          {...rest}
        />
      );
    default:
      // not so good default
      return (
        <CustomerEventCard
          data-testid={eventCardTestId}
          event={event as CalendarEvent}
          className={className}
          {...rest}
        />
      );
  }
};
