import React, { HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import styles from './NoItems.module.scss';

export const noItemsTestId = 'noItemsTestId';

export const NoItems = (props: HTMLProps<HTMLDivElement>) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={clsx(styles.noItems, className)}
      data-testid={noItemsTestId}
      {...rest}
    >
      <FontAwesomeIcon icon={faSearch} /> {children}
    </div>
  );
};
