import React, { HTMLProps } from 'react';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';

import styles from './NoteCard.module.scss';

import { Note } from '../../../../domain/Note/Note';
import { User } from '../../../../domain/User/User';

export const noteCardTestId = 'noteCardTestId';

export interface NoteCardProps extends HTMLProps<HTMLDivElement> {
  note?: Note;
  currentUser?: User;
  handleReplyNote?: () => void;
  readonly?: boolean;
}

export const NoteCard = (props: NoteCardProps) => {
  const { note, currentUser, handleReplyNote, readonly, ...rest } = props;
  return (
    <>
      <div
        className={clsx(
          styles.noteCard,
          currentUser?.email === note?.userInfo.email && styles.ownNote
        )}
        data-testid={noteCardTestId}
        {...rest}
      >
        <div className={styles.title}>
          {note ? (
            <>
              <div className={styles.name}>
                {note.userInfo.firstName} {note.userInfo.lastName}
              </div>
              <div className={styles.date}>
                {format(parseISO(note.createdAt), 'MM/dd/yy')}
              </div>
            </>
          ) : (
            <Skeleton
              variant="text"
              height={20}
              style={{ width: '100%' }}
              animation="wave"
            />
          )}
        </div>

        {note ? (
          note.content
        ) : (
          <>
            <Skeleton variant="text" height={20} animation="wave" />
            <Skeleton variant="text" height={20} animation="wave" />
          </>
        )}
      </div>
      <div className={styles.replySection}>
        {!readonly && (
          <span onClick={handleReplyNote}>
            <FontAwesomeIcon icon={faReply} /> Reply
          </span>
        )}
      </div>
    </>
  );
};
