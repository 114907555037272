import React, { HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { SelectionTable } from '../SelectionTable/SelectionTable';
import { useNewEventState } from '../../../../context/events/NewEventProvider';
import { Item } from '../../../../domain/Customer/Item';

import styles from '../SelectItem/SelectItem.module.scss';

export const selectedItemsTestId = 'selectedItemsTestId';

export const SelectedItems = (props: HTMLProps<HTMLDivElement>) => {
  const { ...rest } = props;
  const { selectedItems } = useNewEventState();

  return (
    <div
      data-testid={selectedItemsTestId}
      className={styles.itemsListTable}
      {...rest}
    >
      <SelectionTable>
        <thead>
          <tr>
            <th scope="col" data-check-cell></th>
            <th scope="col">Item #</th>
            <th scope="col">Item Description</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems?.map((selectedItem: Item, index: number) => (
            <tr key={index + 1}>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>{selectedItem.inventoryNumber}</td>
              <td>{selectedItem.description || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </SelectionTable>
    </div>
  );
};
