export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export enum ParentType {
  Task = 'task',
  Event = 'event'
}

export interface NotePayload {
  parentId: string;
  parentType: ParentType;
  content: string;
}

export interface Note {
  id: string;
  userInfo: UserInfo;
  createdAt: string;
  parentId: string;
  content: string;
}
