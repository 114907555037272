import clsx from 'clsx';
import { format, isPast, isSameDay } from 'date-fns';
import React, { HTMLProps } from 'react';
import { TimeSlot } from '../../../../domain/Calendar/Timeslot';

import styles from './TimeSlotColumn.module.scss';

import { TimeSlotStatus } from '../../../../domain/Calendar/CalendarEvent';

const timeFormat = 'h a';

export const timeSlotColumnTestId = 'timeSlotColumnTestId';

export interface TimeSlotColumnProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onSelect'> {
  timeslots: TimeSlot[];
  day: Date;
  onSelect: (timeSlot: TimeSlot) => void;
  selectedTimeSlot?: TimeSlot;
  selectedDate?: Date;
}

export const TimeSlotColumn = (props: TimeSlotColumnProps) => {
  const {
    day,
    className,
    timeslots,
    onSelect,
    selectedTimeSlot,
    selectedDate,
    ...rest
  } = props;

  const isSelected = (timeSlot: TimeSlot) => {
    if (!selectedDate || !selectedTimeSlot) {
      return false;
    }

    return selectedTimeSlot.id === timeSlot.id && isSameDay(selectedDate, day);
  };

  const isBlocked = (timeSlot: TimeSlot) =>
    timeSlot.status === TimeSlotStatus.Blocked;

  const isBusy = (timeSlot: TimeSlot) =>
    timeSlot.status === TimeSlotStatus.Busy;

  const isAvailable = (timeSlot: TimeSlot) =>
    !isPast(timeSlot.endTime) && !isBlocked(timeSlot) && !isBusy(timeSlot);

  const isStoreClosed = !timeslots.length;

  return (
    <div
      className={clsx(styles.timeSlotColumn, className)}
      data-testid={timeSlotColumnTestId}
      {...rest}
    >
      <div className={styles.day}>{format(day, 'EEE, LLL dd')}</div>
      {isStoreClosed ? (
        <div className={clsx(styles.timeSlotEntry, styles.closed)}>
          Store Closed
        </div>
      ) : (
        timeslots.map(timeSlot => (
          <div
            key={timeSlot.id}
            data-status={timeSlot.status}
            className={clsx(
              styles.timeSlotEntry,
              isSelected(timeSlot) && styles.selected,
              isPast(timeSlot.endTime) && styles.disabled,
              isPast(timeSlot.endTime) &&
                isSelected(timeSlot) &&
                styles.disabledSelected,
              isBusy(timeSlot) && styles.disabled,
              isBusy(timeSlot) &&
                isSelected(timeSlot) &&
                styles.disabledSelected,
              isBlocked(timeSlot) && styles.blocked,
              isSelected(timeSlot) &&
                isBlocked(timeSlot) &&
                styles.blockSelected
            )}
            onClick={() => isAvailable(timeSlot) && onSelect(timeSlot)}
          >
            {format(timeSlot.startTime, timeFormat)} -{' '}
            {format(timeSlot.endTime, timeFormat)}
          </div>
        ))
      )}
    </div>
  );
};
