import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectOption } from '@rentacenter/racstrap';
import clsx from 'clsx';

import styles from './CreateTask.module.scss';

import { Task, TaskPriority, TaskStatus } from '../../../domain/Task/Task';
import { StoreContext } from '../../../context/store/StoreProvider';
import { TasksDispatchContext } from '../../../context/tasks/TasksProvider';
import { FormActions } from '../TaskForm/FormActions';
import { TaskTitle } from '../TaskForm/TaskTitle/TaskTitle';
import { TaskCategorySelect } from '../TaskForm/TaskCategorySelect/TaskCategorySelect';
import { TaskPrioritySelect } from '../TaskForm/TaskPrioritySelect/TaskPrioritySelect';
import { TaskDueDate } from '../TaskForm/TaskDueDate/TaskDueDate';
import { TaskDueTime } from '../TaskForm/TaskDueTime/TaskDueTime';
import { TaskDescription } from '../TaskForm/TaskDescription/TaskDescription';
import { TaskAssigneesSelect } from '../TaskForm/TaskAssigneesSelect/TaskAssigneesSelect';
import { noOp } from '../../../utils/helper-functions';

export const createTaskFormId = 'createTaskFormId';

export interface TaskFormData {
  title: string;
  category: string;
  assignee: string;
  priority: string;
  taskStatus: string;
  dueDate: Date;
  dueTime: Date;
  description?: string;
}

export enum TaskFormFields {
  title = 'title',
  category = 'category',
  assignee = 'assignee',
  priority = 'priority',
  status = 'taskStatus',
  dueDate = 'dueDate',
  dueTime = 'dueTime',
  description = 'description'
}

interface CreateTaskFormProps {
  onCancel?: () => void;
  onSave?: (task: Task) => void;
  onPending?: (pending: boolean) => void;
}

export const getEmptyValidationMessage = (isValid: boolean) =>
  isValid ? ' ' : undefined;

// eslint-disable-next-line
export const CreateTask = (props: CreateTaskFormProps) => {
  const {
    onCancel: handleClose = noOp,
    onSave = noOp,
    onPending = noOp
  } = props;
  const { selectedStore } = useContext(StoreContext);
  const [assignee, setAssignee] = useState<SelectOption>();
  const [pending, setPending] = useState<boolean>(false);
  const { createTask } = useContext(TasksDispatchContext);

  const formMethods = useForm<TaskFormData>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      category: '',
      assignee: '',
      priority: '',
      dueDate: '',
      dueTime: '',
      taskStatus: '',
      description: ''
    }
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: TaskFormData) => {
    if (!selectedStore) return;
    setPending(true);
    onPending && onPending(true);

    const payload = {
      title: data.title,
      category: data.category,
      assignee: {
        userId: assignee?.value,
        name: assignee?.label
      },
      priority: data.priority as TaskPriority,
      taskStatus: data.taskStatus as TaskStatus,
      dueDate: data.dueTime.toISOString(),
      description: data.description?.trim()
    } as Task;

    createTask(selectedStore, payload)
      .then((response: Task) => {
        handleClose();
        onSave(response);
      })
      .finally(() => {
        setPending(false);
        onPending(false);
      });
  };

  return (
    <div className={clsx(styles.container)}>
      <FormProvider {...formMethods}>
        <form
          id={createTaskFormId}
          data-testid={createTaskFormId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={clsx(styles.row, styles.split)}>
            <div className={styles.left}>
              <TaskTitle disabled={pending} />
            </div>
            <div className={styles.right}>
              <TaskCategorySelect disabled={pending} />
            </div>
          </div>
          <div className={clsx(styles.row, styles.split)}>
            <div className={styles.left}>
              <TaskAssigneesSelect onChange={setAssignee} disabled={pending} />
            </div>
            <div className={styles.right}>
              <TaskPrioritySelect disabled={pending} />
            </div>
          </div>
          <div className={clsx(styles.row, styles.split)}>
            <div className={styles.left}>
              <TaskDueDate disabled={pending} />
            </div>
            <div className={styles.right}>
              <TaskDueTime disabled={pending} />
            </div>
          </div>
          <div className={styles.row}>
            <TaskDescription disabled={pending} />
          </div>

          <div className={clsx(styles.buttons)}>
            <FormActions
              form={createTaskFormId}
              pending={pending}
              onClose={handleClose}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
