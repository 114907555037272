import React, { HTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { SelectionTable } from '../SelectionTable/SelectionTable';
import { useNewEventState } from '../../../../context/events/NewEventProvider';
import { format, parseISO } from 'date-fns';

export const selectedAgreementTestId = 'selectedCustomerTestId';

export const SelectedAgreement = (props: HTMLProps<HTMLDivElement>) => {
  const { ...rest } = props;
  const { selectedAgreement } = useNewEventState();

  return (
    <div data-testid={selectedAgreementTestId} {...rest}>
      <SelectionTable>
        <thead>
          <tr>
            <th scope="col" data-check-cell></th>
            <th scope="col">Agreement Number</th>
            <th scope="col">Date Created</th>
            <th scope="col">Status</th>
            <th scope="col">Description</th>
            <th scope="col">Total Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <FontAwesomeIcon icon={faCheck} />
            </td>
            <td>{selectedAgreement?.agreementNumber}</td>
            <td>
              {selectedAgreement && selectedAgreement?.agreementOpenDate
                ? format(
                    parseISO(selectedAgreement?.agreementOpenDate),
                    'MM/dd/yyyy'
                  )
                : 'N/A'}
            </td>
            <td>{selectedAgreement?.agreementStatus.description}</td>
            <td>{selectedAgreement?.description || 'N/A'}</td>
            <td>${selectedAgreement?.agreementTotalCost}</td>
          </tr>
        </tbody>
      </SelectionTable>
    </div>
  );
};
