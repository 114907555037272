import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import styles from './CBStatus.module.scss';

import {
  EventStatus,
  cancelled,
  completed,
  deleted
} from '../../../domain/Calendar/CalendarEvent';

export const cbStatusTestId = 'cbStatusTestId';

export interface CbStatusProps {
  status: EventStatus;
  className?: string;
}

// CB = Color Blind
export const CBStatus = ({ status, className }: CbStatusProps) => {
  return (
    <>
      {completed(status) && (
        <FontAwesomeIcon
          className={clsx(styles.completedIcon, className)}
          icon={faCheck}
        />
      )}
      {cancelled(status) && (
        <FontAwesomeIcon
          className={clsx(styles.canceledIcon, className)}
          icon={faTimes}
        />
      )}
      {deleted(status) && (
        <FontAwesomeIcon
          className={clsx(styles.deletedIcon, className)}
          icon={faTrash}
        />
      )}
    </>
  );
};
