import { ToastType, useToastActionsContext } from '@rentacenter/racstrap';
import { format, addMonths } from 'date-fns';
import React, { useContext, useState } from 'react';
import { EventsDispatchContext } from '../../../context/events/EventsProvider';
import { StoreContext } from '../../../context/store/StoreProvider';
import {
  BlockTimeEventRequest,
  CalendarEvent,
  EventType,
  StoreEventRequest
} from '../../../domain/Calendar/CalendarEvent';
import { AxiosError } from 'axios';
import { APIError, getErrorMessage } from '../../../utils/errorHandlerService';

const DATEFORMAT = 'MM/dd/yyyy';

//todo, update payload
export const createPayload = (eventType: string, formData: any) => {
  const payload: any = {
    type: eventType,
    eventDate: format(formData.timeSlot.date, DATEFORMAT),
    timeSlot: {
      timeSlotId: formData.timeSlot.timeSlotId
    },
    eventInstruction: formData.description,
    // TODO: robert.gyori: Aligned with Flavia and Aakash and we decided to set requiredCoworkers to 0 for blocked time event
    // because the RAC backend is currently expecting the field to be present, but requiredCoworkers is not part of block time event.
    requiredCoworkers: '0'
  };

  if (eventType === EventType.Store) {
    payload.title = formData.title;
    payload.requiredCoworkers = formData.requiredCoworkers;
  } else if (eventType === EventType.BlockTime && formData.period) {
    const endDate = addMonths(formData.timeSlot.date, formData.period);
    payload.startDate = format(formData.timeSlot.date, DATEFORMAT);
    payload.endDate = format(endDate, DATEFORMAT);
  }

  return payload;
};

interface EventSubmitProps {
  // notice that the create operation doesn't require the event to be present
  // but we need the eventId for requests where we are altering an existing event
  onSave: () => void;
  event?: { eventId: string };
  onCancel?: () => void;
}
export const withEventSubmit = (
  WrappedComponent: any,
  eventType: EventType,
  api: (
    storeId: string,
    payload: Partial<CalendarEvent> | StoreEventRequest | BlockTimeEventRequest,
    eventId: string
  ) => Promise<any>
) => {
  return function Wrapper(props: EventSubmitProps) {
    const [pending, setPending] = useState<boolean>(false);
    const { selectedStore } = useContext(StoreContext);
    const { showToast } = useToastActionsContext();
    const { setSelectedDate } = useContext(EventsDispatchContext);

    const onSubmit = (formData: any) => {
      if (!selectedStore) return;
      setPending(true);

      const { eventId } = props.event || {};

      const payload: any = createPayload(eventType, formData);
      // console.log('payload', payload);
      api(selectedStore, payload, eventId || '')
        .then(() => {
          showToast(
            ToastType.Success,
            `Your Event has been successfully ${
              eventId ? 'updated' : 'created'
            }!`
          );

          setSelectedDate(formData.timeSlot.date);

          props?.onSave();
        })
        .catch((error: AxiosError<APIError>) => {
          const errorMessage = getErrorMessage(error.response);
          const message = errorMessage
            ? errorMessage
            : 'Something went wrong when trying to add your Event, please try again!';
          showToast(ToastType.Error, message);
          window.scrollTo(0, 0);
        })
        .finally(() => {
          setPending(false);
        });
    };

    return (
      <WrappedComponent pending={pending} onSubmit={onSubmit} {...props} />
    );
  };
};
