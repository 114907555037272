import { client } from './client';
import { appConfig } from '../config/app-config';
import { User } from '../domain/User/User';
import { ProductPermissions } from '../domain/User/ProductPermissions';
import { CancelToken } from 'axios';

export const getCurrentUser = (): Promise<User> =>
  client('users/current', { method: 'GET' }, appConfig.apiUrls.container);

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
