import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';

import styles from '../../../common/EventForm/EventFields.module.scss';

import { DescriptionField } from '../../../common/DescriptionField/DescriptionField';
import { EventFormLayout } from '../../../common/EventForm/EventFormLayout';
import { IE11SubmitButton } from '../../../common/IE11SubmitButton/IE11SubmitButton';
import { TitleField } from '../../../common/TitleField/TitleField';
import { RequiredCoworkersField } from '../../../common/RequiredCoworkersField/RequiredCoworkersField';
import { TimeSlotField } from '../../../common/TimeSlotField/TimeSlotField';
import { TimeSlotValue } from '../../TimeSlotSelector/TimeSlotSelector';
import { EventsStateContext } from '../../../../context/events/EventsProvider';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';

const formId = 'storeEventFormId';

enum FormFields {
  Title = 'title',
  RequiredCoworkers = 'requiredCoworkers',
  Description = 'description',
  Date = 'date',
  TimeSlot = 'timeSlot'
}

export interface StoreEventFormData {
  readonly title: string;
  readonly requiredCoworkers: string;
  readonly description: string;
  readonly timeSlot: TimeSlotValue;
}

export interface StoreEventFormProps {
  readonly pending: boolean;
  readonly onSubmit: (formData: StoreEventFormData) => void;
}

export const NewStoreEventForm: FC<StoreEventFormProps> = ({
  pending,
  onSubmit
}) => {
  const { selectedDate } = useContext(EventsStateContext);
  const [hasError, setHasError] = useState(false);
  const { isCreateEventForbidden } = useUserPermissions();
  const methods = useForm<StoreEventFormData>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      requiredCoworkers: '',
      timeSlot: {
        date: selectedDate,
        timeSlotId: ''
      }
    }
  });

  const handleTimeSlotError = () => setHasError(true);

  return (
    <EventFormLayout
      title="Create New Store Event"
      sectionTitle="Store Event Details"
      sectionDescription="Store Event will show on the Calendar"
      saveButtonText="Create Event"
      pending={pending}
      hasError={hasError}
      isDisabled={isCreateEventForbidden}
    >
      <FormProvider {...methods}>
        <form
          id={formId}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.container}
        >
          <div className={clsx(styles.row, styles.split)}>
            <div className={styles.left}>
              <TitleField fieldName={FormFields.Title} disabled={pending} />
            </div>
            <div className={styles.right}>
              <RequiredCoworkersField
                fieldName={FormFields.RequiredCoworkers}
                disabled={pending}
              />
            </div>
          </div>
          <div className={clsx(styles.row)}>
            <DescriptionField
              fieldName={FormFields.Description}
              disabled={pending}
            />
          </div>
          <div className={clsx(styles.row, styles.fullWidth)}>
            <TimeSlotField
              fieldName={FormFields.TimeSlot}
              onError={handleTimeSlotError}
            />
          </div>
          <IE11SubmitButton formId={formId} />
        </form>
      </FormProvider>
    </EventFormLayout>
  );
};
