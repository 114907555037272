/* eslint-disable react/no-unescaped-entities */
/* eslint-disable  */
import React from 'react';
import {
    Button,
    ButtonProps,
    Modal,
    ModalButtons,
    ModalContent
} from '@rentacenter/racstrap';
import { DapRoute } from '../../../../config/route-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router-dom';

export interface ModalConfirmationProps {
    title: string;
    description?: React.ReactNode;
    open: boolean;
    loading?: boolean;
    cancelButtonText: string;
    confirmButtonText: string;
    confirmButtonProps?: Partial<ButtonProps>;
    onCancel: () => void;
    onConfirm: any
}

export const modalConfirmationTestId = 'modalConfirmationTestId';
export const cancelButtonTestId = 'cancel';
export const confirmButtonTestId = 'confirm'

export const AcimaModalPopup = (props: ModalConfirmationProps) => {
    const history = useHistory()

    const {
        title,
        open,
        cancelButtonText,
        confirmButtonText,
        confirmButtonProps,
        onCancel,
        onConfirm,
    } = props;

    const acimaPageNavigateFn = (poNumber: any) => {
        const path = generatePath(DapRoute.AcimaPage, {
            ponumber: poNumber
        });
        debugger
        return history.push({
            pathname: `${path}`,
        });
        // return `${path}#fullscreen`;
    };
    return (
        <Modal
            isOpen={open}
            onClose={onCancel}
            title={title}
            data-testid={modalConfirmationTestId}
        >
            <div style={{ textAlign: "center", marginTop: "10px" }}>
                <span style={{
                    fontWeight: 600,
                    fontSize: '17px'
                }}>This is an Ashley PO delivery event.</span>
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
                <span style={{
                    fontWeight: 600,
                    fontSize: '17px'
                }}>Do you want to open the PO receiving page?</span>
            </div>
            <Grid style={{ textAlign: "center", marginTop: "30px" }}>
                <Button
                    style={{ marginRight: '25px' }}
                    data-testid={cancelButtonTestId}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {cancelButtonText}
                </Button>
                <Button
                    color="danger"
                    data-testid={confirmButtonTestId}
                    {...confirmButtonProps}
                    onClick={() => {
                        acimaPageNavigateFn(onConfirm)
                    }}




                >
                    {confirmButtonText}
                </Button>
            </Grid>
        </Modal >
    );
};
