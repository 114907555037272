import React from 'react';
import { TaskCategory } from '../../../domain/Task/Task';
import styles from './Category.module.scss';
import clsx from 'clsx';

interface CategoryProps {
  category: string;
  className?: string;
}

export const Category = (props: CategoryProps) => {
  const { category, className } = props;
  const getCategoryColor = () => {
    switch (category) {
      case TaskCategory.Field:
        return '#772B89';
      case TaskCategory.Store:
        return '#1C66A7';
    }
  };

  return (
    <div
      className={clsx(styles.category, className)}
      style={{ color: getCategoryColor() }}
    >
      {category}
    </div>
  );
};
