import { Card } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { HTMLProps } from 'react';
import {
  CollectionMetricType,
  SingleDayMetric
} from '../../../../domain/Calendar/Goal';

import styles from './CollectionGoalCard.module.scss';

export const collectionGoalCardTestId = 'collectionGoalCardTestId';

export const collectionGoalColumnTestId = 'collectionGoalColumnTestId';

interface CollectionGoalColumnProps {
  countName: string;
  countPercent: string;
  totalCount: string;
  isAgreement?: boolean;
}

export interface CollectionGoalCardProps extends HTMLProps<HTMLDivElement> {
  singleDayMetric: SingleDayMetric;
}

const CollectionGoalColumn = (props: CollectionGoalColumnProps) => {
  const { isAgreement, countName, countPercent, totalCount } = props;
  return (
    <div
      className={clsx(styles.collectionGoalColumn)}
      data-testid={collectionGoalColumnTestId}
    >
      <h4 className={styles.countName}>{countName || '-'}</h4>
      <div>
        {!isAgreement && (
          <h3 className={styles.count}>{countPercent || '-'}</h3>
        )}
        <h3 className={styles.count}>{totalCount || '-'}</h3>
      </div>
    </div>
  );
};

export const CollectionGoalCard = (props: CollectionGoalCardProps) => {
  const { singleDayMetric, ...rest } = props;
  const {
    oneToSixCount,
    oneToSixPct,
    sevenPlusPct,
    sevenPlusCount,
    fifteenPlusPct,
    fifteenPlusCount,
    totalPct,
    totalCount,
    metricType
  } = singleDayMetric;
  const isAgreement = metricType === CollectionMetricType.AgreementsNeeded;
  return (
    <div data-testid={collectionGoalCardTestId} {...rest}>
      <Card className={styles.collectionGoals}>
        <div className={styles.metricType}>
          {!isAgreement ? metricType : 'Accounts Needed'}
        </div>
        <div className={styles.collectionGoalCard}>
          <CollectionGoalColumn
            countName="1-6"
            countPercent={oneToSixPct}
            totalCount={oneToSixCount}
            isAgreement={isAgreement}
          />
          <CollectionGoalColumn
            countName="7+"
            countPercent={sevenPlusPct}
            totalCount={sevenPlusCount}
            isAgreement={isAgreement}
          />
          {!isAgreement && (
            <CollectionGoalColumn
              countName="15+"
              countPercent={fifteenPlusPct}
              totalCount={fifteenPlusCount}
            />
          )}
          <CollectionGoalColumn
            countName="Total"
            countPercent={totalPct}
            totalCount={totalCount}
            isAgreement={isAgreement}
          />
        </div>
      </Card>
    </div>
  );
};
