import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './Loading.module.scss';

interface Props {
  readonly className?: string;
}

export function Loading({ className }: Props) {
  return (
    <div className={clsx(styles.loading, className)}>
      <div>
        <FontAwesomeIcon
          icon={faSpinner}
          className={clsx('fa-spin', styles.spinnerIcon)}
        />
      </div>
      <div className={styles.loadingText}>Loading</div>
    </div>
  );
}
