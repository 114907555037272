import { parse } from 'date-fns';
import { RawTimeSlot, TimeSlotStatus } from './CalendarEvent';

export interface TimeSlot {
  id: string;
  startTime: Date;
  endTime: Date;
  lunchBreak?: boolean;
  status?: TimeSlotStatus;
}

export function createTimeslot(
  timeslotInfo: RawTimeSlot,
  date: Date
): TimeSlot {
  return {
    id: timeslotInfo.timeSlotId,
    startTime: parse(timeslotInfo.startTime, 'HH:mm:ss', date),
    endTime: parse(timeslotInfo.endTime, 'HH:mm:ss', date),
    status: timeslotInfo.status
  };
}
