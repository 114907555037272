import React, { Dispatch, SetStateAction } from 'react';

import {
  useNewEventDispatch,
  useNewEventState
} from '../../../context/events/NewEventProvider';
import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';
import { DapRoute } from '../../../config/route-config';
import { useHistory } from 'react-router-dom';

interface CancelCreateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const CancelCreate = ({ show, setShow }: CancelCreateProps) => {
  const history = useHistory();
  const { dirty } = useNewEventState();
  const { clearState } = useNewEventDispatch();

  const cancel = () => {
    setShow(false);
  };

  const confirm = () => {
    if (!dirty) return;

    setShow(false);
    clearState();
    history.push(DapRoute.Calendar);
  };

  return (
    <>
      <ModalConfirmation
        open={show}
        title="Please confirm"
        description="Canceling the event creation will result in loosing all your event data. Are you sure you want to continue?"
        cancelButtonText="No"
        confirmButtonText="Yes, Cancel"
        confirmButtonProps={{ color: 'primary' }}
        onCancel={cancel}
        onConfirm={confirm}
      />
    </>
  );
};
