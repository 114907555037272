import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@rentacenter/racstrap';

import styles from './CancelButton.module.scss';

import { DapRoute } from '../../../config/route-config';

interface CancelButtonProps {
  'data-testid'?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export function CancelButton(props: CancelButtonProps) {
  const history = useHistory();
  return (
    <Button
      color="primary"
      variant="link"
      className={styles.button}
      onClick={() => history.push(DapRoute.Calendar)}
      {...props}
    >
      Cancel
    </Button>
  );
}
