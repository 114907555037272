import { isAfter, isBefore, isEqual } from 'date-fns';

export enum TIME {
  MILLISECONDS = 1,
  SECONDS = 1000,
  MINUTES = 60000,
  HOURS = 3600000
}

export function isSameOrBefore(date1: Date, date2: Date) {
  return isEqual(date1, date2) || isBefore(date1, date2);
}

export function isSameOrAfter(date1: Date, date2: Date) {
  return isEqual(date1, date2) || isAfter(date1, date2);
}
