export enum TaskStatus {
  ToDo = 'To Do',
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export const TaskStatusNames = {
  [TaskStatus.ToDo]: 'To Do',
  [TaskStatus.Cancelled]: 'Cancelled',
  [TaskStatus.Completed]: 'Completed'
};

export enum TaskStatusActionType {
  Complete = 'complete',
  Cancel = 'cancel',
  Reopen = 'reopen'
}

export enum TaskPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export enum TaskCategory {
  Field = 'Field',
  Store = 'Store'
}

export enum SortBasis {
  Priority = 'Priority',
  Category = 'Category'
}

export interface Assignee {
  name: string;
  userId: string;
}

export interface Task {
  id: string;
  title: string;
  description: string;
  assignee: Assignee;
  category: string;
  createdAt?: string;
  dueDate: string;
  dueTime?: string;
  priority: TaskPriority;
  taskStatus: TaskStatus;
  noteCount: number;
}

export const todo = (arg: Task | TaskStatus): boolean =>
  TaskStatus.ToDo === (typeof arg === 'string' ? arg : arg.taskStatus);

export const cancelled = (arg: Task | TaskStatus): boolean =>
  TaskStatus.Cancelled === (typeof arg === 'string' ? arg : arg.taskStatus);

export const completed = (arg: Task | TaskStatus): boolean =>
  TaskStatus.Completed === (typeof arg === 'string' ? arg : arg.taskStatus);

export enum RecurrencyPeriod {
  OneMonth = '1 Month',
  ThreeMonths = '3 Months',
  SixMonths = '6 Months'
}
