import React, { FC } from 'react';
import { Input, InputProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { TaskFormFields } from '../../CreateTask/CreateTask';

export const TaskDescription: FC<Partial<InputProps>> = props => {
  const { control, errors, clearErrors } = useFormContext();
  return (
    <Controller
      control={control}
      name={TaskFormFields.description}
      rules={{
        maxLength: {
          value: 256,
          message:
            'The description text is limited to a number of 256 characters.'
        }
      }}
      render={({ ref, onChange, ...rest }, { invalid }) => (
        <Input
          label="Description"
          placeholder="Enter description"
          size="large"
          invalid={invalid}
          errorMessage={errors?.description?.message}
          onChange={value => {
            clearErrors(TaskFormFields.description);
            onChange(value);
          }}
          {...rest}
          {...props}
        />
      )}
    />
  );
};
