import React, { HTMLProps } from 'react';
import clsx from 'clsx';

import styles from './EventCardLayout.module.scss';

export const EventCardLayout = (props: HTMLProps<HTMLDivElement>) => {
  const { children, className, ...rest } = props;
  return (
    <div className={clsx(styles.card, className)} {...rest}>
      {children}
    </div>
  );
};
