import React, { FC } from 'react';
import { DatePicker, DatePickerProps } from '@rentacenter/racstrap';
import { Controller, useFormContext } from 'react-hook-form';

import { TaskFormFields } from '../../CreateTask/CreateTask';
import { isBefore, isValid, parseISO, startOfDay } from 'date-fns';

function validateTaskDate(date: Date | string) {
  const dateObj = typeof date === 'string' ? parseISO(date) : date;

  if (!isValid(dateObj)) {
    return 'Invalid date';
  }

  if (isBefore(dateObj, startOfDay(new Date()))) {
    return 'Due Date cannot be in the past!';
  }
}

export const TaskDueDate: FC<Partial<DatePickerProps>> = props => {
  const { control, errors } = useFormContext();
  return (
    <Controller
      control={control}
      name={TaskFormFields.dueDate}
      rules={{
        required: true,
        validate: validateTaskDate
      }}
      render={({ ref, ...rest }, { invalid }) => (
        <DatePicker
          required={true}
          label="Due Date"
          placeholder="Select date"
          datePickerProps={{ disablePast: true }}
          invalid={invalid}
          errorMessage={errors[TaskFormFields.dueDate]?.message}
          {...rest}
          {...props}
        />
      )}
    />
  );
};
