import React from 'react';
import { ToastProvider } from '@rentacenter/racstrap';

import { UserFetcher } from './UserFetcher';
import { UserProvider } from '../../context/user/UserProvider';
import { Main } from '../layout/Main/Main';
import { StoreProvider } from '../../context/store/StoreProvider';
import { PermissionsProvider } from '../../context/user/PermissionsProvider';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DapRoute } from '../../config/route-config';
import { EventDetails } from '../Calendar/EventDetails/EventDetails';
import { TaskDetails } from '../tasks/TaskDetails/TaskDetails';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <ToastProvider>
        <div data-testid={routesTestId}>
          <StoreProvider>
            <Switch>
              <Route exact path={DapRoute.Index}>
                <Redirect to={DapRoute.Calendar} />
              </Route>
              <Route path={DapRoute.EventDetails}>
                <EventDetails onStatusChange={() => {}} />
              </Route>
              <Route path={DapRoute.TaskDetails}>
                <TaskDetails />
              </Route>
              <Route>
                <Main />
              </Route>
            </Switch>
          </StoreProvider>
        </div>
      </ToastProvider>
    </PermissionsProvider>
  </UserProvider>
);
