import { Skeleton } from '@material-ui/lab';
import { Card } from '@rentacenter/racstrap';
import React from 'react';

import styles from './SkeletonLoader.module.scss';

export const skeletonLoaderTestId = 'skeletonLoaderTestId';

export const SkeletonLoader = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <Card className={styles.loadingCard} data-testid={skeletonLoaderTestId}>
        <Skeleton variant="text" height={21} width={118} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
      </Card>

      <Card className={styles.loadingCard} data-testid={skeletonLoaderTestId}>
        <Skeleton variant="text" height={21} width={118} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
      </Card>

      <Card className={styles.loadingCard} data-testid={skeletonLoaderTestId}>
        <Skeleton variant="text" height={21} width={118} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
        <Skeleton variant="text" height={21} animation="wave" />
      </Card>
    </div>
  );
};
