import React, { FC } from 'react';

interface E11SubmitButtonProps {
  formId: string;
}

export const IE11SubmitButton: FC<E11SubmitButtonProps> = ({ formId }) => (
  <button
    id="ie11SubmitBtn"
    form={formId}
    type="submit"
    style={{ display: 'none' }}
  >
    This is needed for IE11
  </button>
);
