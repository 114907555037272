import React from 'react';

import { NoteCard } from '../NoteCard/NoteCard';

export const numberOfNotes = 2;

export interface NotesSkeletonProps {
  readonly?: boolean;
}

export const NotesSkeleton = (props: NotesSkeletonProps) => {
  const { readonly } = props;
  return (
    <>
      {new Array(numberOfNotes).fill(null).map((item, index) => (
        <NoteCard key={index} readonly={readonly} />
      ))}
    </>
  );
};
