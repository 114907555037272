import React, { useContext, useEffect, useState } from 'react';
import { Button, Table } from '@rentacenter/racstrap';

import styles from './SelectItem.module.scss';

import { Footer } from '../../../layout/footer/Footer';
import { nextStepTestId } from '../SelectCustomer/SelectCustomer';
import { SetStepProp } from '../NewEvent';
import { BackButton } from '../BackButton';
import { CancelButton } from '../CancelButton';
import {
  useNewEventDispatch,
  useNewEventState
} from '../../../../context/events/NewEventProvider';
import { getItems } from '../../../../api/customer';
import { SelectedCustomer } from '../SelectedCustomer/SelectedCustomer';
import { SelectedAgreement } from '../SelectedAgreement/SelectedAgreement';
import { SelectableRow } from '../SelectableRow/SelectableRow';
import { Loading } from '../Loading/Loading';
import { Item } from '../../../../domain/Customer/Item';
import { NoItems } from '../NoItems/NoItems';
import { StoreContext } from '../../../../context/store/StoreProvider';
import { ApiError } from '../../../common/ApiError/ApiError';
import { Divider } from '../Divider';
import { CancelCreate } from '../CancelCreate';

export const selectItemTestId = 'selectItemTestId';
export const itemsListTestId = 'itemsListTestId';

export const SelectItem = (props: SetStepProp) => {
  const { setStep, ...rest } = props;
  const [items, setItems] = useState<Item[]>([]);
  const [hasApiError, setHasApiError] = useState(false);
  const [cancelCreate, setCancelCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    selectCustomer: { selectedCustomer },
    selectedAgreement,
    selectedItems
  } = useNewEventState();

  const { setSelectedItems } = useNewEventDispatch();
  const { selectedStore } = useContext(StoreContext);

  type ListStatus = 'initial' | 'loading' | 'apiError' | 'empty' | 'success';

  const getStatus = (): ListStatus => {
    if (loading) return 'loading';
    if (hasApiError) return 'apiError';

    if (items) {
      return items.length > 0 ? 'success' : 'empty';
    } else {
      return 'initial';
    }
  };

  useEffect(
    function fetchItems() {
      if (!selectedStore || !selectedCustomer || !selectedAgreement) {
        return;
      }
      setLoading(true);
      setHasApiError(false);
      getItems(selectedAgreement?.agreementId, 'agreement')
        .then(response => {
          setItems(response.inventoryItems || []);
          setSelectedItems(response.inventoryItems);
        })
        .catch(() => {
          setSelectedItems();
          setItems([]);
          setHasApiError(true);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAgreement]
  );

  const handleSelectItem = (item: Item, isItemSelected: boolean) => {
    let items: Item[] = [];

    if (isItemSelected) {
      items = selectedItems.filter(
        (c: Item) => c.inventoryId !== item?.inventoryId
      );
    } else {
      items = [...selectedItems, item];
    }
    return setSelectedItems(items);
  };

  return (
    <div data-testid={selectItemTestId} {...rest}>
      <SelectedCustomer />
      <SelectedAgreement />

      {
        {
          initial: null,
          loading: <Loading className={styles.loading} />,
          apiError: <ApiError className={styles.apiError} />,
          empty: <NoItems>No Search Results Found</NoItems>,
          success: (
            <Table
              classes={{ table: styles.itemsListTable }}
              data-testid={itemsListTestId}
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Item #</th>
                  <th scope="col">Item Description</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item: Item, index) => {
                  const isItemSelected = selectedItems?.find(
                    (c: Item) => c.inventoryId === item?.inventoryId
                  );
                  return (
                    <SelectableRow
                      key={index}
                      selected={isItemSelected}
                      onClick={() => handleSelectItem(item, isItemSelected)}
                    >
                      <td>{item.inventoryNumber}</td>
                      <td>{item.description || 'N/A'}</td>
                    </SelectableRow>
                  );
                })}
              </tbody>
            </Table>
          )
        }[getStatus()]
      }

      <CancelCreate show={cancelCreate} setShow={setCancelCreate} />

      <Footer>
        <div>
          <BackButton onClick={() => setStep(1)} />
          <Divider />
          <CancelButton onClick={() => setCancelCreate(true)} />
        </div>
        <div>
          <Button
            data-testid={nextStepTestId}
            color="primary"
            disabled={!selectedItems?.length}
            onClick={() => setStep(3)}
          >
            Next Step
          </Button>
        </div>
      </Footer>
    </div>
  );
};
