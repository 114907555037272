import React, { FC, useState } from 'react';
import { Button } from '@rentacenter/racstrap';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './EditStoreEventForm.module.scss';

import { Status } from '../../../common/EventStatus/EventStatus';
import { StoreEvent } from '../../../../domain/Calendar/CalendarEvent';
import { noOp } from '../../../../utils/helper-functions';

import { TitleField } from '../../../common/TitleField/TitleField';
import { DescriptionField } from '../../../common/DescriptionField/DescriptionField';
import { TimeSlotField } from '../../../common/TimeSlotField/TimeSlotField';
import { RequiredCoworkersField } from '../../../common/RequiredCoworkersField/RequiredCoworkersField';
import { TimeSlotValue } from '../../TimeSlotSelector/TimeSlotSelector';

export const editStoreEventTestId = 'editStoreEventTestId';

export interface EditStoreEventFormData {
  readonly title: string;
  readonly requiredCoworkers: string;
  readonly description: string;
  readonly timeSlot: TimeSlotValue;
}

enum FormFields {
  Title = 'title',
  RequiredCoworkers = 'requiredCoworkers',
  Description = 'description',
  TimeSlot = 'timeSlot'
}

export interface EditStoreEventProps {
  readonly pending: boolean;
  readonly event: StoreEvent;
  readonly onCancel?: () => void;
  readonly onSubmit: (formData: EditStoreEventFormData) => void;
}

export const EditStoreEvent: FC<EditStoreEventProps> = ({
  pending,
  event,
  onCancel = noOp,
  onSubmit
}) => {
  const [hasError, setHasError] = useState(false);

  const methods = useForm<EditStoreEventFormData>({
    mode: 'onChange',
    defaultValues: {
      title: event.title,
      description: event.instruction,
      requiredCoworkers: event.requiredCoworkers,
      timeSlot: {
        date: new Date(event.eventDate),
        timeSlotId: event?.timeSlot?.timeSlotId
      }
    }
  });

  const { isDirty } = methods.formState;

  const handleTimeSlotError = () => setHasError(true);

  return (
    <div className={styles.container}>
      <div className={styles.eventStatus} data-testid={editStoreEventTestId}>
        <div className={styles.statusField}>
          <span className={styles.label}>Status:</span>
          <Status className={styles.status} status={event.status} />
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          id={editStoreEventTestId}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={clsx(styles.container, styles.formContainer)}
        >
          <div className={clsx(styles.row, styles.split)}>
            <div className={styles.left}>
              <TitleField fieldName={FormFields.Title} disabled={pending} />
            </div>
            <div className={styles.right}>
              <RequiredCoworkersField
                fieldName={FormFields.RequiredCoworkers}
                disabled={pending}
              />
            </div>
          </div>
          <div className={clsx(styles.row)}>
            <DescriptionField
              fieldName={FormFields.Description}
              disabled={pending}
            />
          </div>
          <div className={styles.row}>
            <TimeSlotField
              fieldName={FormFields.TimeSlot}
              editMode
              onError={handleTimeSlotError}
            />
          </div>
        </form>
        <div className={clsx(styles.buttons, styles.editMode)}>
          <Button
            data-testid="cancelEventEdit"
            disabled={pending}
            size="large"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-testid="submitEventEdit"
            disabled={!isDirty || pending || hasError}
            size="large"
            type="submit"
            form={editStoreEventTestId}
            {...(pending && {
              icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            })}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};
