import React, { useContext } from 'react';
import { Tabs } from '@rentacenter/racstrap';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import clsx from 'clsx';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Main.module.scss';

import { useUserStateContext } from '../../../context/user/user-contexts';
import { StoreContext } from '../../../context/store/StoreProvider';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import { TasksList } from '../../tasks/TasksList/TasksList';
import { GoalsController } from '../../Goals/GoalsController';
import { DapRoute } from '../../../config/route-config';
import { NoStoreSelected } from '../NoStoreSelected/NoStoreSelected';
import { TasksProvider } from '../../../context/tasks/TasksProvider';
import { CalendarRoutes } from './CalendarRoutes';

const { isRenderedByContainer } = window;

export const footerTestId = 'footerTestId';

export const Main = () => {
  const history = useHistory();
  const location = useLocation();
  const { permissionsError } = useUserStateContext();
  const { selectedStore } = useContext(StoreContext);

  const handleTabsChange = (event: any, tabIndex: any) => {
    switch (tabIndex) {
      case 0:
        history.push(DapRoute.Calendar);
        break;
      case 1:
        history.push(DapRoute.Tasks);
        break;
      case 2:
        history.push(DapRoute.Goals);
        break;
    }
  };

  const isTasksRoute = matchPath(location.pathname, {
    path: DapRoute.Tasks
  });

  const isGoalsRoute = matchPath(location.pathname, {
    path: DapRoute.Goals
  });

  let currentTab = 0;

  if (isTasksRoute) {
    currentTab = 1;
  } else if (isGoalsRoute) {
    currentTab = 2;
  }

  return (
    <div
      className={clsx(
        styles.mainWrapper,
        isRenderedByContainer && styles.containedMainWrapper
      )}
    >
      <StoreSelector />
      {permissionsError && (
        <div className={styles.permissionsError}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          You are currently in view only mode, you cannot create and edit events
          and tasks
        </div>
      )}
      <Tabs
        value={currentTab}
        classes={{ tabs: styles.tabs }}
        onChange={handleTabsChange}
      >
        <div title="Calendar"></div>
        <div title="Tasks"></div>
        <div title="Goals"></div>
      </Tabs>

      {selectedStore ? (
        <>
          <Switch>
            <Route path={DapRoute.Calendar}>
              <CalendarRoutes />
            </Route>

            <Route exact path={DapRoute.Tasks}>
              <div className={styles.fullview}>
                <TasksProvider>
                  <TasksList />
                </TasksProvider>
              </div>
            </Route>

            <Route exact path={DapRoute.Goals}>
              <GoalsController />
            </Route>

            <Route>
              <Redirect to={DapRoute.Calendar} />
            </Route>
          </Switch>
        </>
      ) : (
        <NoStoreSelected />
      )}
    </div>
  );
};
