import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SearchableSelect } from '@rentacenter/racstrap';

import styles from './EventCompletedReasonModal.module.scss';
import { ModalConfirmation } from '../../common/ModalConfirmation/ModalConfirmation';
import { buildOptions } from '../../../utils/buildOptions';
import { DeliveryCompleteReasonNames } from '../../../domain/Calendar/CalendarEvent';
import { noOp } from '../../../utils/helper-functions';

export const eventCompletedReasonModalSelectTestId =
  'eventCompletedReasonModalSelectTestId';
export const eventCompletedReasonModalForm = 'eventCompletedReasonModalForm';
export const eventCompletedReasonModalFormTestId =
  'eventCompletedReasonModalFormTestId';

export interface EventCompletedReasonModalProps {
  open: boolean;
  onCancel: () => void;
  pending: boolean;
  onConfirm: (reason: string) => void;
}

interface EventCompletedReasonModalFormData {
  readonly reason: string;
}

export const EventCompletedReasonModal: FC<EventCompletedReasonModalProps> = ({
  open = true,
  onCancel = noOp,
  onConfirm = noOp,
  pending
}) => {
  const { control, handleSubmit, errors, clearErrors, formState } = useForm<
    EventCompletedReasonModalFormData
  >({
    mode: 'onSubmit',
    defaultValues: {
      reason: ''
    }
  });

  const { isDirty } = formState;

  const onSubmit = (formData: EventCompletedReasonModalFormData) => {
    onConfirm(formData.reason);
  };

  return (
    <ModalConfirmation
      open={open}
      title="You are about to Complete the event"
      description={
        <form id={eventCompletedReasonModalForm}>
          <div>
            <Controller
              control={control}
              name="reason"
              rules={{
                required: 'This field is required.'
              }}
              render={({ ref, onChange, value, ...rest }, { invalid }) => (
                <SearchableSelect
                  data-testid={eventCompletedReasonModalSelectTestId}
                  classes={{ options: styles.options }}
                  size="small"
                  placeholder="Select Reason"
                  label="Please select return reason"
                  required
                  options={buildOptions(DeliveryCompleteReasonNames)}
                  invalid={invalid}
                  onChange={(e: any) => {
                    clearErrors('reason');
                    onChange(e);
                  }}
                  value={value}
                  errorMessage={errors?.reason?.message}
                  {...rest}
                />
              )}
            />
          </div>
        </form>
      }
      cancelButtonText="Cancel"
      confirmButtonText="Complete Event"
      loading={pending}
      onCancel={onCancel}
      confirmButtonProps={{ color: 'primary', disabled: !isDirty || pending }}
      onConfirm={handleSubmit(onSubmit)}
    />
  );
};
