import React, { HTMLProps, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarTimes,
  faCheckCircle,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { Button, Tabs } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { format } from 'date-fns';
import { compact } from 'lodash';

import styles from './CustomerEventDetails.module.scss';

import {
  CalendarEvent,
  cancelled,
  EventStatusActionType,
  toDo,
  formatTimeslot
} from '../../../../domain/Calendar/CalendarEvent';
import { ParentType } from '../../../../domain/Note/Note';
import { Status } from '../../../common/EventStatus/EventStatus';
import { EventType as Type } from '../../../common/EventType/EventType';
import { EventSource } from '../../../common/EventSource/EventSource';
import { Notes } from '../../../common/Notes/Notes';
import { useUserPermissions } from '../../../../context/user/PermissionsProvider';
import { HistoryLog } from '../../../common/HistoryLog/HistoryLog';

export const customerEventDetailsTestId = 'customerEventDetailsTestId';
export const agreementNumberTestId = 'agreementNumberTestId';
export const agreementItemsTestId = 'agreementItemsTestId';
export const eventStatusTestId = 'eventStatusTestId';
export const eventTypeTestId = 'eventTypeTestId';
export const coWorkersRequiredTestId = 'coWorkersRequiredTestId';
export const descriptionTestId = 'descriptionTestId';
export const customerPhoneNoTestId = 'customerPhoneNoTestId';
export const customerAddressTestId = 'customerAddressTestId';
export const eventDateTestId = 'eventDateTestId';
export const sourceTestId = 'sourceTestId';

export interface CustomerEventDetailsProps extends HTMLProps<HTMLDivElement> {
  event: CalendarEvent;
  printMode?: boolean;
  onStatusChange: (eventStatusActionType: EventStatusActionType) => void;
}

export const CustomerEventDetails = (props: CustomerEventDetailsProps) => {
  const { event, onStatusChange, printMode, ...rest } = props;
  const [activeTab, setActiveTab] = useState<number>(0);

  const { canSubmit, canCancel, isUpdateEventForbidden } = useUserPermissions();

  const canReopen = canCancel;

  const eventDate = event?.eventDate;

  const { customerInformation } = event || {};
  const { address, firstName, lastName, phoneNumber } =
    customerInformation || {};

  const formatPhoneNumber = (phone: string) => {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  };

  const canChangeStatus = canSubmit && !printMode && !isUpdateEventForbidden;

  const handleTabsChange = (event: any, tabIndex: number) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div
        className={styles.eventDetails}
        data-testid={customerEventDetailsTestId}
        {...rest}
      >
        <div className={styles.name}>
          <FontAwesomeIcon icon={faUserCircle} />
          {firstName} {lastName}
        </div>
        <div className={styles.row} data-testid={eventDateTestId}>
          {eventDate && (
            <span className={styles.eventDate}>{`${format(
              new Date(eventDate),
              'eeee, MMMM dd'
            )} | ${formatTimeslot(event)}`}</span>
          )}
        </div>
        <div className={styles.eventStatus} data-testid={eventStatusTestId}>
          <div className={styles.statusField}>
            <span className={styles.label}>Status:</span>
            <Status className={styles.status} status={event?.status} />
          </div>
          {canChangeStatus && (
            <>
              {cancelled(event.status) && canReopen && (
                <Button
                  color="primary"
                  className={styles.statusButton}
                  size="small"
                  onClick={() => onStatusChange(EventStatusActionType.Reopen)}
                >
                  Reopen Event
                </Button>
              )}

              {toDo(event.status) && (
                <>
                  {canCancel && (
                    <Button
                      color="primary"
                      icon={<FontAwesomeIcon icon={faCalendarTimes} />}
                      className={clsx(styles.statusButton, styles.cancelEvent)}
                      variant="link"
                      size="small"
                      onClick={() =>
                        onStatusChange(EventStatusActionType.Cancel)
                      }
                    >
                      Cancel Event
                    </Button>
                  )}
                  <Button
                    color="primary"
                    icon={<FontAwesomeIcon icon={faCheckCircle} />}
                    className={styles.statusButton}
                    size="small"
                    onClick={() =>
                      onStatusChange(EventStatusActionType.Complete)
                    }
                  >
                    Mark as Completed
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        {event?.agreement && (
          <>
            <div className={styles.row} data-testid={agreementNumberTestId}>
              <span className={styles.label}>Agreement Number:</span>
              {event.agreement.agreementNumber}
            </div>
            <div className={clsx(styles.row)} data-testid={descriptionTestId}>
              <span className={styles.label}>Agreement Description:</span>
              <span>{event.agreement.description}</span>
            </div>
          </>
        )}

        <div
          className={clsx(styles.row, styles.items)}
          data-testid={agreementItemsTestId}
        >
          <span className={styles.label}>Items:</span>
          <span>{event?.invntories?.length}</span>
        </div>
        <div className={styles.itemsList}>
          {event?.invntories.map((inventoryItem, index) => (
            <div key={index}>
              <span>{index + 1}. </span>
              <span>{inventoryItem?.description}</span>
            </div>
          ))}
        </div>

        <div className={styles.row} data-testid={sourceTestId}>
          <span className={styles.label}>Source:</span>
          <EventSource
            className={styles.eventSourceType}
            source={event.source}
          />
        </div>

        <div className={styles.row} data-testid={eventTypeTestId}>
          <span className={styles.label}>Type:</span>
          <Type className={styles.eventType} type={event.type} />
        </div>
        {address && (
          <div className={styles.row} data-testid={customerAddressTestId}>
            <span className={styles.label}>Address:</span>
            <span>
              {compact([
                address?.addressLine1,
                address?.city,
                address?.state,
                address?.zipCode
              ]).join(', ')}
            </span>
          </div>
        )}
        <div className={styles.row} data-testid={customerPhoneNoTestId}>
          <span className={styles.label}>Phone:</span>
          {phoneNumber && (
            <span>
              {phoneNumber.length === 10
                ? formatPhoneNumber(phoneNumber)
                : phoneNumber}
            </span>
          )}
        </div>

        <div
          className={clsx(styles.row, styles.coworkersRequired)}
          data-testid={coWorkersRequiredTestId}
        >
          <span className={styles.label}>Coworkers required:</span>
          <span>{event.requiredCoworkers}</span>
        </div>
      </div>

      <Tabs
        value={activeTab}
        classes={{ tabs: styles.tabs }}
        onChange={handleTabsChange}
      >
        <div title="Notes" className={styles.tabTitle}>
          <Notes
            parentId={event.eventId}
            parentType={ParentType.Event}
            readonly={printMode || isUpdateEventForbidden}
          />
        </div>
        <div title="History Log" className={styles.tabTitle}>
          <HistoryLog parentId={event.eventId} parentType={ParentType.Event} />
        </div>
      </Tabs>
    </>
  );
};
