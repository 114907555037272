/* eslint-disable */
export enum DapRoute {
  Index = '/dap',
  Calendar = '/dap/calendar',
  CalendarNewEvent = '/dap/calendar/new-event',
  CalendarNewStoreEvent = '/dap/calendar/new-store-event',
  CalendarNewBlockEvent = '/dap/calendar/new-block-time-event',
  Tasks = '/dap/tasks',
  Goals = '/dap/goals',
  EventDetails = '/dap/event-details/:storeId/:eventId',
  TaskDetails = '/dap/task-details/:taskId',
  AcimaPage ='/acimapo/poreceive/details/:ponumber'
}
