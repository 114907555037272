import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import { DropdownButton, DropdownInput } from '@rentacenter/racstrap';
import { format, parse } from 'date-fns';
import { compact } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './SearchEvent.module.scss';

import { useDebounce } from '../../../../utils/useDebounce';
import { TIME } from '../../../../utils/time';
import { buildOptions } from '../../../../utils/buildOptions';
import { searchEvents } from '../../../../api/calendar';
import { getCancelTokenSource } from '../../../../api/client';
import { StoreContext } from '../../../../context/store/StoreProvider';
import {
  CalendarEvent,
  EventType,
  StoreEvent,
  formatTimeslot
} from '../../../../domain/Calendar/CalendarEvent';
import {
  SearchCriteria,
  SearchResult
} from '../../../../domain/Calendar/search';
import { CBStatus } from '../../CBStatus/CBStatus';

const criteriaItems = {
  [SearchCriteria.Name]: 'Name',
  [SearchCriteria.Description]: 'Description',
  [SearchCriteria.Title]: 'Title'
};

function CustomerEventOption({ option }: { option: CalendarEvent }) {
  const { customerInformation: owner, status, eventDate } = option;
  const { address, firstName, lastName } = owner || {};
  const compactAddress = compact([
    address?.addressLine1,
    address?.city,
    address?.state,
    address?.zipCode
  ]).join(', ');

  return (
    <div data-testid="customerEventOption">
      <div className={styles.primaryRow}>
        <CBStatus status={status} className={styles.statusIcon} />
        <div className={styles.ownerName}>
          {firstName} {lastName}
        </div>
        <div>
          {format(parse(eventDate, 'LL/dd/yyyy', new Date()), 'EEE, LLL d')}
        </div>
      </div>
      <div className={styles.secondaryRow}>
        <div className={styles.address} title={compactAddress}>
          {compactAddress}
        </div>

        <div className={styles.timeslot}>{formatTimeslot(option, 'h ')}</div>
      </div>
    </div>
  );
}
function StoreEventOption({ option }: { option: StoreEvent }) {
  const { title, instruction, status, eventDate } = option;

  return (
    <div data-testid="storeEventOption">
      <div className={styles.primaryRow}>
        <CBStatus status={status} className={styles.statusIcon} />
        <div className={styles.ownerName}>{title}</div>
        <div>
          {format(parse(eventDate, 'LL/dd/yyyy', new Date()), 'EEE, LLL d')}
        </div>
      </div>
      <div className={styles.secondaryRow}>
        <div className={styles.address} title={instruction}>
          {instruction}
        </div>

        <div className={styles.timeslot}>{formatTimeslot(option, 'h ')}</div>
      </div>
    </div>
  );
}

export const searchEventTestId = 'searchEventTestId';
export interface SearchEventProps extends HTMLProps<HTMLDivElement> {
  onShowEvent: (event: CalendarEvent) => void;
}

export const SearchEvent = (props: SearchEventProps) => {
  const { onShowEvent, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [criteriaOpen, setCriteriaOpen] = useState(false);
  const [criteria, setCriteria] = useState<SearchCriteria>(SearchCriteria.Name);
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 0.5 * TIME.SECONDS);
  const { selectedStore } = useContext(StoreContext);

  useEffect(
    function searchTerm() {
      if (!debouncedSearchTerm) {
        return setOpen(false);
      }

      if (!selectedStore) {
        return;
      }

      const cancelToken = getCancelTokenSource();

      setLoading(true);
      setOpen(false);
      setSearchError('');
      searchEvents(
        selectedStore,
        { [criteria]: debouncedSearchTerm },
        cancelToken.token
      )
        .then(response => {
          setOptions(response.filter(e => e.type !== EventType.BlockTime));
          setOpen(true);
        })
        .catch(err => {
          if (!err.__CANCEL__) {
            setSearchError('Unexpected server error');
          }
        })
        .finally(() => setLoading(false));

      return cancelToken.cancel;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm, criteria]
  );

  useEffect(() => {
    setSearchTerm('');
  }, [selectedStore]);

  const renderOption = (option: SearchResult) => {
    if (option.type === EventType.Store) {
      return <StoreEventOption option={option as StoreEvent} />;
    } else {
      return <CustomerEventOption option={option as CalendarEvent} />;
    }
  };

  const handleSelection = (option: CalendarEvent) => {
    onShowEvent && onShowEvent(option);
    setSearchTerm('');
    setOpen(false);
  };

  function criteriaSelected({ value }: { value: SearchCriteria }) {
    setCriteria(value);
    setCriteriaOpen(!criteriaOpen);
  }

  return (
    <div
      className={styles.searchEvent}
      data-testid={searchEventTestId}
      {...rest}
    >
      <DropdownInput
        open={open}
        name="searchEvent"
        onClose={() => {
          setOpen(false);
          setFocused(false);
        }}
        onFocus={() => {
          if (searchTerm && !searchError) setOpen(true);
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
        options={options}
        loading={loading}
        placeholder="Search"
        value={searchTerm}
        onChange={(event: any) => setSearchTerm(event.target.value)}
        icon={<FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />}
        renderOption={renderOption}
        classes={{ options: styles.options, input: styles.input }}
        onSelect={handleSelection}
        errorMessage={searchError}
        endAdornment={
          !open &&
          !focused && (
            <DropdownButton
              open={criteriaOpen}
              variant="link"
              classes={{
                button: styles.criteriaButton,
                options: styles.criteriaOptions,
                label: styles.criteriaLabel
              }}
              onClose={() => setCriteriaOpen(false)}
              onClick={() => setCriteriaOpen(!criteriaOpen)}
              onSelect={criteriaSelected}
              options={buildOptions(criteriaItems)}
            >
              {criteriaItems[criteria]}
            </DropdownButton>
          )
        }
      />
    </div>
  );
};
