import React, { useEffect, useState } from 'react';
import { TimeSlot } from '../../../domain/Calendar/Timeslot';

import styles from './TimeCursor.module.scss';
import heights from '../TimeSlotRow/TimeSlotRow.module.scss';
import { ActiveTimeSlot } from '../Calendar';
import { differenceInMinutes, isSameDay } from 'date-fns';

export const currentTimeCursorTestId = 'currentTimeCursorTestId';

export interface TimeCursorProps {
  timeSlots?: TimeSlot[];
  activeTimeSlot?: ActiveTimeSlot;
  selectedDate?: Date;
}

export const TimeCursor = (props: TimeCursorProps) => {
  const { activeTimeSlot, timeSlots = [], selectedDate } = props;
  const [show, setShow] = useState(false);

  const lunchTimeSlotHeight = parseInt(heights.lunchTimeSlotHeight, 10);
  const timeSlotHeight = parseInt(heights.timeSlotHeight, 10);

  useEffect(
    function updateShouldShow() {
      const hasTimeSlots = timeSlots?.length > 0;
      if (!activeTimeSlot || !selectedDate || activeTimeSlot.index === -1) {
        setShow(false);
        return;
      }

      const isToday = isSameDay(
        activeTimeSlot?.timeslot.startTime,
        selectedDate
      );
      setShow(hasTimeSlots && !!activeTimeSlot && isToday);
    },
    [timeSlots, activeTimeSlot, selectedDate]
  );

  const getCursorPos = () => {
    if (!show) {
      return;
    }

    // TS is not smart enough to see that !shouldShow makes
    // sure activeTimeslot is not undefined
    let position = (activeTimeSlot!.index - 1) * timeSlotHeight + 27;

    if (activeTimeSlot?.afterLunch) {
      position += lunchTimeSlotHeight;
    } else {
      position += timeSlotHeight - 18;
    }
    const currentSlotMinutes = differenceInMinutes(
      activeTimeSlot!.timeslot.startTime,
      activeTimeSlot!.timeslot.endTime
    );

    const passedMinutes = differenceInMinutes(
      activeTimeSlot!.timeslot.startTime,
      new Date()
    );

    const activeTimeSlotHeight = activeTimeSlot?.timeslot.lunchBreak
      ? lunchTimeSlotHeight
      : timeSlotHeight;
    const minutesPercent = (100 * passedMinutes) / currentSlotMinutes;
    const heightPercent = (minutesPercent * activeTimeSlotHeight) / 100;

    position += heightPercent;

    return position;
  };

  return (
    <>
      {show && (
        <div
          className={styles.cursor}
          style={{ top: getCursorPos() }}
          data-testid={currentTimeCursorTestId}
        ></div>
      )}
    </>
  );
};
