import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltUp,
  faLongArrowAltDown
} from '@fortawesome/free-solid-svg-icons';

import clsx from 'clsx';

import styles from './Priority.module.scss';
import { TaskPriority } from '../../../domain/Task/Task';

interface PriorityProps {
  priority: TaskPriority;
  className?: string;
}

export const Priority = (props: PriorityProps) => {
  const { priority, className } = props;
  const getPriorityStyle = () => {
    switch (priority) {
      case TaskPriority.Low:
        return { icon: faLongArrowAltDown, style: styles.low };
      case TaskPriority.Medium:
        return { icon: faLongArrowAltUp, style: styles.medium };
      case TaskPriority.High:
        return { icon: faLongArrowAltUp, style: styles.high };
    }
  };
  const priorityStyle = getPriorityStyle();

  return (
    <div className={clsx(styles.priority, priorityStyle.style, className)}>
      <FontAwesomeIcon icon={priorityStyle.icon} /> {priority}
    </div>
  );
};
