import React, { useContext, useState } from 'react';
import { SideDrawerModal, SideDrawerModalContent } from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons';

import styles from './CalendarSideModal.module.scss';

import {
  CalendarEvent,
  EventStatusActionType,
  EventType,
  StoreEvent,
  cancelled,
  toDo
} from '../../../domain/Calendar/CalendarEvent';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';
import { EventForm } from './EventForm/EventForm';
import { EventDetails } from '../EventDetails/EventDetails';
import EditStoreEvent from './EditStoreEventFrom';
import EditBlockTimeEvent from './EditBlockTimeEventForm';
import { DeleteEvent } from './DeleteEvent/DeleteEvent';
import { EventsDispatchContext } from '../../../context/events/EventsProvider';

export const eventViewDetailsTestId = 'eventViewDetailsTestId';
export const eventSideModalEditBtnTestId = 'eventSideModalEditBtnTestId';
export const eventSideModalPrintBtnTestId = 'eventSideModalPrintBtnTestId';
export const eventSideModalDeleteBtnTestId = 'eventSideModalDeleteBtnTestId';
export const agreementNumberTestId = 'agreementNumberTestId';
export const eventStatusTestId = 'eventStatusTestId';
export const eventTypeTestId = 'eventTypeTestId';
export const coWorkersRequiredTestId = 'coWorkersRequiredTestId';
export const descriptionTestId = 'descriptionTestId';
export const customerPhoneNoTestId = 'customerPhoneNoTestId';
export const customerAddressTestId = 'customerAddressTestId';
export const eventDateTestId = 'eventDateTestId';

export const getTitle = (eventType: string, isEditMode: boolean) => {
  let title = '';
  if (isEditMode) {
    title = 'Edit ';
  }
  if (eventType === EventType.Store) {
    title += 'Store Event Details';
  } else if (eventType === EventType.BlockTime) {
    title += 'Block Time Event Details';
  } else {
    title += 'Event Details';
  }

  return title;
};

export type EventSideModalProps = {
  editMode?: boolean;
  onClose: () => void;
  onStatusChange: (eventStatusActionType: EventStatusActionType) => void;
  onPrint: (eventId: string) => void;
  event: CalendarEvent | StoreEvent;
  setAutoRefresh: (autoRefresh: boolean) => void;
};

// eslint-disable-next-line
export const CalendarSideModal = (props: EventSideModalProps) => {
  const {
    editMode,
    onClose,
    onStatusChange,
    onPrint,
    event,
    setAutoRefresh
  } = props;
  const [isEditMode, setEditMode] = useState<boolean>(editMode || false);
  const [disableModalClose, setDisableModalClose] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const {
    canSubmit,
    canBlockTimeslot,
    canDelete,
    isSupportUser,
    isUpdateEventForbidden
  } = useUserPermissions();
  const { setReloadUpcomingEvents } = useContext(EventsDispatchContext);

  const toggleEditMode = () => {
    if (!isEditMode) setAutoRefresh(false);

    setEditMode(!isEditMode);
  };

  const handleSave = () => {
    toggleEditMode();
    onClose();
    setReloadUpcomingEvents(true);
  };

  const isStoreEvent = event?.type === EventType.Store;
  const isBlockTimeEvent = event?.type === EventType.BlockTime;
  const isCustomerEvent = !isStoreEvent && !isBlockTimeEvent;

  const showEditIcon =
    ((isBlockTimeEvent && canBlockTimeslot && !isSupportUser) ||
      (!isBlockTimeEvent &&
        canSubmit &&
        toDo(event.status) &&
        !isSupportUser) ||
      (isSupportUser && isCustomerEvent && toDo(event.status))) &&
    !isUpdateEventForbidden &&
    event.recurringAppointmentId === '0';
  const showDelete =
    canDelete &&
    cancelled(event) &&
    !isBlockTimeEvent &&
    !isSupportUser &&
    !isUpdateEventForbidden;

  const renderActionBar = () => {
    return (
      <div className={styles.actionBar}>
        {!isBlockTimeEvent && (
          <>
            <FontAwesomeIcon
              data-testid={eventSideModalPrintBtnTestId}
              cursor="pointer"
              icon={faPrint}
              onClick={() => onPrint(event.eventId)}
            />
            {showEditIcon && <span className={styles.divider}></span>}
          </>
        )}
        {showEditIcon && (
          <FontAwesomeIcon
            data-testid={eventSideModalEditBtnTestId}
            cursor="pointer"
            icon={faPen}
            onClick={toggleEditMode}
          />
        )}
        {showDelete && (
          <>
            <DeleteEvent
              event={event}
              open={showDeleteModal}
              onClose={(cancelled = false) => {
                setShowDeleteModal(false);

                if (!cancelled) onClose();
              }}
            />
            <span className={styles.divider}></span>
            <FontAwesomeIcon
              data-testid={eventSideModalDeleteBtnTestId}
              cursor="pointer"
              icon={faTrash}
              onClick={() => setShowDeleteModal(true)}
            />
          </>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (!isEditMode) {
      return (
        <EventDetails
          event={event as CalendarEvent}
          onStatusChange={onStatusChange}
        />
      );
    }

    if (isStoreEvent) {
      return (
        <EditStoreEvent
          event={event as StoreEvent}
          onCancel={toggleEditMode}
          onSave={handleSave}
        />
      );
    }

    if (isBlockTimeEvent) {
      return (
        <EditBlockTimeEvent
          event={event as StoreEvent}
          onCancel={toggleEditMode}
          onSave={handleSave}
        />
      );
    }

    return (
      <EventForm
        setDisableModalClose={setDisableModalClose}
        editMode
        onCancel={toggleEditMode}
        event={event as CalendarEvent}
        onSave={handleSave}
      />
    );
  };

  return (
    <SideDrawerModal
      isOpen={true}
      title={getTitle(event?.type, isEditMode)}
      onClose={onClose}
      renderActionBar={isEditMode ? undefined : renderActionBar}
      disableClose={disableModalClose}
    >
      <SideDrawerModalContent>{renderContent()}</SideDrawerModalContent>
    </SideDrawerModal>
  );
};
