import React from 'react';
import { EventCard } from '../EventCard/EventCard';

export const numberOfCards = 4;

export interface EventsSkeletonProps {
  className?: string;
}

export const EventsSkeleton = ({ className }: EventsSkeletonProps) => {
  return (
    <>
      {new Array(numberOfCards).fill(null).map((item, index) => (
        <EventCard key={index} className={className} />
      ))}
    </>
  );
};
